import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IHotelNameId } from '../../models/hotelsInterfaces';
import { fetchHotelsNameId } from '../../services/maidPaymentsService';
import { IAutocompleteSelectOptionType } from '../../models/maidsInterfaces';
import { getUpdateURLParameter } from '../../helpers/url';
import { PAYOUT_STAFF } from '../../config/env';

export interface FilterState {
  curValue: number;
  hotelNameIds: IHotelNameId[];
  hotelsLoading: 'idle' | 'loading' | 'succeeded' | 'error';
  curHotelOPt: IAutocompleteSelectOptionType[];
  allHotelOpts: IAutocompleteSelectOptionType[];
  allHotelOptsInactive: IAutocompleteSelectOptionType[]; 
  isHotelActive: boolean;
}

const initialState: FilterState = {
  curValue: 0,
  hotelNameIds: [],
  hotelsLoading: 'idle',
  curHotelOPt: [], // {} as IAutocompleteSelectOptionType,
  allHotelOpts: [],
  allHotelOptsInactive: [], 
  isHotelActive: true,
};

export const guestPaymentsHotelFilterSlice = createSlice({
  name: 'guestPaymentsHotelFilter',
  initialState,
  reducers: {
    changeHotelFilter: (state, action: PayloadAction<any>) => {
      state.curValue = action.payload;
      // if (action.payload != 0) {
      //   window.history.replaceState(
      //     '',
      //     '',
      //     getUpdateURLParameter(window.location.href, 'guest-hotel', action.payload)
      //   );
      // }
      // let op = state.allHotelOpts.find((option) => option.value === action.payload);
      // if (op !== undefined) {
      //   state.curHotelOPt = op;
      //   let opVal = parseInt(op.value);
      //   if (opVal != undefined) {
      //     let hotel = state.hotelNameIds.find((option) => option.id === opVal);
      //     if (hotel !== undefined) {
      //       state.isHotelActive = hotel.isActive;
      //     } else {
      //       state.isHotelActive = true;
      //     }
      //   }
      // }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHotelsNameId.pending, (state) => {
      state.hotelNameIds = [];
      hotelsLoading: 'loading';
    });
    builder.addCase(fetchHotelsNameId.fulfilled, (state, action) => {
      const hotels = action.payload.results as IHotelNameId[];
      state.hotelNameIds = hotels;
      let opts: IAutocompleteSelectOptionType[] = [];
      let optsInactive: IAutocompleteSelectOptionType[] = [];
      hotels.map((hotelNameId) => {
        // opts.push({ value: hotelNameId.id.toString(), label: hotelNameId.hotelName });
        opts.push({ value: hotelNameId.id.toString(), label: hotelNameId.hotelName, active: hotelNameId.isActive ? true : false, payout:hotelNameId.payout});
        optsInactive.push({ value: hotelNameId.id.toString(), label: hotelNameId.isActive ? hotelNameId.hotelName : (hotelNameId.hotelName + ' (Inactive)'), active: hotelNameId.isActive ? true : false ,payout:hotelNameId.payout });
      });
      state.allHotelOpts = [{value:"all",label:"All Hotels",active:true,payout:PAYOUT_STAFF},...opts];  
      state.allHotelOptsInactive = [{value:"all",label:"All Hotels",active:true,payout:PAYOUT_STAFF},...optsInactive];

      if (opts.length > 0) {
        if (state.curValue == 0) {
          let opt = opts.find((option) => option.value !== undefined);
          if(!opt || opt == undefined) {
            opt = opts[0]; 
          }
          if (opt != undefined) {
            //state.curHotelOPt = opt;
            state.curValue = parseInt(opt.value);
            let opVal = parseInt(opt.value);
            if (opVal != undefined) {
              let hotel = state.hotelNameIds.find((option) => option.id === opVal);
              if (hotel !== undefined) {
                state.isHotelActive = hotel.isActive;
              } else {
                state.isHotelActive = true;
              }
            }
          }
        } else {
          let opt = opts.find((option) => parseInt(option.value) == state.curValue);
          if(!opt || opt == undefined) {
            opt = opts[0]; 
          }
          if (opt != undefined) {
            //state.curHotelOPt = opt;
            state.curValue = parseInt(opt.value);
            let opVal = parseInt(opt.value);
            if (opVal != undefined) {
              let hotel = state.hotelNameIds.find((option) => option.id === opVal);
              if (hotel !== undefined) {
                state.isHotelActive = hotel.isActive;
              } else {
                state.isHotelActive = true;
              }
            }
          }
        }

        // opts.find((option) => {
        //   if (parseInt(option.value) == state.curValue) {
        //     state.curHotelOPt = option;
        //   }
        // });
      }
      hotelsLoading: 'succeeded';
    });
    builder.addCase(fetchHotelsNameId.rejected, (state, { payload }: any) => {
      //   state.loading = "error";
      // state.error = action.error.message;
      hotelsLoading: 'error';
    });
  },
});

// Action creators are generated for each case reducer function
export const { changeHotelFilter } = guestPaymentsHotelFilterSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectGuestPaymentsHotelFilterSlice = (state: RootState) =>
  state.guestPaymentsHotelFilter;

export const guestPaymentsHotelFilterReducer = guestPaymentsHotelFilterSlice.reducer;
