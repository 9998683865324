import React, { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import getSymbolFromCurrency from 'currency-symbol-map';
import Select, { Options } from 'react-select';
import { Store } from 'react-notifications-component';
import utc from 'dayjs/plugin/utc';
import { Autocomplete } from '@mui/material';
import { Box, Checkbox, Modal, TextField } from '@material-ui/core';

import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { changeSearch, selectMaidPaymentsSearchSlice } from './maidPaymentsSearchSlice';
import { setCurrentPage, selectMaidPaymentsPaginationSlice } from './maidPaymentsPaginationSlice';
import SearchBar from '../../components/SearchBar';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import {
  selectMaidPaymentsDataSlice,
  setFilterState,
  setFilterFrom,
  setFilterTo,
  setFlterIndex,
  setFilterPreserveDatesState,
  setmaidPayments,
} from './maidPaymentsDataSlice';
import { selectMaidPaymentsTimesheetSlice } from './maidTimesheetSlice';
import { selectMaidPaymentsTransactionSlice } from './maidTransactionsSlice';
import {
  IMaidPaymentFilterRequest,
  IMaidsPaymentsRequestData,
} from '../../models/maidPaymentsInterface';
import { IHotelPaymentTimesheetRequest } from '../../models/hotelPaymentsInterfaces';
import { IAutocompleteSelectOptionType } from '../../models/maidsInterfaces';

import {
  fetchMaidPayments,
  exportMaidPayments,
  getSampleTimesheet,
  fetchMaidTansactions,
  fetchMaidPaymentsFilterData,
  getApiTimesheetHourly,
} from '../../services/maidPaymentsService';
import DateFilterComponent from '../../components/DateFilterComponent';
import {
  changeHotelFilter,
  selectMaidPaymentsHotelFilterSlice,
} from './maidPaymentHotelFilterSlice';
import {
  fetchHotelsNameId,
  importTimesheet,
  fetchMaidTimeSheets,
} from '../../services/maidPaymentsService';

import { IMaidTransacionDetailsRequest } from '../../models/userDetailsInterfaces';
import { getLowerDateRange, getNextDateRange } from '../../helpers/dateOps';
import {
  selectMaidPaymentsFilterDataSlice,
  resetMaidPaymentsFilterData,
} from './maidPaymentsFilterDataSlice';
import { selectGuestPaymentsHotelFilterSlice } from '../GuestPayments/guestPaymentHotelFilterSlice';

import logo from './../../assets/img/csv.png';
import logo1 from './../../assets/img/csv-black.png';

import { maidPaymentsFields, maidPaymentsFieldsTransDate } from '../../config/tables';
import {
  notification_shown_time_success,
  notification_shown_time_failed,
  notification_shown_time_failed4,
  APP_NAME,
  PAYOUT_HOTEL,
  PAYOUT_STAFF,
} from '../../config/env';
import WorkedHorsTableField from '../../components/WorkedHoursTableField';
import {
  MSG_APPROVE_USERS_BEFORE_IMPORT,
  MSG_FILEIMPORT_INVALID_HEADER,
  MSG_INVALID_FILE_FORMAT,
  MSG_INVALID_INPUT_FILE,
  MSG_NO_RECORDS_IMPORTED,
  MSG_TIMESHEET_IMPORT_DATES_INVALID,
  MSG_TIMESHEET_IMPORT_SUCESS,
} from '../../config/messages';
import {
  checkPagePermission,
  checkActionPermission,
  PERM_MAID_PAYMENTS_VIEW,
  PERM_MAID_PAYMENTS_CSV,
  PERM_MAID_PAYMENTS_IMPORT,
  PERM_MAID_PAYMENTS_EXPORT,
} from '../../config/permissions';

import {
  setSelectedHotelId,
  getSelectedHotelId,
  getHotelUserRole,
} from '../../services/localStorage';
import {
  LBL_CSV_DATE,
  LBL_CSV_HOURLY,
  LBL_INCLUDE_INACTIVE_HOTELS,
  LBL_MAID,
} from '../../config/common_labels';
import MultiSelect from '../../components/MultiSelect';

export const MaidPayments = (props: any) => {
  let menuRef = React.useRef<HTMLDivElement>(null);
  let hotelUserRole = getHotelUserRole();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const { searchValue } = useAppSelector(selectMaidPaymentsSearchSlice);
  const { currentPage, isLoading } = useAppSelector(selectMaidPaymentsPaginationSlice);
  const { maidPayments, filterState, filterFrom, filterTo, filterIndex, totalSize, loading, filterDateRange, maidPaymentsLoading } =
    useAppSelector(selectMaidPaymentsDataSlice);
  const { timesheetDetails } = useAppSelector(selectMaidPaymentsTimesheetSlice);
  const { transactionDetails } = useAppSelector(selectMaidPaymentsTransactionSlice);
  const { maidPaymentsFilterData } = useAppSelector(selectMaidPaymentsFilterDataSlice);
  const [maidDeatilsWidth, setMaidDeatilsWidth] = useState<number>(0);
  const [transactionsWidth, setTransactionsWidth] = useState<number>(0);
  const [refHidder, setRefHidder] = useState<boolean>(true);
  const {
    hotelNameIds,
    curValue,
    // allHotelOpts, curHotelOPt,
    isHotelActive,
  } = useAppSelector(selectMaidPaymentsHotelFilterSlice);
  const { curHotelOPt, allHotelOpts, allHotelOptsInactive } = useAppSelector(
    selectGuestPaymentsHotelFilterSlice
  );
  const dispatch = useAppDispatch();
  dayjs.extend(utc);
  const [logoState, setLogoState] = useState(true);
  const [applySearch, setApplySearch] = useState(0);
  const [localStorageHotelId, setLocalStorageHotelId] = useState(getSelectedHotelId());
  const [selHotelPayout, setSelHotelPayout] = useState(0);
  const [csvState, setCsvState] = useState(false);

  const [inactiveHotel, setInactiveHotel] = useState(false);
  const [activeHotel, setactiveHotel] = useState([]);

  const [multiSearchText, setMultiSearchText] = useState('');
  const [hotelListObj, setHotelListObj] = useState<IAutocompleteSelectOptionType[]>([]); // useState(curHotelOPt);
  const [hotelList, setHotelList] = useState<string[]>([]); // useState<number[]>([]);
  const [includePayout, setIncludePayout] = useState(false);
  const [currHotelIds, setCurrHotelIds] = useState<string>(''); 
  const [searchDetailsChanged, setSearchDetailsChanged] = useState(true); 

  React.useEffect(() => { 
    if(maidPaymentsLoading!='loading' && maidPaymentsLoading!='idle') {
      setSearchDetailsChanged(false); 
    }
  }, [maidPaymentsLoading]);  

  React.useEffect(() => {
    let arr: any = [];
    allHotelOpts.filter((d: any) => (d.active == true ? arr.push(d) : null));
    setactiveHotel(arr);
  }, [allHotelOpts, inactiveHotel]);

  React.useEffect(() => {
    // dispatch(fetchHotelsNameId(''));
    // let search = window.location.search;
    // let params = new URLSearchParams(search);
    // if (localStorageHotelId) {
    //     dispatch(changeHotelFilter(localStorageHotelId));
    // }
    // else if (props.match.params.id !== undefined) {
    //     dispatch(changeHotelFilter(props.match.params.id));
    //     setSelectedHotelId(props.match.params.id);
    //     setLocalStorageHotelId(props.match.params.id);
    // } else if (params.get('maid-pay-hotel') != undefined) {
    //     dispatch(changeHotelFilter(params.get('maid-pay-hotel')));
    //     setSelectedHotelId(params.get('maid-pay-hotel'));
    //     setLocalStorageHotelId(params.get('maid-pay-hotel'));
    // }

    // if (params.get('mp-filter') != undefined) {
    //     dispatch(setFilterPreserveDatesState(params.get('mp-filter')));
    // }
    return function cleanup() {
      dispatch(changeHotelFilter(0));
    };
  }, []);

  const fetchUpdates = (): void => {
    let hIds = hotelList.join(',').replace('all,','');
    if (searchDetailsChanged || (hotelList.length > 0 && hIds!=currHotelIds)) {
      const hasAllOption = hotelList.some((hotel) => hotel === 'all');
      let hotelsList =
        hasAllOption && inactiveHotel
          ? 'all'
          : hasAllOption && !inactiveHotel
          ? 'active_all'
          : hIds;
      if(hotelsList && hotelsList.length > 0) {    
        const req: IMaidsPaymentsRequestData = {
          page: currentPage,
          //hotel: hotelList.length > 0 ? hotelList.join() : '0',
          hotel: hotelsList,
          search: searchValue.trim(),
          start: encodeURIComponent(filterFrom),
          end: encodeURIComponent(filterTo),
          type: filterState,
          additional: filterDateRange,
        };
        dispatch(fetchMaidPayments(req));
        const filterReq: IMaidPaymentFilterRequest = {
          search: searchValue.trim(),
          start_date: encodeURIComponent(filterFrom),
          end_date: encodeURIComponent(filterTo),
          hotel: hotelsList,
          type: filterState,
        };
        dispatch(fetchMaidPaymentsFilterData(filterReq));
        setCurrHotelIds(hIds); 
      }
    } else if (hotelList.length==0) { 
      dispatch(resetMaidPaymentsFilterData());
      dispatch(setmaidPayments([]));
      setCurrHotelIds(''); 
    }
  };
 
//   const fetchUpdates = (): void => {
//     if (hotelList.length > 0) {
//       const hasAllOption = hotelList.some((hotel) => hotel === 'all');
//       let hotelsList =
//         hasAllOption && inactiveHotel
//           ? 'all'
//           : hasAllOption && !inactiveHotel
//           ? 'active_all'
//           : hotelList.join();
//       const req: IMaidsPaymentsRequestData = {
//         page: currentPage,
//         //hotel: hotelList.length > 0 ? hotelList.join() : '0',
//         hotel: hotelsList,
//         search: searchValue.trim(),
//         start: encodeURIComponent(filterFrom),
//         end: encodeURIComponent(filterTo),
//         type: filterState,
//         additional: filterDateRange,
//       };
//       dispatch(fetchMaidPayments(req));
//       const filterReq: IMaidPaymentFilterRequest = {
//         search: searchValue.trim(),
//         start_date: encodeURIComponent(filterFrom),
//         end_date: encodeURIComponent(filterTo),
//         hotel: hotelsList,
//         type: filterState,
//       };
//       dispatch(fetchMaidPaymentsFilterData(filterReq));
//     } else {
//       dispatch(resetMaidPaymentsFilterData());
//       dispatch(setmaidPayments([]));
//     }
//   };

  useEffect(() => {
    let handle = (e: any) => {
      if (!menuRef.current?.contains(e.target)) {
        setCsvState(false);
        // console.log("donnnu",menuRef.current);
      }
    };
    document.addEventListener('mousedown', handle);

    return () => {
      document.removeEventListener('mousedown', handle);
    };
  }, []);

  const handleClick = () => {
    setCsvState(!csvState);
  };

  // React.useEffect(() => {
  //     if (curValue !== 0) {
  //         setSelectedHotelId(curValue);
  //         setLocalStorageHotelId(curValue.toString());
  //         fetchUpdates();
  //         let selPayout = hotelNameIds.find(item => item.id == curValue)?.payout;
  //         if (selPayout) {
  //             setSelHotelPayout(selPayout);
  //         }
  //     }
  // }, [curValue, applySearch, currentPage, filterState, filterFrom, filterTo]);

  React.useEffect(() => {
    if (curValue !== 0) {
      fetchUpdates();
      let selPayout = hotelNameIds.find((item) => item.id == curValue)?.payout;
      if (selPayout) {
        setSelHotelPayout(selPayout);
      }
    }
  }, [curValue, applySearch, currentPage, filterState, hotelList]); 

  React.useEffect(() => {
    setSearchDetailsChanged(true); 
  }, [filterFrom, filterTo, filterIndex]);

  React.useEffect(() => {
    fetchUpdates();
  }, [searchDetailsChanged]);

  const onSearchButton = () => {
    acceptPage(1);
    setApplySearch(applySearch + 1);
  };

  const acceptSearch = (e: any): void => {
    dispatch(changeSearch(e.target.value));
  };

  const acceptPage = (e: any): void => {
    dispatch(setCurrentPage(e));
  };

  const setStartDate = (e: Date): void => {
    dispatch(setFilterFrom(dayjs(e).format(`YYYY-MM-DDTHH:mm:ssZZ`)));
  };
  const setEndDate = (e: Date): void => {
    dispatch(setFilterTo(dayjs(e).format(`YYYY-MM-DDT23:59:59ZZ`)));
  };

  const setLess = (): void => {
    let date_dange = getLowerDateRange(filterFrom, filterTo, 'mp');
    dispatch(setFilterFrom(date_dange[0]));
    dispatch(setFilterTo(date_dange[1]));
    dispatch(setFlterIndex('-'));
  };

  const setMore = (): void => {
    let date_dange = getNextDateRange(filterFrom, filterTo, 'mp');
    dispatch(setFilterFrom(date_dange[0]));
    dispatch(setFilterTo(date_dange[1]));
    dispatch(setFlterIndex('+'));
  };

  const onExport = (): void => {
    const req: IMaidsPaymentsRequestData = {
      page: currentPage,
      search: searchValue?.trim(),
      start: encodeURIComponent(filterFrom),
      end: encodeURIComponent(filterTo),
      hotel: hotelList.length > 0 ? hotelList.join() : '0',
      type: filterState,
      additional: filterDateRange,
    };
    exportMaidPayments(req);
  };


  const downloadSample = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    getSampleTimesheet();
  };
  const downloadHourlySample = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    getApiTimesheetHourly();
  };

  const closeSlider = (): void => {
    setMaidDeatilsWidth(0);
    setTransactionsWidth(0);
  };

  const onClickable = (id: number, name: string): void => {
    setMaidDeatilsWidth(0);
    setTransactionsWidth(0);
    if (name === 'totalEarnings') {
      const req: IMaidTransacionDetailsRequest = {
        start: encodeURIComponent(filterFrom),
        end: encodeURIComponent(filterTo),
        hotel_id: curValue,
        maid_id: id,
      };
      dispatch(fetchMaidTansactions(req));
      setTransactionsWidth(55);
    }
    if (name === 'name') {
      const req: IHotelPaymentTimesheetRequest = {
        start: encodeURIComponent(filterFrom),
        end: encodeURIComponent(filterTo),
        hotel_id: curValue,
        maid_id: id,
      };
      dispatch(fetchMaidTimeSheets(req));
      setMaidDeatilsWidth(40);
    }
  };

  const dateFilter = (): JSX.Element => {
    if (filterState === 'transDate') {
      return (
        <div className="div-left" id="transDate">
          <div className="div-left">
            <div className="div-left margin-left-15px pay-period">From</div>
          </div>
          <div className="div-left  margin-left-5px pointer-cursor">
            <DateFilterComponent
              defaultDate={dayjs(filterFrom).toDate()}
              setDate={setStartDate}
              format={`YYYY-MM-DDTHH:mm:ssZZ`}
              page="mp"
              type="start"
            />
          </div>
          <div className="div-left">
            <div className="div-left margin-left-15px pay-period">To</div>
          </div>
          <div className="div-left  margin-left-5px pointer-cursor">
            <DateFilterComponent
              defaultDate={dayjs(filterTo).toDate()}
              minDate={filterFrom}
              setDate={setEndDate}
              format={`YYYY-MM-DDTHH:mm:ssZZ`}
              page="mp"
              type="end"
            />
          </div>
        </div>
      );
    }
    return <span />;
  };

  const payPeriodFilter = (): JSX.Element => {
    if (filterState === 'payPeriod') {
      return (
        <div className="div-left" id="payPeriod">
          {loading != 'loading' ? <div onClick={() => setLess()} className="box-data div-left pointer-cursor">
            &lt;
          </div> : <div className="box-data div-left ">
            &lt;
          </div>}
          <div className="box-data box-border-blue div-left">
            {dayjs(filterFrom).format('MMM DD, YYYY')}
          </div>
          <div className="box-data box-border-blue div-left">
            {dayjs(filterTo).format('MMM DD, YYYY')}
          </div>
          {loading != 'loading' ? <div onClick={() => setMore()} className="box-data div-left pointer-cursor">
            &gt;
          </div> : <div className="box-data div-left ">
            &gt;
          </div>}
        </div>
      );
    }
    return <span />;
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    dispatch(setFilterState(e.target.value));
  };

//   const handleHotelFilterChange = (e: any): void => {
//     acceptPage(1);
//     dispatch(changeHotelFilter(e.value));
//     setSelectedHotelId(e.value);
//     setLocalStorageHotelId(e.value);
//   };

  const onImport = (): void => {
    inputFile?.current?.click();
  };

  const postTimesheets = (e: any): void => {
    let file = e.target.files[0];

    let reader = new FileReader();
    reader.readAsText(e.target.files[0], 'UTF-8');

    reader.onload = (e) => {
      let firstline = e.target?.result;
      if (JSON.stringify(firstline).slice(0, 100).toLocaleLowerCase().includes('start_time')) {
        (async () => {
          const uploadStatus = await importTimesheet(curValue, file, '');
          console.log('uploadStatus:: ', uploadStatus);
          if (uploadStatus?.status === 200) {
            fetchUpdates();

            let msgTitle = APP_NAME;
            let msgText = MSG_TIMESHEET_IMPORT_SUCESS;
            let dismisObj = {
              duration: notification_shown_time_success,
              onScreen: true,
              click: true,
              showIcon: false,
            };

            if (uploadStatus?.data?.importedCount === 0) {
              msgText = MSG_NO_RECORDS_IMPORTED;
              dismisObj = {
                duration: notification_shown_time_success,
                onScreen: true,
                click: false,
                showIcon: false,
              };
            } else if (
              uploadStatus?.data?.importedCount > 0 &&
              uploadStatus?.data?.importedCount === uploadStatus?.data?.totalCount
            ) {
              msgText =
                MSG_TIMESHEET_IMPORT_SUCESS +
                ' ' +
                uploadStatus.data.importedCount +
                '/' +
                uploadStatus.data.totalCount +
                ' records imported. ';
              dismisObj = {
                duration: notification_shown_time_success,
                onScreen: true,
                click: false,
                showIcon: false,
              };
            } else if (
              uploadStatus?.data?.notImportedNumbers &&
              uploadStatus?.data?.notImportedNumbers.length > 0
            ) {
              let recText =
                uploadStatus.data.notImportedNumbers.length === 1 ? ' record ' : ' records ';
              msgText =
                uploadStatus.data.importedCount +
                '/' +
                uploadStatus.data.totalCount +
                ' records imported. The following ' +
                recText +
                ' not imported ' +
                uploadStatus.data.notImportedNumbers.map((item: any) => item).join(', ');
              dismisObj = {
                duration: 0,
                onScreen: true,
                click: true,
                showIcon: true,
              };
            } else if (uploadStatus?.data?.importedCount > 0) {
              msgText =
                uploadStatus.data.importedCount +
                '/' +
                uploadStatus.data.totalCount +
                ' records imported.';
              dismisObj = {
                duration: 0,
                onScreen: true,
                click: true,
                showIcon: true,
              };
            }

            Store.addNotification({
              title: msgTitle,
              message: msgText,
              type:
                uploadStatus.data.importedCount > 0 &&
                uploadStatus.data.importedCount === uploadStatus.data.totalCount
                  ? 'success'
                  : 'danger',
              insert: 'top',
              container: 'top-center',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: dismisObj,
            });
          } else {
            if (uploadStatus.response.status === 400) {
              // {"row":2,"mobile_number":"+918086555780","message":"have_future_date"}
              let msgTitle = MSG_INVALID_FILE_FORMAT;
              let msgText = MSG_INVALID_INPUT_FILE;
              let dismisObj = {
                duration: notification_shown_time_failed,
                onScreen: true,
                click: false,
                showIcon: false,
              };
              if (
                uploadStatus?.response?.data?.message &&
                uploadStatus?.response?.data?.message === 'have_future_date'
              ) {
                msgText =
                  MSG_TIMESHEET_IMPORT_DATES_INVALID +
                  ' Check row ' +
                  uploadStatus.response.data.row +
                  ', mobile number ' +
                  uploadStatus.response.data.mobileNumber +
                  '.';
                dismisObj = {
                  duration: 0,
                  onScreen: true,
                  click: true,
                  showIcon: true,
                };
              } else if (
                uploadStatus?.response?.data?.message &&
                uploadStatus?.response?.data.message === 'invalid_headings'
              ) {
                msgText = MSG_FILEIMPORT_INVALID_HEADER;
                dismisObj = {
                  duration: 0,
                  onScreen: true,
                  click: true,
                  showIcon: true,
                };
              } else if (
                uploadStatus?.response?.data?.message &&
                uploadStatus?.response?.data.message === 'un_approved_maid'
              ) {
                msgText = MSG_APPROVE_USERS_BEFORE_IMPORT + ' Check ';
                var i = 0;
                while (i < uploadStatus.response.data.details.length) {
                  msgText += ' row ' + uploadStatus.response.data.details[i]['row'];
                  msgText +=
                    ', mobile number ' +
                    uploadStatus.response.data.details[i]['mobileNumber'] +
                    '.';
                  i++;
                }
                dismisObj = {
                  duration: 0,
                  onScreen: true,
                  click: true,
                  showIcon: true,
                };
              }

              Store.addNotification({
                title: msgTitle,
                message: msgText,
                type: 'danger',
                insert: 'top',
                container: 'top-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: dismisObj,
              });
            }
          }
        })();
        inputFile!.current!.value = '';
      } else {
        Store.addNotification({
          title: 'Refund',
          id: 'import-modal',
          content: (
            <div className="refund-modal">
              <div className="refund-flex-center page-heading">{/* <h4 >Delete </h4> */}</div>
              <div className="refund-flex-center">
                Are you sure you want to import this timesheet to pay period starting with{' '}
                {dayjs(filterFrom).format('MMM DD, YYYY')} ?
              </div>
              <div className="refund-margin"></div>
              <div className="refund-flex-center">
                <button
                  type="button"
                  className=" primary "
                  onClick={() => {
                    importWithoutStartDate(file);
                    Store.removeNotification('import-modal');
                  }}
                >
                  Import
                </button>
                <button
                  type="button"
                  className="primary margin-left-15px"
                  onClick={() => {
                    Store.removeNotification('import-modal');
                    inputFile!.current!.value = '';
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          ),
          type: 'default',
          container: 'center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 1000000000,
            click: false,
            touch: false,
          },
        });
      }
    };
  };

  const importWithoutStartDate = (file: any): void => {
    (async () => {
      const uploadStatus = await importTimesheet(
        curValue,
        file,
        dayjs(filterFrom).format('MM/DD/YYYY')
      );
      console.log('uploadStatus:: ', uploadStatus);
      if (uploadStatus?.status === 200) {
        fetchUpdates();

        let msgTitle = APP_NAME;
        let msgText = MSG_TIMESHEET_IMPORT_SUCESS;
        let dismisObj = {
          duration: notification_shown_time_success,
          onScreen: true,
          click: true,
          showIcon: false,
        };

        if (uploadStatus?.data?.importedCount === 0) {
          msgText = MSG_NO_RECORDS_IMPORTED;
          dismisObj = {
            duration: notification_shown_time_success,
            onScreen: true,
            click: false,
            showIcon: false,
          };
        } else if (
          uploadStatus?.data?.importedCount > 0 &&
          uploadStatus?.data?.importedCount === uploadStatus?.data?.totalCount
        ) {
          msgText =
            MSG_TIMESHEET_IMPORT_SUCESS +
            ' ' +
            uploadStatus.data.importedCount +
            '/' +
            uploadStatus.data.totalCount +
            ' records imported. ';
          dismisObj = {
            duration: notification_shown_time_success,
            onScreen: true,
            click: false,
            showIcon: false,
          };
        } else if (
          uploadStatus?.data?.notImportedNumbers &&
          uploadStatus?.data?.notImportedNumbers.length > 0
        ) {
          let recText =
            uploadStatus.data.notImportedNumbers.length === 1 ? ' record ' : ' records ';
          msgText =
            uploadStatus.data.importedCount +
            '/' +
            uploadStatus.data.totalCount +
            ' records imported. The following ' +
            recText +
            ' not imported ' +
            uploadStatus.data.notImportedNumbers.map((item: any) => item).join(', ');
          dismisObj = {
            duration: 0,
            onScreen: true,
            click: true,
            showIcon: true,
          };
        } else if (uploadStatus?.data?.importedCount > 0) {
          msgText =
            uploadStatus.data.importedCount +
            '/' +
            uploadStatus.data.totalCount +
            ' records imported.';
          dismisObj = {
            duration: 0,
            onScreen: true,
            click: true,
            showIcon: true,
          };
        }

        Store.addNotification({
          title: msgTitle,
          message: msgText,
          type:
            uploadStatus.data.importedCount > 0 &&
            uploadStatus.data.importedCount === uploadStatus.data.totalCount
              ? 'success'
              : 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: dismisObj,
        });
      } else {
        if (uploadStatus.response.status === 400) {
          // {"row":2,"mobile_number":"+918086555780","message":"have_future_date"}
          let msgTitle = MSG_INVALID_FILE_FORMAT;
          let msgText = MSG_INVALID_INPUT_FILE;
          let dismisObj = {
            duration: notification_shown_time_failed,
            onScreen: true,
            click: false,
            showIcon: false,
          };

          if (
            uploadStatus?.response?.data?.message &&
            uploadStatus?.response?.data?.message === 'have_future_date'
          ) {
            msgText =
              MSG_TIMESHEET_IMPORT_DATES_INVALID +
              ' Check row ' +
              uploadStatus.response.data.row +
              ', mobile number ' +
              uploadStatus.response.data.mobileNumber +
              '.';
            dismisObj = {
              duration: 0,
              onScreen: true,
              click: true,
              showIcon: true,
            };
          } else if (
            uploadStatus?.response?.data?.message &&
            uploadStatus?.response?.data.message === 'invalid_headings'
          ) {
            msgText = MSG_FILEIMPORT_INVALID_HEADER;
            dismisObj = {
              duration: 0,
              onScreen: true,
              click: true,
              showIcon: true,
            };
          } else if (
            uploadStatus?.response?.data?.message &&
            uploadStatus?.response?.data.message === 'un_approved_maid'
          ) {
            msgText = MSG_APPROVE_USERS_BEFORE_IMPORT + ' Check ';
            var i = 0;
            while (i < uploadStatus.response.data.details.length) {
              msgText += ' row ' + uploadStatus.response.data.details[i]['row'];
              msgText +=
                ', mobile number ' + uploadStatus.response.data.details[i]['mobileNumber'] + '.';
              i++;
            }
            dismisObj = {
              duration: 0,
              onScreen: true,
              click: true,
              showIcon: true,
            };
          }

          Store.addNotification({
            title: msgTitle,
            message: msgText,
            type: 'danger',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: dismisObj,
          });
        }
      }
    })();
    inputFile!.current!.value = '';
  };

  // useEffect(() => {
  //     document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-light-grey');
  // }, [])

  function selectBoxOnFocus() {
    document
      .getElementsByClassName('react-select-maids__single-value')[0]
      .classList.add('color-grey');
    document
      .getElementsByClassName('react-select-maids__single-value')[0]
      .classList.remove('color-light-grey');
  }
  function selectBoxOnBlur() {
    document
      .getElementsByClassName('react-select-maids__single-value')[0]
      .classList.remove('color-grey');
    document
      .getElementsByClassName('react-select-maids__single-value')[0]
      .classList.add('color-light-grey');
  }


  return (
    <>
      <div className="content_container">
        <div className="box">
          {/* <div className="col-12 row mb-1" >
                        <h4 className="page-heading div-left">{LBL_MAID} Payments </h4>
                    </div> */}
          <div className="row">
            <div className="col-12 mb-3">
              <h4 className="page-heading div-left padding-top5 ">Payments of </h4>
              <div className="margin-left-15px div-left col-3 ">
                <MultiSelect
                  getHotellist={setHotelList}
                  getIncludeInactiveStatus={setInactiveHotel}
                  getIncludePayoutStatus={setIncludePayout}
                  includePayout={false}
                />
                {/* <Select
                                    onChange={handleHotelFilterChange}
                                    name="hotelName"
                                    className=""
                                    onFocus={selectBoxOnFocus}
                                    onBlur={selectBoxOnBlur}
                                    classNamePrefix="react-select-maids"
                                    options={allHotelOpts}
                                    value={curHotelOPt}
                                    isSearchable={true}
                                /> */}

               
              </div>
              <div className="div-left margin-left-25px">
                <select
                  value={filterState}
                  name="payPeriod"
                  className="div-left box-data box-border-blue sel-box min-width-170"
                  onChange={(e) => handlePeriodChange(e)}
                >
                  <option value="payPeriod">Pay Period</option>
                  <option value="transDate">Transaction Date</option>
                </select>
                {payPeriodFilter()}

                {dateFilter()}
              </div>
            </div>
            {!isHotelActive && <div className="warning-text">Inactive.</div>}
          </div>

          <div className="col-12 justify-space-between">
            <div className="col-12">
              <div className="page-sub-heading col-3 div-left ">
                <div>
                  {filterState == 'transDate'
                    ? 'Total Amount'
                    : filterState == 'payPeriod'
                    ? maidPaymentsFilterData.totalPendingDistribution > 0
                      ? 'Total Amount to be Processed'
                      : 'Total Amount Processed'
                    : 'Total Amount Processed'}
                </div>
                <div className="page-sub-data">
                  <h4>
                    {getSymbolFromCurrency(maidPaymentsFilterData.currency || 'usd')}{' '}
                    {filterState == 'transDate'
                      ? (
                          maidPaymentsFilterData.totalDistributed +
                          maidPaymentsFilterData.totalPendingDistribution
                        ).toFixed(2)
                      : filterState == 'payPeriod'
                      ? maidPaymentsFilterData.totalPendingDistribution > 0
                        ? maidPaymentsFilterData.totalPendingDistribution.toFixed(2)
                        : maidPaymentsFilterData.totalDistributed.toFixed(2)
                      : maidPaymentsFilterData.totalDistributed.toFixed(2)}
                  </h4>
                  <div>
                    {Number(maidPaymentsFilterData.totalDistributedFailed) !== 0 && (
                      <div className="danger">
                        Failed : {getSymbolFromCurrency(maidPaymentsFilterData.currency || 'usd')}{' '}
                        {maidPaymentsFilterData.totalDistributedFailed.toFixed(2)}
                      </div>
                    )}
                    {filterState == 'payPeriod' &&
                      maidPaymentsFilterData.totalPendingDistribution > 0 && (
                        <div className="color-green">Upcoming</div>
                      )}
                  </div>
                </div>
              </div>

              {/* <div className="page-sub-heading col-3 div-left margin-left-25px">
                                <div>Total Commission</div>
                                <div className="page-sub-data">
                                    <h4 className="div-left margin-right-5px">
                                        {getSymbolFromCurrency(maidPaymentsFilterData.currency || 'usd')}{' '}
                                        {maidPaymentsFilterData.totalCommission.toFixed(2)}
                                    </h4>{' '}
                                </div>
                            </div> */}
              <div className="page-sub-heading col-3 div-left margin-left-25px">
                <div>Total Transactions</div>
                <div className="page-sub-data">
                  <h4 className="div-left margin-right-5px">
                    {maidPaymentsFilterData.totalTransactions}{' '}
                  </h4>{' '}
                  <div className="margin-left-15px margin-top2">
                    {' '}
                    (
                    <span className="success" title="Success transactions">
                      {maidPaymentsFilterData.successTransactions}
                    </span>
                    /
                    <span className="danger" title="Failed transactions">
                      {maidPaymentsFilterData.failedTransactions}
                    </span>
                    )
                  </div>
                </div>
              </div>
              <div>
                {/* <button type="button" className="margin-left-25px primary" onClick={(e)=>reprocessFailed('all')}>
                                    Reprocess failed transactions - All Hotels
                                </button> 
                                <button type="button" className="margin-left-25px primary margin-top10" onClick={(e)=>reprocessFailed('')}>
                                    Reprocess failed transactions - {curHotelOPt.label}
                                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content_container">
        <div className="box content-box">
          <div className="justify-space-between">
            <SearchBar
              onSearch={acceptSearch}
              currentSearch={searchValue}
              place_holder="&#xf002; Search name or mobile number"
              searchButton={true}
              onSearchButton={onSearchButton}
            />

            <div className="div-right align-csv">
              {selHotelPayout != PAYOUT_HOTEL && (
                <>
                  {checkActionPermission(hotelUserRole, PERM_MAID_PAYMENTS_CSV) && (
                    <>
                      {refHidder && (
                        <input
                          type="file"
                          onChange={postTimesheets}
                          id="file"
                          ref={inputFile}
                          style={{ display: 'none' }}
                        />
                      )}

                      <div className="csvmain" ref={menuRef}>
                        <button
                          onMouseEnter={() => setLogoState(false)}
                          onMouseLeave={() => setLogoState(true)}
                          type="button"
                          className="primary csv-button margin-left-15px"
                          // onClick={downloadSample}
                          onClick={handleClick}
                          title="CSV template for importing timesheet."
                        >
                          <img
                            src={logoState ? logo : logo1}
                            className="info-sample invert-filter"
                            title="CSV template for importing timesheet."
                          ></img>
                        </button>
                        <div className={csvState ? 'CsvDropdown' : 'hide'}>
                          <ul>
                            <li onClick={getApiTimesheetHourly}>{LBL_CSV_HOURLY}</li>
                            <li onClick={downloadSample}>{LBL_CSV_DATE}</li>
                          </ul>
                        </div>
                      </div>
                    </>
                  )}

                  {checkActionPermission(hotelUserRole, PERM_MAID_PAYMENTS_IMPORT) &&
                  hotelList && hotelList.length==1 &&
                    filterState == 'payPeriod' && (
                      <button
                        type="button"
                        className="primary margin-left-15px"
                        onClick={() => onImport()}
                      >
                        Import Timesheet
                      </button>
                    )}

                  {checkActionPermission(hotelUserRole, PERM_MAID_PAYMENTS_EXPORT) && maidPayments.length > 0 &&(
                    <button
                      type="button"
                      className="primary margin-left-15px"
                      onClick={() => onExport()}
                    >
                      Export {LBL_MAID} Payments
                    </button>
                  )}
                </>
              )}
            </div>
          </div>

          <div className=" table-wrapper">
            <Table
              titles={filterState == 'transDate' ? maidPaymentsFieldsTransDate : maidPaymentsFields}
              data={maidPayments ? maidPayments : []}
              isLoading={loading === `loading` ? true : false}
              onClickable={onClickable}
              currentPage={0}
            />
            <div className="justify-right">
              <div className="pagination">
                <Pagination
                  isTableLoading={loading === `loading` ? true : false}
                  totalCount={totalSize}
                  currentPage={currentPage}
                  setCurrentPage={acceptPage}
                  pageSize={20}
                  siblingCount={2}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="sliderMaidDetails" className="sidenav" style={{ width: maidDeatilsWidth + '%' }}>
        <div className="box">
          <div className="row">
            <div className="col">
              <label className="h5 page-heading">Timesheet of {timesheetDetails.name}</label>
              <span className="closebtn" onClick={closeSlider}>
                ×
              </span>
            </div>
          </div>
          <div className="page-sub-heading col-10">
            <div>Total Amount Processed: $ {timesheetDetails.totalAmount}</div>
            <div>
              {timesheetDetails.totalAmountFailed &&
                Number(timesheetDetails.totalAmountFailed) !== 0 && (
                  <span className="danger">
                    Failed : $ {timesheetDetails.totalAmountFailed || '0.00'}
                  </span>
                )}
            </div>
            <div className="page-sub-data">
              {/* <h4>Success : $ {timesheetDetails.totalAmount}</h4> */}
              <small>
                Period: {dayjs(filterFrom).format('MM/DD/YYYY HH:mm')} -{' '}
                {dayjs(filterTo).format('MM/DD/YYYY HH:mm')}
              </small>
            </div>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Hours Worked</th>
                <th>Time Duration</th>
              </tr>
            </thead>
            <tbody>
              {timesheetDetails.data !== undefined &&
                timesheetDetails.data.map((timesheet) => {
                  return (
                    <tr key={timesheet.shiftStart}>
                      <td>{dayjs(timesheet.shiftStart).utc().format('MM/DD/YYYY')}</td>
                      <td>
                        <WorkedHorsTableField value={timesheet.workedHours} />
                      </td>
                      <td>
                        {timesheet.shiftStart != timesheet.shiftEnd && (
                          <>
                            {dayjs(timesheet.shiftStart).utc().format('MM/DD/YYYY HH:mm')} to{' '}
                            {dayjs(timesheet.shiftEnd).utc().format('MM/DD/YYYY HH:mm')}
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {timesheetDetails.data && timesheetDetails.data.length > 5 && (
            <div className="row justify-right">
              <div className="col slider-close-button justify-right">
                <button type="button" className="primary" onClick={() => closeSlider()}>
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div id="sliderTransactions" className="sidenav" style={{ width: transactionsWidth + '%' }}>
        <div className="box">
          <div className="row">
            <div className="col">
              <label className="h5 page-heading">
                Transaction details of {transactionDetails.name}
              </label>
              <span className="closebtn" onClick={closeSlider}>
                ×
              </span>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Hours Worked</th>
                <th>Payment Status</th>
                <th>Transaction Id</th>
                <th>Amount Distributed</th>
              </tr>
            </thead>
            <tbody>
              {transactionDetails.data !== undefined &&
                transactionDetails.data.map((transaction) => {
                  return (
                    <tr key={transaction.transactionDate}>
                      <td>
                        {
                          // transaction.transactionDate.replace(/\T.*/, '')
                          dayjs(transaction.transactionDate).utc().format('MM/DD/YYYY')
                        }
                      </td>
                      <td>{transaction.workedTimeCalculated}</td>
                      <td>{transaction.paymentStatus}</td>
                      <td>{transaction.transactionId}</td>
                      <td align="right">{transaction.amountDistributed}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {transactionDetails.data && transactionDetails.data.length > 6 && (
            <div className="row justify-right">
              <div className="col slider-close-button justify-right">
                <button type="button" className="primary" onClick={() => closeSlider()}>
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
