import React, { useEffect, useState,useRef } from 'react';
import { navBarList, navBarListMultiHotel } from './NavbarList';
import './pageLayout.css';
import { useHistory, useLocation } from 'react-router-dom';
import { removeHotelTokens } from '../../services/localStorage';
import logo from './../../assets/img/tova-logo.png';
import settingsImage from './../../assets/img/settings.png';
import { getHotelUserHotelIds, getHotelUserRole, getHotelUserName } from '../../services/localStorage';
import { APP_NAME, USER_ROLE_HOTEL_MANAGER, USER_ROLE_HOTEL_USER } from '../../config/env';
import { LBL_HOTEL_PAYMENTS, LBL_ASSOCIATES } from '../../config/common_labels';
import { checkPagePermission } from '../../config/permissions';

function Navbar() {
  let hotelIds = getHotelUserHotelIds();
  let arrHotelIds = hotelIds?.split(',');
  let hotelUserRole = getHotelUserRole();
  const [click, setClick] = useState(false)
  const handleclk =()=>{
    setClick(!click)
  }

  const history = useHistory();
  const path = useLocation().pathname;
  const loc = useLocation();
  const adminUserName = getHotelUserName();
  let userNavBarList = navBarList;
  if (arrHotelIds && arrHotelIds.length > 1) {
    userNavBarList = navBarListMultiHotel;
  }

  const hotelLogo = localStorage.getItem('hotelLogo');
  let menuRef = React.useRef<HTMLDivElement>(null)


  useEffect(() => {
    let handle = (e:any)=>{
     if(!menuRef.current?.contains(e.target)){
       setClick(false)
       // console.log("donnnu",menuRef.current); 
     } 
    }
    document.addEventListener("mousedown",handle)
 
    return()=> {
     document.removeEventListener("mousedown",handle)
    }
   }, [])

  function checkIfActive(index: number) {
    let activeIndex = userNavBarList.findIndex((item) => item.path === path);
    if (activeIndex == -1) {
      const pathArr = path.split('/');
      if (pathArr.length > 2) {
        activeIndex = userNavBarList.findIndex(
          (item) => item.path === pathArr[0] + '/' + pathArr[1] + '/' + pathArr[2]
        );
      }
    }
    if (index == activeIndex) {
      return 'active';
    }
    return '';
  }

  function handleClick(path: string) {
    history.push(path + window.location.search);
  }

  function sideImage(item: any) {
    if (item.path === path) {
      return item.imgSrcActive;
    } else {
      return item.imgSrc;
    }
  }
  const handleLogout = () => {
    removeHotelTokens();
    history.push('/v1');
  };

  return (
    <>
      <div>
        <div className="header justify-space-between">
          <div className="justify-left margin-left-24px">
            <div className="div-left">
              <div>
                {/* <img className="small" style={{ height: 70, marginTop: 5 }} src={logo} alt={APP_NAME} /> */}
                <img className='nav_img' src={logo} alt={APP_NAME} />
              </div>
            </div>
            <div className="nav_links pointer-cursor">
              {userNavBarList.map((item, index) => {
                if (!checkPagePermission(hotelUserRole, item.name)) {
                  return null;
                }
                else {
                  return (
                    <a
                      className={checkIfActive(index)}
                      key={index}
                      // style={{ paddingTop: 24, paddingBottom: 24 }}
                      onClick={() => handleClick(item.path)}
                    >
                      {item.name}
                    </a>
                  );
                }
              })}
            </div>
          </div>
          {/* 
          <div className="div-right user-select border-red">
            <div className="box-data box-border-grey div-right" onClick={handleLogout}>
              Logout
              <span className="margin-left-5px fa fa-sign-out"></span>
            </div>
          </div> */}
          <div className="div-right nav-hotel-logo-right ">

            {hotelLogo && hotelLogo.length > 0 && hotelLogo != 'undefined' && hotelLogo != 'null' &&
              <div className="div-right pt-10 ml-30px">
                <img className="small" style={{ height: 55 }} src={hotelLogo} alt={APP_NAME} />
              </div>}

            {/* <div className='flex-col'>
              <div className="div-right margin-top10" >
                <div className="box-data margin-right-0px box-border-grey div-right pointer" onClick={handleLogout}>
                  Logout
                  <span className="margin-left-5px fa fa-sign-out"></span>
                </div>
              </div>
              <div className="div-right mt-5px" >
                {(adminUserName != "undefined" && adminUserName != "" && adminUserName != null) ? (<p className='small-text-12'>Logged in as {adminUserName}</p>) : null}</div>
            </div> */}

            <div className=' flex-col '>
              <div className="div-right user-select pointer-cursor" ref={menuRef}>
                <div
                  className=" div-right margin-right-m1px"
                  onClick={handleclk}
                >
                  {(adminUserName != "undefined" && adminUserName != "") ? (<button className='border-none mb-1 primary btnbg-bkup text-white'>{adminUserName}</button>) : null}
                  <div className={click ? "NavDropdown" : 'hide'} >
                    <ul>
                      {/* {checkPagePermission(adminUserRole, LBL_ASSOCIATES) && <li onClick={() => handleClick('/v1/admin-associates')}>{LBL_ASSOCIATES}</li>}
                      {checkPagePermission(adminUserRole, LBL_SETTINGS) && <li onClick={() => handleClick('/v1/settings')}>{LBL_SETTINGS}</li>}
                      {checkPagePermission(adminUserRole, LBL_TERMS) && <li onClick={() => handleClick('/v1/terms')}>{LBL_TERMS}</li>} */}
                      <li onClick={handleLogout}>Logout</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
export default Navbar;
