import { ITableHeader } from '../models/genericInterfaces';
import { LBL_MAID, LBL_MAIDS, LBL_TIPS, LBL_COL_HEAD_DOCS_VERIFIED } from '../config/common_labels'; 

export const hotelListFields: ITableHeader[] = [
  {
    title: 'hotelName',
    type: 'text',
    display_title: 'Hotel Name',
    sortBy: false,
    sortDirection: 'asc',
    clickable: true,
    customClass: 'none pointer-cursor',
  },
  {
    title: 'hotelCode',
    type: 'text',
    display_title: 'Hotel Code',
    sortBy: false,
    sortDirection: 'asc',
    clickable: false,
    customClass: '',
  },
  {
    title: 'address',
    type: 'text',
    display_title: 'Address',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'creationDate',
    type: 'date',
    display_title: 'Added Date',
    sortBy: false,
    sortDirection: 'asc',
    dateTimeFormat: 'MM/DD/YYYY',
    customClass: '',
  },
  {
    title: 'payout',
    type: 'text',
    display_title: 'Payment to',
    sortBy: false,
    sortDirection: 'asc',
    sortTitle:'',
    customClass: '',
  },
  {
    title: 'isActive',
    type: 'status',
    display_title: 'Active',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: LBL_MAIDS.toLowerCase(),
    type: 'url',
    display_title: LBL_MAIDS,
    sortBy: false,
    sortDirection: 'asc',
    customClass: 'url-field',
  },
];

export const hotelPaymentsFelds: ITableHeader[] = [
  {
    title: 'id',
    type: 'text',
    display_title: 'Sl. No.',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'name',
    type: 'text',
    display_title: 'Hotel Name',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'totalTip',
    type: 'money',
    display_title: 'Tip Received',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'hotelCommission',
    type: 'text',
    display_title: 'Commission (%)',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'totalDistributed',
    type: 'money',
    display_title: 'Amount Distributed',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'totalCommission',
    type: 'money',
    display_title: 'Commission Collected',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'transactions',
    type: 'transaction_count',
    display_title: 'No of Transactions',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: LBL_MAID.toLowerCase()+'-payments', 
    type: 'url',
    display_title: LBL_MAID+' Payments',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'guest-payments',
    type: 'url',
    display_title: LBL_TIPS,
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
];

export const guestPaymentsFields: ITableHeader[] = [
  {
    title: 'hotelName',
    type: 'text',
    display_title: 'Hotel',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'transactionDate',
    type: 'date',
    display_title: 'Tr. Date & Time',
    sortBy: false,
    sortDirection: 'asc',
    dateTimeFormat: 'MM/DD/YYYY HH:mm:ss',
    customClass: '',
  },
  // {
  //   title: 'customerName',
  //   type: 'text',
  //   display_title: 'From',
  //   sortBy: false,
  //   sortDirection: 'asc',
  //   customClass: '',
  // },
  {
    title: 'gateway',
    type: 'text',
    display_title: 'Payment Method',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'transactionId',
    type: 'text',
    display_title: 'Transaction ID',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'amount',
    type: 'money',
    display_title: 'Amount',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'refundStatus',
    type: 'text',
    display_title: 'Refund Status',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'roomNo',
    type: 'text',
    display_title: 'Room No',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'Refund',
    type: 'action_menu',
    display_title: 'Action',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
];

export const maidListFields: ITableHeader[] = [
  {
    title: 'mobileNumber',
    type: 'text',
    display_title: 'Mobile Number',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'firstName',
    type: 'text',
    display_title: 'First Name',
    sortBy: true,
    sortDirection: 'asc',
    sortTitle:'first_name',
    customClass: 'none',
    clickable: true,
  },
  {
    title: 'middleName',
    type: 'text',
    display_title: 'Middle Name',
    sortBy: false,
    sortDirection: 'asc',
    customClass: 'none',
    clickable: true,
  },
  {
    title: 'lastName',
    type: 'text',
    display_title: 'Last Name',
    sortBy: true,
    sortDirection: 'asc',
    sortTitle:'last_name',
    customClass: 'none',
    clickable: true,
  },
  {
    title: 'address',
    type: 'text',
    display_title: 'Address',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'joiningDate',
    type: 'date',
    display_title: 'Joining Date',
    sortBy: true,
    sortDirection: 'asc',
    sortTitle:'date_joined',
    dateTimeFormat: 'MM/DD/YYYY',
    customClass: '',
  },
  {
    title: 'totalHours',
    type: 'worked_hours',
    display_title: 'Total Hours',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'hotelEarnings',
    type: 'money_by_money',
    display_title: 'Total Earnings',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
    clickable: false,
  },
  {
    title: 'documentVerified',
    type: 'status',
    display_title: LBL_COL_HEAD_DOCS_VERIFIED,
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  }, 
  {
    title: 'paymentMethod',
    type: 'text',
    display_title: 'Payment Method',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'deleteMaid',
    type: 'delete_button',
    display_title: 'Action',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
];

export const maidPaymentsFields: ITableHeader[] = [
  {
    title: 'transactionDateTime',
    type: 'date',
    display_title: 'Tr. Date & Time',
    sortBy: false,
    sortDirection: 'asc',
    dateTimeFormat: 'MM/DD/YYYY HH:mm',
    customClass: '',
  },
  {
    title: 'mobileNumber',
    type: 'text',
    display_title: 'Mobile Number',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'name',
    type: 'text',
    display_title: 'Name',
    sortBy: false,
    sortDirection: 'asc',
    customClass: 'none',
    clickable: false,
  },
  {
    title: 'totalHours',
    type: 'worked_hours',
    display_title: 'Total Hours',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'totalEarnings',
    type: 'money',
    display_title: 'Total Earnings',
    sortBy: false,
    sortDirection: 'asc',
    customClass: 'none',
    clickable: false,
  },
  {
    title: 'paymentStatus',
    type: 'status',
    display_title: 'Payment Status',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'transactionId',
    type: 'text',
    display_title: 'Transaction ID',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
];

export const maidPaymentsFieldsTransDate: ITableHeader[] = [
  {
    title: 'transactionDateTime',
    type: 'date',
    display_title: 'Tr. Date & Time',
    sortBy: false,
    sortDirection: 'asc',
    dateTimeFormat: 'MM/DD/YYYY HH:mm',
    customClass: '',
  },
  {
    title: 'mobileNumber',
    type: 'text',
    display_title: 'Mobile Number',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'name',
    type: 'text',
    display_title: 'Name',
    sortBy: false,
    sortDirection: 'asc',
    customClass: 'none',
    clickable: false,
  },
  {
    title: 'totalHours',
    type: 'worked_hours',
    display_title: 'Total Hours',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'totalEarnings',
    type: 'money',
    display_title: 'Total Earnings',
    sortBy: false,
    sortDirection: 'asc',
    customClass: 'none',
    clickable: false,
  },
  {
    title: 'paymentStatus',
    type: 'status',
    display_title: 'Payment Status',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: 'transactionId',
    type: 'text',
    display_title: 'Transaction ID',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
];

export const PaymentsLogFields: ITableHeader[] = [
  {
    title: 'startOn',
    type: 'date',
    display_title: 'Start Time',
    sortBy: false,
    sortDirection: 'asc',
    dateTimeFormat: 'MM/DD/YYYY HH:mm',
    customClass: '',
  },
  {
    title: 'endOn',
    type: 'date',
    display_title: 'End Time',
    sortBy: false,
    sortDirection: 'asc',
    dateTimeFormat: 'MM/DD/YYYY HH:mm',
    customClass: '',
  },
  {
    title: 'payPeriodEndDate',
    type: 'date',
    display_title: 'Pay Period End Date',
    sortBy: false,
    sortDirection: 'asc',
    dateTimeFormat: 'MM/DD/YYYY',
    customClass: '',
  },
  {
    title: 'hotels',
    type: 'text',
    display_title: ' Hotels',
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  {
    title: LBL_MAIDS.toLowerCase(),
    type: 'text',
    display_title: LBL_MAIDS,
    sortBy: false,
    sortDirection: 'asc',
    customClass: '',
  },
  
  ];

  // id: number;
  // fullName: string; 
  // userName: string;
  // mobileNumber: string;
  // position: string;
  // lastLogin: string;
  // isActive: boolean;
  // isPrimaryUser: boolean;
  // emailVerified: boolean;
  // firstName: string;
  // lastName: string;

  export const hotelAssociatesListFields: ITableHeader[] = [
    {
      title: 'fullName',
      type: 'text',
      display_title: 'Name',
      sortBy: false,
      sortDirection: 'asc',
      clickable: true,
      customClass: 'none pointer-cursor',
    },
    {
      title: 'username',
      type: 'text',
      display_title: 'Username',
      sortBy: false,
      sortDirection: 'asc',
      customClass: '',
    },
    {
      title: 'mobile',
      type: 'text',
      display_title: 'Mobile',
      sortBy: false,
      sortDirection: 'asc',
      customClass: '',
    },
    {
      title: 'position',
      type: 'text',
      display_title: 'Position',
      sortBy: false,
      sortDirection: 'asc',
      customClass: '',
    },
    {
      title: 'role',
      type: 'number',
      display_title: 'Role',
      sortBy: false,
      sortDirection: 'asc',
      customClass: '',
    },
    {
      title: 'lastLogin',
      type: 'date',
      display_title: 'Last Login',
      sortBy: false,
      sortDirection: 'asc',
      dateTimeFormat: 'MM/DD/YYYY HH:mm',
      customClass: '',
    },
    {
      title: 'isActive',
      type: 'status',
      display_title: 'Active',
      sortBy: false,
      sortDirection: 'asc',
      customClass: '',
    },
    {
      title: 'deleteuser',
      type: 'delete_user_button',
      display_title: 'Action',
      sortBy: false,
      sortDirection: 'asc',
      customClass: '',
    },
  ];

  
  

  // [{"id":162,"first_name":"","last_name":"","full_name":" ","username":"victor+31@aimabms.com","email":"","mobile_number":null,"position":null,"last_login":"2022-10-10T09:06:55.525717Z","is_active":true,"is_primary_user":false,"email_verified":false}]