import React from 'react';
import ReactTooltip from 'react-tooltip';

interface IProps {
  value: string;
  toolTipData: string;
  status: boolean;
  boldFont: boolean;
  customClass: string;
}

const StatusField: React.FC<IProps> = ({ value, status, boldFont, customClass, toolTipData }) => {
  const statusClass = status ? 'success' : 'danger';
  const boldClass = boldFont ? 'font-bold' : '';
  let valToDisplay = value;
  if (value == 'true') {
    valToDisplay = 'YES';
  } else if (value == 'false') {
    valToDisplay = 'NO';
  } else if (value.toLowerCase() == 'undefined') {
    valToDisplay = '';
  }
  return (
    <>
      <span
        data-tip={toolTipData}
        className={`${statusClass} ${boldClass} ${customClass}`}
        style={{ textTransform: 'capitalize' }}
      >
        {valToDisplay !== undefined && valToDisplay.toString()}
      </span>
      <ReactTooltip />
    </>
  );
};

export default StatusField;
