import React, { useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import dayjs from 'dayjs';
var QRCode = require('qrcode.react');
import copy from 'copy-to-clipboard';
import { Store } from 'react-notifications-component';
import { useForm, SubmitHandler, SubmitErrorHandler, useFieldArray } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReactLoading from 'react-loading';
import AddPageHeader from '../../components/AddPageHeader';
import ImageBox from '../../components/ImageBox';
import {
  IHotelForm,
  IHotelId,
  IHotelIdUsername,
  IHotelInviteByEmail,
} from '../../models/hotelsInterfaces';
import {
  fetchHotel,
  updateHotel,
  createHotelNew,
  activateHotel,
  deactivateHotel,
  fetchConfig,
  getHotelQrCode,
  getHotelsettings,
  modifyHotelUsername,
  inviteByEmail,
} from '../../services/hotelService';
import { getPdfFile, getPnGFile } from '../../services/apiService';
import { getHotelUserHotelIds } from '../../services/localStorage';
import { getHotelUserRole } from '../../services/localStorage';

import {
  selectHotelFormDataSlice,
  setMobile,
  setAlternative,
  setHotelForm,
  setNewHotelCreated,
} from './hotelFormDataSlice';

import {
  notification_shown_time_success,
  server_url_var,
  tip_server_url_var,
  TIP_AMT_MAX_VALUE,
  SMS_MAX_LENGTH,
  TIP_FACT_HEADER_MAX_LENGTH,
  TIP_FACT_BODY_MAX_LENGTH,
  APP_NAME,
  ROOM_NUMBER_VALUE,
  ROOM_NAME_VALUE,
  USER_ROLE_HOTEL_MANAGER,
  USER_ROLE_HOTEL_USER,
  HOTEL_CODE_MAX_LENGTH,
  PAYOUT_HOTEL,
  PAYOUT_STAFF,
  DEFAULT_CURRENCY,
  DEFAULT_COUNTRY,
  COMPANY_DOC_TYPE_IRS_LETTER_147C_ID,
  COMPANY_DOC_TYPE_IRS_SS4_LETTER_ID,
  COMPANY_DOC_TYPE_IRS_SS4_LETTER,
  COMPANY_DOC_TYPE_IRS_LETTER_147C,
} from '../../config/env';
import {
  checkPagePermission,
  checkActionPermission,
  PERM_EDIT_HOTEL_DETAILS,
} from '../../config/permissions';

import { selectHotelSettingsFormDataSlice } from '../Settings/AddSettings';
import { Box, Modal } from '@mui/material';
import {
  MSG_ENTER_VALID_EMAIL,
  MSG_INVALID_AMOUNT,
  MSG_INVALID_PHONE_NUMBER,
  MSG_LINK_COPIED,
  MSG_STAFF_ID_PROOF_TYPE_ERROR,
} from '../../config/messages';
import {
  LBL_MAID,
  LBL_MAIDS,
  LBL_ROOM_NUMBER,
  LBL_ROOM_NAME,
  LBL_PAYOUT_HOTEL,
  LBL_PAYOUT_STAFF,
  LBL_INVITE_STAFF_EMAIL,
} from '../../config/common_labels';

export const HotelForm = (props: any) => {
  const history = useHistory();
  const [title, setTitle] = useState<string>('Add New Hotel');
  const [subTitle, setSubTitle] = useState<string>('Hotel List/Add New Hotel');
  const [formdata, setFormdata] = useState<any>({});
  const [selectedImage, setSelectedImage] = useState<BinaryType | null | undefined>(null);
  const { hotelForm, loading, newHotelCreated, defaultCommission } =
    useAppSelector(selectHotelFormDataSlice);
  const { hotelSettingsForm, newSettingsAdded } = useAppSelector(selectHotelSettingsFormDataSlice);
  const [selectedMobile, setSelectedMobile] = useState('');
  const [selectedAlternative, setSelectedAlternative] = useState('');
  const [hotelUsername, setHotelUsername] = useState('');
  const [inviteModalShow, setInviteModalShow] = useState(false);
  const [inviteFirstName, setInviteFirstName] = useState('');
  const [inviteLastName, setInviteLastName] = useState('');
  const [inviteEmail, setInviteEmail] = useState('');

  const [payout, setPayout] = useState(true); //FOR BANK ACC DISPLAY
  const [verificationDocumentImg, setVerificationDocumentImg] = useState('');
  const [checkDisable, setCheckDisable] = useState(true);

  const dispatch = useAppDispatch();

  let hotelId = 0;
  if (props.match.params.id != undefined) {
    hotelId = props.match.params.id;
  } else {
    let hotelIds = getHotelUserHotelIds();
    let arrHotelIds = hotelIds?.split(',');
    hotelId = arrHotelIds && arrHotelIds[0] ? parseInt(arrHotelIds[0], 10) : 0;
  }

  let hotelUserRole = getHotelUserRole();
  let editHotelPermission: boolean = checkActionPermission(hotelUserRole, PERM_EDIT_HOTEL_DETAILS);
  let required = 'This field is required.';

  useEffect(() => {
    dispatch(setHotelForm({} as IHotelForm));
    // reset form with user data
    // if (props.match.params.id != undefined) {
    //   dispatch(fetchHotel(props.match.params.id));
    // } else {
    //   dispatch(fetchConfig());
    //   dispatch(getHotelsettings(1));
    // }

    if (hotelId) {
      dispatch(fetchHotel(hotelId));
    }

    return function cleanup() {
      dispatch(setHotelForm({} as IHotelForm));
      reset(hotelForm);
    };
  }, [dispatch]);

  useEffect(() => {
    setFormdata(hotelSettingsForm);
    setValue(`welcomeMessage`, hotelSettingsForm?.welcomeMessage);

    setValue(`tip_1`, hotelSettingsForm?.tip_1);
    setValue(`tip_2`, hotelSettingsForm?.tip_2);
    setValue(`tip_3`, hotelSettingsForm?.tip_3);
    setValue(`tip_4`, hotelSettingsForm?.tip_4);
    setValue(`tip_5`, hotelSettingsForm?.tip_5);

    setValue(`tippingFactHeader_1`, hotelSettingsForm?.tippingFactHeader_1);
    setValue(`tippingFactHeader_2`, hotelSettingsForm?.tippingFactHeader_2);
    setValue(`tippingFactHeader_3`, hotelSettingsForm?.tippingFactHeader_3);
    setValue(`tippingFactHeader_4`, hotelSettingsForm?.tippingFactHeader_4);
    setValue(`tippingFactHeader_5`, hotelSettingsForm?.tippingFactHeader_5);

    setValue(`tippingFactBody_1`, hotelSettingsForm?.tippingFactBody_1);
    setValue(`tippingFactBody_2`, hotelSettingsForm?.tippingFactBody_2);
    setValue(`tippingFactBody_3`, hotelSettingsForm?.tippingFactBody_3);
    setValue(`tippingFactBody_4`, hotelSettingsForm?.tippingFactBody_4);
    setValue(`tippingFactBody_5`, hotelSettingsForm?.tippingFactBody_5);
  }, [hotelSettingsForm]);

  // This effect is used to handle a redirect when a hotel is created successfully
  React.useEffect(() => {
    if (newHotelCreated === true) {
      setTitle(hotelForm?.hotelName);
      setSelectedMobile(hotelForm?.mobileNumber);
      setSelectedAlternative(hotelForm?.alternativeMobileNumber);
      setSubTitle(`Hotel List/${hotelForm?.hotelName}`);
      history.replace('/v1/edit-hotel/' + hotelForm.id + '/');
      return function cleanup() {
        dispatch(setNewHotelCreated(false));
      };
    }
  }, [newHotelCreated]);

  const {
    setValue,
    getValues,
    clearErrors,
    setError,
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IHotelForm>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  });

  useEffect(() => {
    if (fields.length === 0) {
      incContacts();
    }
  }, [fields]);

  const checkMandatory = (e: any, fieldName: string) => {
    if (fieldName === 'payout') {
      if (e.target.value != PAYOUT_HOTEL) {
        setPayout(false);
      } else {
        setPayout(true);
      }
    }
  };

  const fileValidation = (e: any) => {    
    setVerificationDocumentImg(e);
};

  

  useEffect(() => {
    // setValue(`welcomeMessage`,hotelSettingsForm?.welcomeMessage);
    if (hotelId != undefined) {
      console.log(hotelForm);

      reset(hotelForm);
      setSelectedImage(hotelForm?.image);
      setCheckDisable(hotelForm?.documentVerified ? hotelForm?.documentVerified : false);
      setVerificationDocumentImg(hotelForm?.verificationDocument?`data:image/png;base64,${hotelForm?.verificationDocument}`:"");
      if (hotelForm == undefined || hotelForm.contacts == undefined || hotelForm.contacts == null) {
        append({}, { shouldFocus: false });
      }
      setTitle(hotelForm?.hotelName);
      setSelectedMobile(hotelForm?.mobileNumber);
      setSelectedAlternative(hotelForm?.alternativeMobileNumber);
      setSubTitle(`Hotel List/${hotelForm?.hotelName}`);
    } else {
      setValue(`paymentCommission`, hotelForm?.paymentCommission);
    }

    setValue(`payout`, hotelForm?.payout ? hotelForm?.payout : PAYOUT_STAFF);

    if (hotelForm?.payout && hotelForm?.payout != PAYOUT_HOTEL) {
      setPayout(false);
    } else {
      setPayout(true);
    }

    setValue(`accountHolderName`, hotelForm?.bankDetails?.accountHolderName);
    setValue(
      `country`,
      hotelForm?.bankDetails?.country ? hotelForm?.bankDetails?.country : DEFAULT_COUNTRY
    );
    setValue(`accountNumber`, hotelForm?.bankDetails?.accountNumber);
    setValue(`bankName`, hotelForm?.bankDetails?.bankName);
    setValue(`routingNumber`, hotelForm?.bankDetails?.routingNumber);
    setValue(`companyName`, hotelForm?.companyName);
    setValue(`companyTaxId`, hotelForm?.companyTaxId); 
    setValue(`documentTypeId`, (!hotelForm?.documentTypeId || hotelForm?.documentTypeId==null) ? 0 : hotelForm?.documentTypeId); 
  }, [hotelForm]);

  const onErrorSubmit: SubmitErrorHandler<IHotelForm> = (data) => {
    if (selectedMobile === '') {
      setError('mobileNumber', {
        type: 'manual',
        message: MSG_INVALID_PHONE_NUMBER,
      });
    }
    // if (selectedAlternative === '') {
    //   setError('alternativeMobileNumber', {
    //     type: 'manual',
    //     message: MSG_INVALID_PHONE_NUMBER,
    //   });
    // }
  };

  const updateFieldMobile = (value: string) => {
    setSelectedMobile(value);
    clearErrors('mobileNumber');
  };

  const updateFieldAltMobile = (value: string) => {
    setSelectedAlternative(value);
    clearErrors('alternativeMobileNumber');
  };

  const updateIndexMobileField = (index: number, value: string) => {
    setValue(`contacts.${index}.mobileNumber`, value);
  };

  const getIndexMobilenumber = (index: number): string => {
    if (
      hotelForm == undefined ||
      hotelForm.contacts == undefined ||
      hotelForm.contacts == null ||
      hotelForm.contacts.length == 0
    ) {
      return '';
    }
    if (hotelForm.contacts.length >= index) {
      if (hotelForm.contacts[index] == undefined) {
        return '';
      }
      return hotelForm.contacts[index].mobileNumber;
    }
    return '';
  };

  const onSubmit: SubmitHandler<IHotelForm> = (data) => {
    console.log('hotels:', data);

    const idsToRemove: number[] = [];
    if (selectedMobile.trim() === '') {
      setError('mobileNumber', {
        type: 'manual',
        message: MSG_INVALID_PHONE_NUMBER,
      });
      return;
    }
    // if (selectedAlternative === '') {
    //   setError('alternativeMobileNumber', {
    //     type: 'manual',
    //     message: MSG_INVALID_PHONE_NUMBER,
    //   });
    //   return;
    // }
    data.verificationDocument = verificationDocumentImg;
    data.currency = hotelForm?.currency ? hotelForm.currency : DEFAULT_CURRENCY;
    data.tipIncludeProcessFee = true;
    data.image = selectedImage;
    data.mobileNumber = selectedMobile;
    data.alternativeMobileNumber = selectedAlternative;
    if (data?.contacts != undefined) {
      data.contacts.map((contact, i) => {
        const isEmpty = !Object.values(contact).some((x) => x !== null && x !== '');
        if (isEmpty) {
          idsToRemove.push(i);
        }
      });
      idsToRemove.map((id) => {
        if (data?.contacts != undefined) {
          data.contacts = data.contacts.filter((contact, index) => {
            return index != id;
          });
        }
      });
    }
    if (hotelId === undefined) {
      dispatch(createHotelNew(data));
    } else {
      data.id = hotelId;
      dispatch(updateHotel(data));
    }
  };

  const onSaveSubmitted = (): void => {
    if (loading != 'loading') {
      handleSubmit(onSubmit, onErrorSubmit)();
    }
  };

  const onCancelSubmitted = (): void => {
    history.push('/v1/hotels');
  };

  const incContacts = (): void => {
    append(
      {
        firstName: '',
        lastName: '',
        position: '',
        addressLineOne: '',
        addressLineTwo: '',
        zip: '',
        mobileNumber: '',
        city: '',
        state: '',
        email: '',
      },
      { shouldFocus: false }
    );
  };

  const decContacts = (index: number): void => {
    remove(index);
  };

  const onImageUploadSubmitted = (e: any): void => {
    setSelectedImage(e);
  };

  const onActivateHotel = (): void => {
    var req: IHotelId = {
      id: hotelId,
    };
    dispatch(activateHotel(req));
  };

  const onDeactivateHotel = (): void => {
    var req: IHotelId = {
      id: hotelId,
    };
    dispatch(deactivateHotel(req));
  };

  const copyLink = (): void => {
    copy(server_url_var + '/register/' + hotelForm.hotelCodeId + '/');
    Store.addNotification({
      title: APP_NAME,
      message: MSG_LINK_COPIED,
      type: 'success',
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: notification_shown_time_success,
        onScreen: true,
        pauseOnHover: true,
      },
    });
  };

  const handleInviteByEmail = (): void => {
    let pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (
      pattern.test(inviteEmail) &&
      hotelForm.hotelCode.trim().length > 0 &&
      inviteFirstName &&
      inviteFirstName.trim().length > 0 &&
      inviteLastName &&
      inviteLastName.trim().length > 0 &&
      inviteEmail &&
      inviteEmail.trim().length > 0
    ) {
      var req: IHotelInviteByEmail = {
        hotelCode: hotelForm.hotelCodeId,
        firstName: inviteFirstName,
        lastName: inviteLastName,
        email: inviteEmail,
      };
      dispatch(inviteByEmail(req));
      setInviteModalShow(false);
    }
  };

  const OnSetInviteModalShow = () => {
    setInviteModalShow(true);
    setInviteFirstName('');
    setInviteLastName('');
    setInviteEmail('');
  };

  const copyQRCodeLink = (): void => {
    copy(tip_server_url_var + '/tip/' + hotelForm.hotelCodeId);
    Store.addNotification({
      title: APP_NAME,
      message: MSG_LINK_COPIED,
      type: 'success',
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: notification_shown_time_success,
        onScreen: true,
        pauseOnHover: true,
      },
    });
  };

  const handleEmailValidation = (e: any) => {
    let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (pattern.test(hotelUsername)) {
      setValue('username', hotelUsername);
      var req: IHotelIdUsername = {
        hotelId: JSON.stringify(hotelForm.id),
        username: hotelUsername,
      };
      dispatch(modifyHotelUsername(req));
    } else {
      console.log('invalid mail');
    }
  };
  const downloadQrCode = (url: string, file_name: string): void => {
    getPnGFile(url, file_name);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 850,
    bgcolor: 'background.paper',
    border: '1px solid #d0d0d0',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className="content_container">
      {loading != 'loading' && (
        <AddPageHeader
          contentTitle={title}
          subTitle={''}
          onSave={onSaveSubmitted}
          onCancel={onCancelSubmitted}
          appLogo={false}
          smallHeader={false}
          editHotelPermission={editHotelPermission}
        />
      )}
      {loading === 'loading' && (
        <div className="table-loader">
          <ReactLoading className="loader-center" color={'#2f427c'} type={'bars'} width={'3vw'} />
        </div>
      )}
      {loading != 'loading' && (
        <div className="box pb-5">
          <form autoComplete="off">
            <div className="row top mb-10px">
              <div className="col-1">&nbsp;</div>
              <div className="col-10">
                {/* MIDDLE-SECTION-START */}
                <div className="row">
                  <div className="row">
                    <div className="col-6 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">
                          Hotel Name<span className="danger">*</span>
                        </label>
                      </div>
                      <div className="mb-10px">
                        <input
                          autoFocus
                          {...register('hotelName', { required })}
                          type="text"
                          name="hotelName"
                          className={errors.hotelName ? 'input-error w-100px' : 'w-100px'}
                          placeholder="Enter hotel name here"
                        />
                      </div>
                      {errors.hotelName && (
                        <span className="danger">{errors.hotelName.message}</span>
                      )}
                    </div>

                    <div className="col-6 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">
                          Hotel Code<span className="danger">*</span>
                        </label>
                      </div>
                      <div className="mb-10px">
                        <input
                          {...register('hotelCode')}
                          type="text"
                          name="hotelCode"
                          className="w-100px disabled"
                          placeholder="Code"
                          disabled
                        />
                      </div>
                      {errors.hotelCode && (
                        <span className="danger">{errors.hotelCode.message}</span>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">
                          Address Line 1<span className="danger">*</span>
                        </label>
                      </div>
                      <div className="mb-10px">
                        <input
                          {...register('addressLineOne', { required })}
                          type="text"
                          name="addressLineOne"
                          className={errors.addressLineOne ? 'input-error w-100px' : 'w-100px'}
                          placeholder="Enter address line 1 here"
                        />
                      </div>
                      {errors.addressLineOne && (
                        <span className="danger">{errors.addressLineOne.message}</span>
                      )}
                    </div>

                    <div className="col-6 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Address Line 2</label>
                      </div>
                      <div className="mb-10px w-100px">
                        <input
                          {...register('addressLineTwo')}
                          type="text"
                          name="addressLineTwo"
                          className="w-100px"
                          placeholder="Enter address line 2 here"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">
                          City<span className="danger">*</span>
                        </label>
                      </div>
                      <div className="mb-10px">
                        <input
                          {...register('city', { required })}
                          type="text"
                          name="city"
                          className={errors.city ? 'input-error w-100px' : 'w-100px'}
                          placeholder="Enter City here"
                        />
                      </div>
                      {errors.city && <span className="danger">{errors.city.message}</span>}
                    </div>
                    <div className="col-4 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">
                          State<span className="danger">*</span>
                        </label>
                      </div>
                      <div className="mb-10px">
                        <input
                          {...register('state', { required })}
                          type="text"
                          name="state"
                          className={errors.state ? 'input-error w-100px' : 'w-100px'}
                          placeholder="Enter State here"
                        />
                      </div>
                      {errors.state && <span className="danger">{errors.state.message}</span>}
                    </div>
                    <div className="col-4 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">
                          Zip<span className="danger">*</span>
                        </label>
                      </div>
                      <div className="mb-10px">
                        <input
                          {...register('zip', { required })}
                          type="text"
                          name="zip"
                          className="w-100px"
                          placeholder="Enter Zip here"
                        />
                      </div>
                      {errors.zip && <span className="danger">{errors.zip.message}</span>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">
                          Phone Number<span className="danger">*</span>
                        </label>
                      </div>
                      <div className="mb-10px">
                        <PhoneInput
                          inputProps={{
                            name: 'mobileNumberPhoneInput',
                            required: true,
                            autoFocus: false,
                          }}
                          containerClass="w-100px"
                          inputClass="tel-input"
                          onlyCountries={['us']}
                          country={'us'}
                          value={selectedMobile}
                          onChange={(code) => updateFieldMobile(code)}
                        />
                      </div>
                      {errors.mobileNumber && (
                        <span className="danger">{errors.mobileNumber.message}</span>
                      )}
                    </div>
                    <div className="col-6 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Alternate Phone Number</label>
                      </div>
                      <div className="mb-10px">
                        <PhoneInput
                          inputProps={{
                            name: 'alternativeMobileNumberPhoneInput',
                            required: false,
                            autoFocus: false,
                          }}
                          inputClass="tel-input"
                          onlyCountries={['us']}
                          country={'us'}
                          value={selectedAlternative}
                          onChange={(code) => updateFieldAltMobile(code)}
                        />
                        {/* {errors.alternativeMobileNumber && (
                        <span className="danger">{errors.alternativeMobileNumber.message}</span>
                      )} */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">
                          Communication Email<span className="danger">*</span>
                        </label>
                      </div>
                      <div className="mb-10px">
                        <input
                          {...register('communicationEmail', {
                            required,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: MSG_ENTER_VALID_EMAIL,
                            },
                          })}
                          type="email"
                          name="communicationEmail"
                          className={errors.communicationEmail ? 'input-error w-100px' : 'w-100px'}
                          placeholder="Enter email"
                        />
                      </div>
                      {errors.communicationEmail && (
                        <span className="danger">{errors.communicationEmail.message}</span>
                      )}
                    </div>
                    <div className="col-3 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Room Label</label>
                      </div>
                      <div className="mb-10px">
                        <select
                          id="roomLabel"
                          className="w-100px"
                          defaultValue={ROOM_NUMBER_VALUE}
                          {...register('roomLabel')}
                        >
                          <option value={ROOM_NUMBER_VALUE}>{LBL_ROOM_NUMBER}</option>
                          <option value={ROOM_NAME_VALUE}>{LBL_ROOM_NAME} </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-3 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Room Label Mandatory</label>
                      </div>
                      <div className="mb-10px">
                        <select
                          id="roomLabelMandatory"
                          className="w-100px"
                          defaultValue={'false'}
                          {...register('roomLabelMandatory')}
                        >
                          <option value={'false'}>No</option>
                          <option value={'true'}>Yes </option>
                        </select>
                        {/* <label className="div-left min-width-125 mb-15px"> <input 
                           type="checkbox"
                           checked={isActive}
                           onChange={e => changeStatus(e.target.checked)}
                           className="mandatoryCheckbox div-left"
        
                          /> Mandatory</label> */}
                      </div>
                    </div>

                    <div className="col-6 form-group hide">
                      <div className="mb-10px">
                        <label className="div-label font-bold">
                          Ask for additional processing fee
                        </label>
                      </div>
                      <div className="mb-10px">
                        {/* <select name="tipIncludeProcessFee" id="tipIncludeProcessFee" className='w-100px' value={active} defaultValue={"Yes"} onChange={(e: any) => setActive(e.target.value)}>  */}
                        <select
                          id="tipIncludeProcessFee"
                          className="w-100px"
                          defaultValue={'false'}
                          {...register('tipIncludeProcessFee')}
                        >
                          <option value={'false'}>No</option>
                          <option value={'true'}>Yes </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {/* MIDDLE-SECTION-END */}

                {/* QR-CODE-TIP-STARTING */}
                <div className="row ">
                  {hotelId != undefined && (
                    <>
                      <div className="col-6">
                        <div className="row top">
                          <div className="col-10 form-group mt-20px mb-10px ">
                            <div className="downoad-qr">
                              <div className="mb-10px">
                                <label className="div-label font-bold"> QR Code for Tipping</label>
                              </div>
                            </div>
                            <div className="col-10 mt-10 mb-10px">
                              <button
                                type="button"
                                className="primary color-light-grey mt-10px ml-9px copy-link-button-width"
                                onClick={copyQRCodeLink}
                              >
                                Click to copy the QR code link
                              </button>
                            </div>
                            <div className="justify-space-between">
                              <div>
                                <div className="">
                                  <div className="image-box-qrcode">
                                    {hotelForm != null &&
                                      hotelForm != undefined &&
                                      hotelForm.hotelCodeId != undefined && (
                                        <img
                                          className="logo_maid_hotel_form"
                                          src={hotelForm.hotelPngQrUrl}
                                          alt={APP_NAME}
                                        />
                                      )}
                                  </div>
                                </div>
                                <div className="mb-10px ">
                                  {hotelForm != null &&
                                    hotelForm != undefined &&
                                    hotelForm.hotelQrUrl != undefined && (
                                      <>
                                        <a
                                          href={hotelForm.hotelQrUrl}
                                          download={`${hotelForm.hotelCodeId}.pdf`}
                                        >
                                          <button
                                            type="button"
                                            className="primary color-light-grey mt-10px ml-40px"
                                          >
                                            Download QR code
                                          </button>
                                        </a>
                                      </>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6 vertical-top top">
                        <div className="row top">
                          <div className="mb-10px  mt-20px ">
                            <label className="div-label font-bold">{LBL_MAID} invite link</label>
                          </div>
                          <div className="col-10 mt-10 form-group mb-10px">
                            <button
                              type="button"
                              className="primary color-light-grey mt-10px copy-link-button-width"
                              onClick={copyLink}
                            >
                              Click to copy the link for <br></br> inviting {LBL_MAIDS} to this
                              hotel.
                            </button>
                          </div>

                          {/* <div className="mb-10px  mt-30px ">
                            <label className="div-label font-bold">{LBL_INVITE_STAFF_EMAIL}</label>
                          </div> */}
                          <div className="col-10 mt-30px form-group mb-10px">
                            <button
                              type="button"
                              className="primary color-light-grey mt-10px copy-link-button-width"
                              onClick={OnSetInviteModalShow}
                            >
                              Invite a {LBL_MAID.toLowerCase()} through email.
                            </button>
                          </div>

                          <Modal
                            open={inviteModalShow}
                            onClose={() => setInviteModalShow(false)}
                            aria-labelledby={LBL_INVITE_STAFF_EMAIL}
                            aria-describedby=""
                          >
                            <Box sx={style}>
                              <div className="padding-35px flex-col border div-center">
                                <div className="page-heading">
                                  <h4>{LBL_INVITE_STAFF_EMAIL}</h4>
                                </div>

                                <div className="row col-12 margin-bottom20px">
                                  <div className="row margin-top5">
                                    <div className="col-6">
                                      First Name
                                      <input
                                        type="text"
                                        value={inviteFirstName}
                                        name={'firstName'}
                                        className={
                                          inviteFirstName && inviteFirstName.trim().length > 0
                                            ? 'w-100px'
                                            : 'w-100px input-error'
                                        }
                                        placeholder="Enter First Name here"
                                        onChange={(e) => setInviteFirstName(e.target.value)}
                                        required
                                      />
                                    </div>
                                    <div className="col-6">
                                      Last Name
                                      <input
                                        type="text"
                                        name={'lastName'}
                                        className={
                                          inviteLastName && inviteLastName.trim().length > 0
                                            ? 'w-100px'
                                            : 'w-100px input-error'
                                        }
                                        placeholder="Enter Last Name here"
                                        onChange={(e) => setInviteLastName(e.target.value)}
                                        required
                                        value={inviteLastName}
                                      />
                                    </div>
                                  </div>
                                  <div className="row margin-top20">
                                    <div className="col-6">
                                      Email
                                      <input
                                        type="text"
                                        name={'username'}
                                        className={
                                          inviteEmail && inviteEmail.trim().length > 0
                                            ? 'w-100px'
                                            : 'w-100px  input-error'
                                        }
                                        placeholder="Enter Username here"
                                        onChange={(e) => setInviteEmail(e.target.value)}
                                        required
                                        value={inviteEmail}
                                      />
                                    </div>
                                  </div>
                                  <div className="row margin-top30">
                                    <div className="col-7 div-center">&nbsp;</div>
                                    <div className="col-5 div-right">
                                      <button
                                        type="button"
                                        className="primary div-right margin-left-10px"
                                        onClick={handleInviteByEmail}
                                      >
                                        {' '}
                                        Sent
                                      </button>
                                      <button
                                        type="button"
                                        className="div-right primary margin-right-15px"
                                        onClick={() => setInviteModalShow(false)}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Box>
                          </Modal>

                          <div className="justify-right mt-10 min-height-255">&nbsp;</div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-6 top hidden">
                    <div className="mt-2px"></div>
                    {hotelId != undefined && (
                      <div className="justify-right mt-10 min-height-330">&nbsp;</div>
                    )}
                  </div>
                </div>

                {/* QR-CODE-TIP-ENDING */}
                <ImageBox
                  contentTitle={'Hotel Logo'}
                  id={'HotelLogo'}
                  label={''}
                  url={''}
                  defaultImage={selectedImage}
                  onUpload={onImageUploadSubmitted}
                  hideDeleteOption={true}
                />

                <div className="row">

                  <div className="col-4 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold mt-3">Send Payout to</label>
                    </div>
                    <div className="mb-10px">
                      <select
                        id="payout"
                        className={'w-100px'}
                        defaultValue={PAYOUT_STAFF}
                        {...register('payout', {
                          required: 'Required.',
                          onChange: (e) => checkMandatory(e, 'payout'),
                        })}
                      >
                        {/* <option value={PAYOUT_TOVA}>{LBL_PAYOUT_TOVA}</option> */}
                        <option value={PAYOUT_STAFF}>{LBL_PAYOUT_STAFF}</option>
                        <option value={PAYOUT_HOTEL}>{LBL_PAYOUT_HOTEL} </option>
                      </select>
                    </div>
                  </div>
                  {payout && (
                    <>
                      <div className="col-4 form-group">
                        <div className="mb-10px">
                          <label className="div-label font-bold mt-3">Legal Company Name <span className="danger">*</span></label>
                        </div>
                        <div className="mb-10px">
                          <input
                            {...register('companyName', { required: 'Required.' })}
                            name="companyName"
                            type="text"
                            className={'w-100px'}
                          ></input>
                          {errors.companyName && (
                            <span className="danger">{errors.companyName.message}</span>
                          )}
                        </div>
                      </div>

                      <div className="col-4 form-group">
                        <div className="mb-10px">
                          <label className="div-label font-bold mt-3">Company Tax ID </label>
                        </div>
                        <div className="mb-10px">
                          <input
                            {...register('companyTaxId')}
                            name="companyTaxId"
                            type="text"
                            className={'w-100px'}
                          ></input>
                          {errors.companyTaxId && (
                            <span className="danger">{errors.companyTaxId.message}</span>
                          )}
                        </div>
                      </div>
                      </>)}

                </div>

                {payout && (
                  <>
                    <div className="row mt-20px">
                      <div className="col-3 ">
                        <div className="">
                          <h5 className="div-label font-bold">
                            <div className="heading">Bank Details</div>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="row p-10">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Bank Account Payee Name <span className="danger">*</span></th>
                            <th>Country <span className="danger">*</span></th>
                            <th>Bank Account number <span className="danger">*</span></th>
                            <th>Bank Name <span className="danger">*</span></th>
                            <th>ABA/ ACH Routing Code <span className="danger">*</span></th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              <input
                                {...register('accountHolderName', { required: 'Required.' })}
                                name="accountHolderName"
                                type="text"
                                className={'w-100px'}
                              ></input>
                              {errors.accountHolderName && (
                                <span className="danger">{errors.accountHolderName.message}</span>
                              )}
                            </td>
                            <td>
                              <input
                                {...register('country', { required: 'Required.' })}
                                name="country"
                                type="text"
                                value={DEFAULT_COUNTRY}
                                className={'w-100px'}
                                readOnly
                              ></input>
                              {errors.country && (
                                <span className="danger">{errors.country.message}</span>
                              )}
                            </td>
                            <td>
                              <input
                                {...register('accountNumber', { required: 'Required.' })}
                                name="accountNumber"
                                type="text"
                                className={'w-100px'}
                                maxLength={35}
                              ></input>
                              {errors.accountNumber && (
                                <span className="danger">{errors.accountNumber.message}</span>
                              )}
                            </td>
                            <td>
                              <input
                                {...register('bankName', { required: 'Required.' })}
                                name="bankName"
                                type="text"
                                className={'w-100px'}
                              ></input>
                              {errors.bankName && (
                                <span className="danger">{errors.bankName.message}</span>
                              )}
                            </td>
                            <td>
                              <input
                                {...register('routingNumber', { required: 'Required.' })}
                                name="routingNumber"
                                type="text"
                                className={'w-100px'}
                                maxLength={9}
                              ></input>
                              {errors.routingNumber && (
                                <span className="danger">{errors.routingNumber.message}</span>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="row mb-4 gap-3">
                      <div className="col-4">
                        <div className="mt-20px">
                          <h5 className="div-label font-bold">
                            <div className="heading">ID Proof Document Type</div>
                          </h5>
                        </div>
                        <div className="mb-10px ">
                          <select
                            id="documentTypeId"
                            className={'w-100px'}
                            {...register('documentTypeId', {
                              required: 'Required.',
                            })}
                            disabled={checkDisable}
                          >
                            <option value={0}>Select</option>
                            <option value={COMPANY_DOC_TYPE_IRS_LETTER_147C_ID}>
                              {COMPANY_DOC_TYPE_IRS_LETTER_147C}
                            </option>
                            <option value={COMPANY_DOC_TYPE_IRS_SS4_LETTER_ID}>
                              {COMPANY_DOC_TYPE_IRS_SS4_LETTER}{' '}
                            </option>
                          </select>
                        </div>
                        {errors.documentTypeId && (
                          <span className="danger">{errors.documentTypeId.message}</span>
                        )}
                      </div>
                      {props.match.params.id != undefined && <div className="col-4">
                        <div className="mt-10px mb-10px">
                          <h5 className="div-label font-bold">
                            <div className="heading">Document Verified</div>
                          </h5>
                        </div>
                        <div className="mb-10px font-16">
                          {hotelForm?.documentVerified ? 'Yes' : 'No'} 
                        </div>
                      </div>}
                    </div>
                    <div className="row mb-4 ">
                        <div className="mt-20px">
                          <h5 className="div-label font-bold">
                            <div className="heading">Upload Document Image</div>
                          </h5>
                          <p className='div-label-small mb-0'>{MSG_STAFF_ID_PROOF_TYPE_ERROR}</p>

                        </div>
                        <div className="mb-10px">
                          <ImageBox
                            label={''}
                            url={''}
                            id={"verificationDocumentImg"}
                            defaultImage={verificationDocumentImg}
                            onUpload={fileValidation}
                            checkDisable={checkDisable} 
                            hideDeleteOption={true} 
                          />
                        </div>
                        {errors.verificationDocument && (
                          <span className="danger">{errors.verificationDocument.message}</span>
                        ) }
                      </div>
                  </>
                )}

                {/* ADDITIONAL-CONTACTS-START */}
                <div className="row mt-20px">
                  <div className="col-3 ">
                    <div className="">
                      <h5 className="div-label font-bold">
                        <div className="heading">Additional Contacts</div>
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="p-10">
                  <div className="row ">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Position</th>
                          <th>Address Line 1</th>
                          <th>Address Line 2</th>
                          <th>City</th>
                          <th>State</th>
                          <th>Zip</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      {fields.map((field, index) => (
                        <>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  key={field.id}
                                  {...register(`contacts.${index}.firstName` as const)}
                                  type="text"
                                  name={`contacts.${index}.firstName`}
                                  className="w-100px"
                                  placeholder="Enter First Name here"
                                />
                              </td>
                              <td>
                                <input
                                  key={field.id}
                                  {...register(`contacts.${index}.lastName` as const)}
                                  type="text"
                                  name={`contacts.${index}.lastName`}
                                  className="w-100px"
                                  placeholder="Enter Last Name here"
                                />
                              </td>
                              <td>
                                <input
                                  key={field.id}
                                  {...register(`contacts.${index}.position` as const)}
                                  type="text"
                                  name={`contacts.${index}.position`}
                                  className="w-100px"
                                  placeholder="Enter Position here"
                                />
                              </td>
                              <td>
                                <input
                                  key={field.id}
                                  {...register(`contacts.${index}.addressLineOne` as const)}
                                  type="text"
                                  name={`contacts.${index}.addressLineOne`}
                                  className="w-100px"
                                  placeholder="Enter address line 1 here"
                                />
                              </td>
                              <td>
                                <input
                                  key={field.id}
                                  {...register(`contacts.${index}.addressLineTwo` as const)}
                                  type="text"
                                  name={`contacts.${index}.addressLineTwo`}
                                  className="w-100px"
                                  placeholder="Enter address line 2 here"
                                />
                              </td>
                              <td>
                                <input
                                  key={field.id}
                                  {...register(`contacts.${index}.city` as const)}
                                  type="text"
                                  name={`contacts.${index}.city`}
                                  className="w-100px"
                                  placeholder="Enter City here"
                                />
                              </td>
                              <td>
                                <input
                                  key={field.id}
                                  {...register(`contacts.${index}.state` as const)}
                                  type="text"
                                  name={`contacts.${index}.state`}
                                  className="w-100px"
                                  placeholder="Enter State here"
                                />
                              </td>
                              <td>
                                <input
                                  key={field.id}
                                  {...register(`contacts.${index}.zip` as const)}
                                  type="text"
                                  name={`contacts.${index}.zip`}
                                  className="w-100px"
                                  placeholder="Enter Zip here"
                                />
                              </td>
                              <td>
                                <PhoneInput
                                  inputProps={{
                                    name: `contacts.${index}.mobileNumberPhoneField`,
                                    required: true,
                                    autoFocus: false,
                                  }}
                                  inputClass="tel-input"
                                  onlyCountries={['us']}
                                  country={'us'}
                                  value={getIndexMobilenumber(index)}
                                  onChange={(code) => updateIndexMobileField(index, code)}
                                />
                                <input
                                  key={field.id}
                                  {...register(`contacts.${index}.mobileNumber` as const)}
                                  type="text"
                                  name={`contacts.${index}.mobileNumber`}
                                  className="w-100px hide"
                                  placeholder="Enter Mobile here"
                                />
                              </td>
                              <td>
                                <input
                                  key={field.id}
                                  {...register(`contacts.${index}.email` as const, {
                                    pattern: {
                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                      message: MSG_ENTER_VALID_EMAIL,
                                    },
                                  })}
                                  type="text"
                                  name={`contacts.${index}.email`}
                                  className="w-100px"
                                  placeholder="Enter Email here"
                                />
                                {errors.contacts?.[index]?.email && (
                                  <span className="danger">Enter a valid e-mail address</span>
                                )}
                              </td>
                              <td>
                                <span className="btn-flex-row">
                                  {editHotelPermission && (
                                    <>
                                      <button
                                        type="button"
                                        className=""
                                        onClick={() => decContacts(index)}
                                      >
                                        <span className="font-bold danger"> x </span>
                                      </button>
                                      {index + 1 === fields.length && index < 3 && (
                                        <button
                                          type="button"
                                          className="primary ml-10px"
                                          onClick={incContacts}
                                        >
                                          <span className="font-bold"> + </span>
                                        </button>
                                      )}
                                    </>
                                  )}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </>
                      ))}
                    </table>
                  </div>
                </div>

                {/* ADDITIONAL-CONTACTS-END */}

                <div className="col-12 form-group mt-20px">
                  <div className="mb-10px">
                    <label className="div-label font-bold">{LBL_MAID} Welcome Message (SMS)</label>
                  </div>
                  <div className="mb-10px">
                    <input
                      {...register('welcomeMessage', {
                        required: 'Required.',
                        maxLength: SMS_MAX_LENGTH,
                      })}
                      type="text"
                      name="welcomeMessage"
                      style={{ width: '100%', maxWidth: 1000 }}
                      className="maxw1000"
                      placeholder="Welcome Message"
                      maxLength={SMS_MAX_LENGTH}
                    />
                  </div>
                  {errors.welcomeMessage && (
                    <p className="danger">{errors.welcomeMessage.message}</p>
                  )}
                </div>

                <div className="col-12 form-group mt-20px">
                  <div className="mb-10px">
                    <label className="div-label font-bold">Google review link</label>
                  </div>
                  <div className="mb-10px">
                    <input
                      {...register('googleReviewLink')}
                      type="text"
                      name="googleReviewLink"
                      style={{ width: '100%', maxWidth: 1000 }}
                      className="maxw1000"
                      placeholder="Google review link"
                    />
                  </div>
                </div>

                {/* TIP-START */}
                <div className="row mt-20px">
                  <div className="row mt-20px">
                    <div className="col-2 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tip 1 </label>
                      </div>
                      <div className="mb-10px">
                        <div className="input-icons">
                          <i className="fa fa-dollar hotel_icon"></i>
                          <input
                            {...register('tip_1', {
                              required: 'Required.',
                              min: 0,
                              max: TIP_AMT_MAX_VALUE,
                              pattern: {
                                value: /^[.0-9]*$/,
                                message: MSG_INVALID_AMOUNT,
                              },
                            })}
                            type="number"
                            name="tip_1"
                            className="w-100px tipinp"
                            placeholder="Tip 1"
                            min={0}
                            max={TIP_AMT_MAX_VALUE}
                          />
                        </div>
                        {errors.tip_1 && <span className="danger">{errors.tip_1.message}</span>}
                      </div>
                    </div>

                    <div className="col-2 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tip 2 </label>
                      </div>
                      <div className="mb-10px">
                        <div className="input-icons">
                          <i className="fa fa-dollar hotel_icon"></i>
                          <input
                            {...register('tip_2', {
                              required: 'Required.',
                              min: 0,
                              max: TIP_AMT_MAX_VALUE,
                              pattern: {
                                value: /^[.0-9]*$/,
                                message: MSG_INVALID_AMOUNT,
                              },
                            })}
                            type="number"
                            name="tip_2"
                            className="w-100px tipinp"
                            placeholder="Tip 2"
                            min={0}
                            max={TIP_AMT_MAX_VALUE}
                          />
                        </div>
                        {errors.tip_2 && <span className="danger">{errors.tip_2.message}</span>}
                      </div>
                    </div>

                    <div className="col-2 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tip 3 </label>
                      </div>
                      <div className="mb-10px">
                        <div className="input-icons">
                          <i className="fa fa-dollar hotel_icon"></i>
                          <input
                            {...register('tip_3', {
                              required: 'Required.',
                              min: 0,
                              max: TIP_AMT_MAX_VALUE,
                              pattern: {
                                value: /^[.0-9]*$/,
                                message: MSG_INVALID_AMOUNT,
                              },
                            })}
                            type="number"
                            name="tip_3"
                            className="w-100px tipinp"
                            placeholder="Tip 3"
                            min={0}
                            max={TIP_AMT_MAX_VALUE}
                          />
                        </div>
                        {errors.tip_3 && <span className="danger">{errors.tip_3.message}</span>}
                      </div>
                    </div>

                    <div className="col-2 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tip 4 </label>
                      </div>
                      <div className="mb-10px">
                        <div className="input-icons">
                          <i className="fa fa-dollar hotel_icon"></i>
                          <input
                            {...register('tip_4', {
                              required: 'Required.',
                              min: 0,
                              max: TIP_AMT_MAX_VALUE,
                              pattern: {
                                value: /^[.0-9]*$/,
                                message: MSG_INVALID_AMOUNT,
                              },
                            })}
                            type="number"
                            name="tip_4"
                            className="w-100px tipinp"
                            placeholder="Tip 4"
                            min={0}
                            max={TIP_AMT_MAX_VALUE}
                          />
                        </div>
                        {errors.tip_4 && <span className="danger">{errors.tip_4.message}</span>}
                      </div>
                    </div>

                    <div className="col-2 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tip 5 </label>
                      </div>
                      <div className="mb-10px">
                        <div className="input-icons">
                          <i className="fa fa-dollar hotel_icon"></i>
                          <input
                            {...register('tip_5', {
                              required: 'Required.',
                              min: 0,
                              max: TIP_AMT_MAX_VALUE,
                              pattern: {
                                value: /^[.0-9]*$/,
                                message: MSG_INVALID_AMOUNT,
                              },
                            })}
                            type="number"
                            name="tip_5"
                            className="w-100px tipinp"
                            placeholder="Tip 5"
                            min={0}
                            max={TIP_AMT_MAX_VALUE}
                          />
                        </div>
                        {errors.tip_5 && <span className="danger">{errors.tip_5.message}</span>}
                      </div>
                    </div>
                  </div>

                  <div className="mb-10px mt-20px">
                    <h5 className="div-label font-bold">
                      <div className="heading">Tipping Facts</div>
                    </h5>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-6 form-group mb-43">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tipping Fact Header 1</label>
                      </div>
                      <div className="mb-10px">
                        <input
                          {...register('tippingFactHeader_1', {
                            required: 'Required.',
                            maxLength: TIP_FACT_HEADER_MAX_LENGTH,
                          })}
                          type="text"
                          name="tippingFactHeader_1"
                          className="w-100px"
                          placeholder="Tipping Fact Header 1"
                          maxLength={TIP_FACT_HEADER_MAX_LENGTH}
                        />
                      </div>
                      {errors.tippingFactHeader_1 && (
                        <span className="danger">{errors.tippingFactHeader_1.message}</span>
                      )}
                    </div>

                    <div className="col-6 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tipping Fact body 1</label>
                      </div>
                      <div className="mb-10px">
                        <textarea
                          {...register('tippingFactBody_1', {
                            required: 'Required.',
                            maxLength: TIP_FACT_BODY_MAX_LENGTH,
                          })}
                          rows={2}
                          name="tippingFactBody_1"
                          className="w-100px"
                          placeholder="Tipping Fact Header 1"
                          maxLength={TIP_FACT_BODY_MAX_LENGTH}
                        />
                      </div>
                      {errors.tippingFactBody_1 && (
                        <span className="danger">{errors.tippingFactBody_1.message}</span>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 form-group  mb-43">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tipping Fact Header 2</label>
                      </div>
                      <div className="mb-10px">
                        <input
                          {...register('tippingFactHeader_2', {
                            required: 'Required.',
                            maxLength: TIP_FACT_HEADER_MAX_LENGTH,
                          })}
                          type="text"
                          name="tippingFactHeader_2"
                          className="w-100px"
                          placeholder="Tipping Fact Header 2"
                          maxLength={TIP_FACT_HEADER_MAX_LENGTH}
                        />
                      </div>
                      {errors.tippingFactHeader_2 && (
                        <span className="danger">{errors.tippingFactHeader_2.message}</span>
                      )}
                    </div>
                    <div className="col-6 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tipping Fact body 2</label>
                      </div>
                      <div className="mb-10px">
                        <textarea
                          {...register('tippingFactBody_2', {
                            required: 'Required.',
                            maxLength: TIP_FACT_BODY_MAX_LENGTH,
                          })}
                          rows={2}
                          name="tippingFactBody_2"
                          className="w-100px"
                          placeholder="Tipping Fact body  2"
                          maxLength={TIP_FACT_BODY_MAX_LENGTH}
                        />
                      </div>
                      {errors.tippingFactBody_2 && (
                        <span className="danger">{errors.tippingFactBody_2.message}</span>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 form-group  mb-43">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tipping Fact Header 3</label>
                      </div>
                      <div className="mb-10px">
                        <input
                          {...register('tippingFactHeader_3', {
                            required: 'Required.',
                            maxLength: TIP_FACT_HEADER_MAX_LENGTH,
                          })}
                          type="text"
                          name="tippingFactHeader_3"
                          className="w-100px"
                          placeholder="Tipping Fact Header 3"
                          maxLength={TIP_FACT_HEADER_MAX_LENGTH}
                        />
                      </div>
                      {errors.tippingFactHeader_3 && (
                        <span className="danger">{errors.tippingFactHeader_3.message}</span>
                      )}
                    </div>
                    <div className="col-6 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tipping Fact body 3</label>
                      </div>
                      <div className="mb-10px">
                        <textarea
                          {...register('tippingFactBody_3', {
                            required: 'Required.',
                            maxLength: TIP_FACT_BODY_MAX_LENGTH,
                          })}
                          rows={2}
                          name="tippingFactBody_3"
                          className="w-100px"
                          placeholder="Tipping Fact body 3"
                          maxLength={TIP_FACT_BODY_MAX_LENGTH}
                        />
                      </div>
                      {errors.tippingFactBody_3 && (
                        <span className="danger">{errors.tippingFactBody_3.message}</span>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 form-group  mb-43">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tipping Fact Header 4</label>
                      </div>
                      <div className="mb-10px">
                        <input
                          {...register('tippingFactHeader_4', {
                            required: 'Required.',
                            maxLength: TIP_FACT_HEADER_MAX_LENGTH,
                          })}
                          type="text"
                          name="tippingFactHeader_4"
                          className="w-100px"
                          placeholder="Tipping Fact Header 4"
                          maxLength={TIP_FACT_HEADER_MAX_LENGTH}
                        />
                      </div>
                      {errors.tippingFactHeader_4 && (
                        <span className="danger">{errors.tippingFactHeader_4.message}</span>
                      )}
                    </div>
                    <div className="col-6 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tipping Fact body 4</label>
                      </div>
                      <div className="mb-10px">
                        <textarea
                          {...register('tippingFactBody_4', {
                            required: 'Required.',
                            maxLength: TIP_FACT_BODY_MAX_LENGTH,
                          })}
                          rows={2}
                          name="tippingFactBody_4"
                          className="w-100px"
                          placeholder="Tipping Fact body 4"
                          maxLength={TIP_FACT_BODY_MAX_LENGTH}
                        />
                      </div>
                      {errors.tippingFactBody_4 && (
                        <span className="danger">{errors.tippingFactBody_4.message}</span>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 form-group mb-43">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tipping Fact Header 5</label>
                      </div>
                      <div className="mb-10px">
                        <input
                          {...register('tippingFactHeader_5', {
                            required: 'Required.',
                            maxLength: TIP_FACT_HEADER_MAX_LENGTH,
                          })}
                          type="text"
                          name="tippingFactHeader_5"
                          className="w-100px"
                          placeholder="Tipping Fact Header 5"
                          maxLength={TIP_FACT_HEADER_MAX_LENGTH}
                        />
                      </div>
                      {errors.tippingFactHeader_5 && (
                        <span className="danger">{errors.tippingFactHeader_5.message}</span>
                      )}
                    </div>
                    <div className="col-6 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tipping Fact body 5</label>
                      </div>
                      <div className="mb-10px">
                        <textarea
                          {...register('tippingFactBody_5', {
                            required: 'Required.',
                            maxLength: TIP_FACT_BODY_MAX_LENGTH,
                          })}
                          rows={2}
                          name="tippingFactBody_5"
                          className="w-100px"
                          placeholder="Tipping Fact body 5"
                          maxLength={TIP_FACT_BODY_MAX_LENGTH}
                        />
                      </div>
                      {errors.tippingFactBody_5 && (
                        <span className="danger">{errors.tippingFactBody_5.message}</span>
                      )}
                    </div>
                  </div>

                  <div className="row mt-3 mb-10px">
                    <div className="col-6 form-group ">
                      <label>
                        {hotelForm != null &&
                          hotelForm != undefined &&
                          hotelForm.creationDate != undefined && (
                            <>
                              <div className="div-label font-bold mt-3">
                                Created on {dayjs(hotelForm.creationDate).format('MMMM DD, YYYY')}
                              </div>
                            </>
                          )}
                      </label>
                    </div>
                    <div className="col-6 form-group ">
                      <div className="div-right">
                        {editHotelPermission && (
                          <button
                            type="button"
                            className="primary div-right"
                            onClick={onSaveSubmitted}
                          >
                            Save Details
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* <div className="mt-3">
                {hotelForm != null &&
                  hotelForm != undefined &&
                  hotelForm.isActive != undefined &&
                  hotelForm.isActive == 'YES' && (
                    <button
                      type="button"
                      className="bg-danger color-light-grey"
                      onClick={onDeactivateHotel}
                    >
                      Deactivate Hotel
                    </button>
                  )}
                {hotelForm != null &&
                  hotelForm != undefined &&
                  hotelForm.isActive != undefined &&
                  hotelForm.isActive == 'NO' && (
                    <button
                      type="button"
                      className="bg-success color-light-grey"
                      onClick={onActivateHotel}
                    >
                      Activate Hotel
                    </button>
                  )}
              </div> */}
                </div>

                {/* TIP-END */}
              </div>
              <div className="col-1">&nbsp;</div>

              {/* <div className="col-8"> */}

              {/* <div className="row">
                  <div className="col-6 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Hotel Name<span className='danger'>*</span></label>
                    </div>
                    <div className="mb-10px">
                      <input
                        autoFocus
                        {...register('hotelName', { required })}
                        type="text"
                        name="hotelName"
                        className={errors.hotelName ? "input-error w-100px" : "w-100px"}
                        placeholder="Enter hotel name here"
                      />
                    </div>
                    {errors.hotelName && (
                      <span className="danger">{errors.hotelName.message}</span>
                    )}
                  </div>

                  <div className="col-6 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Hotel Code<span className='danger'>*</span></label>
                    </div>
                    <div className="mb-10px">
                      <input
                        {...register('hotelCode')}
                        type="text"
                        name="hotelCode"
                        className="w-100px disabled"
                        placeholder="Code"
                        disabled
                      />
                    </div>
                    {errors.hotelCode && (
                      <span className="danger">{errors.hotelCode.message}</span>
                    )}
                  </div>
                </div> */}

              {/* </div> */}

              {/* <div className="col-4 v-top">
                <ImageBox
                  contentTitle={'Hotel Logo'}
                  label={''}
                  url={''}
                  defaultImage={selectedImage}
                  onUpload={onImageUploadSubmitted}
                  hideDeleteOption={true}
                />
                <div className='small text-center min-width-400'>(Square image preferred)</div>
              </div> */}
            </div>

            {/* <div className="row mt-20px">
              <div className="col-3 ">
                <div className="">
                  <h5 className="div-label font-bold">
                    <div className="heading">Additional Contacts</div>
                  </h5>
                </div>
              </div>

            </div>
            <div className="row">
              <table className="table">
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Position</th>
                    <th>Address Line 1</th>
                    <th>Address Line 2</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Zip</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                {fields.map((field, index) => (
                  <>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            key={field.id}
                            {...register(`contacts.${index}.firstName` as const)}
                            type="text"
                            name={`contacts.${index}.firstName`}
                            className="w-100px"
                            placeholder="Enter First Name here"
                          />
                        </td>
                        <td>
                          <input
                            key={field.id}
                            {...register(`contacts.${index}.lastName` as const)}
                            type="text"
                            name={`contacts.${index}.lastName`}
                            className="w-100px"
                            placeholder="Enter Last Name here"
                          />
                        </td>
                        <td>
                          <input
                            key={field.id}
                            {...register(`contacts.${index}.position` as const)}
                            type="text"
                            name={`contacts.${index}.position`}
                            className="w-100px"
                            placeholder="Enter Position here"
                          />
                        </td>
                        <td>
                          <input
                            key={field.id}
                            {...register(`contacts.${index}.addressLineOne` as const)}
                            type="text"
                            name={`contacts.${index}.addressLineOne`}
                            className="w-100px"
                            placeholder="Enter address line 1 here"
                          />
                        </td>
                        <td>
                          <input
                            key={field.id}
                            {...register(`contacts.${index}.addressLineTwo` as const)}
                            type="text"
                            name={`contacts.${index}.addressLineTwo`}
                            className="w-100px"
                            placeholder="Enter address line 2 here"
                          />
                        </td>
                        <td>
                          <input
                            key={field.id}
                            {...register(`contacts.${index}.city` as const)}
                            type="text"
                            name={`contacts.${index}.city`}
                            className="w-100px"
                            placeholder="Enter City here"
                          />
                        </td>
                        <td>
                          <input
                            key={field.id}
                            {...register(`contacts.${index}.state` as const)}
                            type="text"
                            name={`contacts.${index}.state`}
                            className="w-100px"
                            placeholder="Enter State here"
                          />
                        </td>
                        <td>
                          <input
                            key={field.id}
                            {...register(`contacts.${index}.zip` as const)}
                            type="text"
                            name={`contacts.${index}.zip`}
                            className="w-100px"
                            placeholder="Enter Zip here"
                          />
                        </td>
                        <td>
                          <PhoneInput
                            inputProps={{
                              name: `contacts.${index}.mobileNumberPhoneField`,
                              required: true,
                              autoFocus: false,
                            }}
                            inputClass="tel-input"
                            onlyCountries={['us']}
                            country={'us'}
                            value={getIndexMobilenumber(index)}
                            onChange={(code) => updateIndexMobileField(index, code)}
                          />
                          <input
                            key={field.id}
                            {...register(`contacts.${index}.mobileNumber` as const)}
                            type="text"
                            name={`contacts.${index}.mobileNumber`}
                            className="w-100px hide"
                            placeholder="Enter Mobile here"
                          />
                        </td>
                        <td>
                          <input
                            key={field.id}
                            {...register(`contacts.${index}.email` as const, {
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: MSG_ENTER_VALID_EMAIL,
                              },
                            })}
                            type="text"
                            name={`contacts.${index}.email`}
                            className="w-100px"
                            placeholder="Enter Email here"
                          />
                          {errors.contacts?.[index]?.email && (
                            <span className="danger">Enter a valid e-mail address</span>
                          )}
                        </td>
                        <td>
                          <span className="btn-flex-row">
                            {editHotelPermission && <><button type="button" className="" onClick={() => decContacts(index)}>
                              <span className="font-bold danger"> x </span>
                            </button>
                              {index + 1 === fields.length && index < 3 && (
                                <button
                                  type="button"
                                  className="primary ml-10px"
                                  onClick={incContacts}
                                >
                                  <span className="font-bold"> + </span>
                                </button>
                              )}</>}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </>
                ))}
              </table>
            </div> */}
          </form>
        </div>
      )}
    </div>
  );
};
