import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IMaidDetails, IMaidApprove, IMaidEdit } from '../../models/maidsInterfaces';
import { adminUpdateMaid, fetchMaidDetails, getImageId } from '../../services/maidService';
import { approveMaid } from '../../services/maidService';
import { Store } from 'react-notifications-component';
import { notification_shown_time_success } from '../../config/env';

export interface DataState {
  maidEditData: IMaidEdit;
  adminEditloading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
  isMaidEditShown: boolean;
}

const initialState: DataState = {
  maidEditData: {} as IMaidEdit,
  adminEditloading: 'idle',
  error: '',
  isMaidEditShown: false,
};

export const maidAdminEditDataSlice = createSlice({
  name: 'maidEditData',
  initialState,
  reducers: {
    setMaidProperties: (state, action: PayloadAction<IMaidDetails>) => {
      state.maidEditData.alternativeMobileNumber = action.payload.alternativeMobileNumber;
      state.maidEditData.mobileNumber = action.payload.mobileNumber;
      state.maidEditData.firstName = action.payload.firstName;
      state.maidEditData.lastName = action.payload.lastName;
      state.maidEditData.middleName = action.payload.middleName;
      state.maidEditData.email = action.payload.email;
      state.maidEditData.id = action.payload.id;
    },
    openMaidEditState: (state) => {
      state.isMaidEditShown = true;
    },
    closeMaidEdit: (state) => {
      state.isMaidEditShown = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminUpdateMaid.pending, (state) => {
      state.adminEditloading = 'loading';
    });
    builder.addCase(adminUpdateMaid.fulfilled, (state, action) => {
      state.adminEditloading = 'succeeded';
      state.isMaidEditShown = false;
    });
    builder.addCase(adminUpdateMaid.rejected, (state, { payload }: any) => {
      state.adminEditloading = 'error';
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setMaidProperties, openMaidEditState, closeMaidEdit } =
  maidAdminEditDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMaidAdminEditSlice = (state: RootState) => state.maidDetailsAdminEdit;

export const maidAdminEditReducer = maidAdminEditDataSlice.reducer;
