import { configureStore } from '@reduxjs/toolkit';
import { authenticationReducer } from './pages/LoginPage/authenticationSlice';
import { alertReducer } from './components/Alert/alertSlice';
import { hotelListSearchReducer } from './pages/HotelList/hotelListSearchSlice';
import { hotelListPaginationReducer } from './pages/HotelList/hotelListPaginationSlice';
import { hotelListDataReducer } from './pages/HotelList/hotelListDataSlice';
// hotel payments
import { hotelPaymentsSearchReducer } from './pages/HotelPayments/hotelPaymentsSearchSlice';
import { hotelPaymentsPaginationReducer } from './pages/HotelPayments/hotelPaymentsPaginationSlice';
import { hotelPaymentsDataReducer } from './pages/HotelPayments/hotelPaymentsDataSlice';
import { hotelPaymentsFilterDataReducer } from './pages/HotelPayments/hotelPaymentsFilterDataSlice';
//guest
import { guestPaymentsSearchReducer } from './pages/GuestPayments/guestPaymentsSearchSlice';
import { guestPaymentsPaginationReducer } from './pages/GuestPayments/guestPaymentsPaginationSlice';
import { guestPaymentsDataReducer } from './pages/GuestPayments/guestPaymentsDataSlice';
import { guestPaymentsHotelFilterReducer } from './pages/GuestPayments/guestPaymentHotelFilterSlice';
import { selectGuestRefundReducer } from './pages/GuestPayments/guestRefundSlice';
// staff list
import { maidListSearchReducer } from './pages/MaidList/maidListSearchSlice';
import { maidListPaginationReducer } from './pages/MaidList/maidListPaginationSlice';
import { maidListDataReducer } from './pages/MaidList/maidListDataSlice';
import { maidAdminEditReducer } from './pages/MaidList/maidAdminEditSlice';
// form data
import { hotelFormDataReducer } from './pages/HotelForm/hotelFormDataSlice';
// hotel fetch name and id
import { maidListHotelFilterReducer } from './pages/MaidList/maidListHotelFilterSlice';

import { maidPaymentsSearchReducer } from './pages/MaidPayments/maidPaymentsSearchSlice';
import { maidPaymentsPaginationReducer } from './pages/MaidPayments/maidPaymentsPaginationSlice';
import { maidPaymentsDataReducer } from './pages/MaidPayments/maidPaymentsDataSlice';
import { maidPaymentsHotelFilterReducer } from './pages/MaidPayments/maidPaymentHotelFilterSlice';
import { maidPaymentsTimesheetReducer } from './pages/MaidPayments/maidTimesheetSlice';
import { maidPaymentsTransactionReducer } from './pages/MaidPayments/maidTransactionsSlice';
import { maidPaymentsFilterDataReducer } from './pages/MaidPayments/maidPaymentsFilterDataSlice';
// staff details
import { maidDataReducer } from './pages/MaidList/maidDetailsSlice';


import { hotelSettingsFormDataReducer } from './pages/Settings/AddSettings';

// log
import { paymentListDataReducer } from './pages/PaymentLog/paymentListDataSlice';

import { resetPasswordReducer } from './pages/HotelResetPassword/hotelResetPasswordSlice';

// HOTEL ASSOCIATES USERS 
import { hotelAssociatesListDataReducer } from './pages/HotelAssociates/hotelAssociatesListDataSlice';
import { hotelAssociatesListPaginationReducer } from './pages/HotelAssociates/hotelAssociatesListPaginationSlice';
import { hotelAssociatesAddEditReducer } from './pages/HotelAssociates/hotelAssociatesAddEditSlice';
import { hotelAssociatesHotelFilterReducer } from './pages/HotelAssociates/hotelAssociatesHotelFilterSlice';
import { multiSelectSliceReducer } from './components/MultiSelect/MultiSelectSlice';

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    alert: alertReducer,
    hotelListSearch: hotelListSearchReducer,
    hotelListPagination: hotelListPaginationReducer,
    hotelListData: hotelListDataReducer,
    // hotel payments
    hotelPaymentsSearch: hotelPaymentsSearchReducer,
    hotelPaymentsPagination: hotelPaymentsPaginationReducer,
    hotelPaymentsData: hotelPaymentsDataReducer,
    hotelPaymentsFilterData: hotelPaymentsFilterDataReducer,
    // guest payments
    guestPaymentsSearch: guestPaymentsSearchReducer,
    guestPaymentsPagination: guestPaymentsPaginationReducer,
    guestPaymentsData: guestPaymentsDataReducer,
    guestPaymentsHotelFilter: guestPaymentsHotelFilterReducer,
    guestRefund: selectGuestRefundReducer,
    // multiselect hotellist
    multiSelectHotelList: multiSelectSliceReducer,
    // staff list
    maidListSearch: maidListSearchReducer,
    maidListPagination: maidListPaginationReducer,
    maidListData: maidListDataReducer,
    maidDetailsAdminEdit: maidAdminEditReducer,
    // hotel-form
    hotelFormData: hotelFormDataReducer,

    hotelSettingsFromData: hotelSettingsFormDataReducer,

    maidListHotelFilter: maidListHotelFilterReducer,

    // maidPayments
    maidPaymentsSearch: maidPaymentsSearchReducer,
    maidPaymentsPagination: maidPaymentsPaginationReducer,
    maidPaymentsData: maidPaymentsDataReducer,
    maidPaymentsHotelFilter: maidPaymentsHotelFilterReducer,
    maidPaymentsTimesheet: maidPaymentsTimesheetReducer,
    maidPaymentsTransaction: maidPaymentsTransactionReducer,
    maidPaymentsFilterData: maidPaymentsFilterDataReducer,
    //maid-details
    maidDetailsData: maidDataReducer,

    //log
    paymentListData: paymentListDataReducer,

    //RESET PASSWORD 
    resetPassword: resetPasswordReducer,

    // HOTEL ASSOCIATES USERS
    hotelAssociatesListData: hotelAssociatesListDataReducer,
    hotelAssociatesListPaginationData: hotelAssociatesListPaginationReducer,
    hotelAssociatesAddEditData: hotelAssociatesAddEditReducer,
    hotelAssociatesHotelFilter: hotelAssociatesHotelFilterReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
