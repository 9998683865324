import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IHotelNameId } from '../../models/hotelsInterfaces';
import { fetchHotelsNameId } from '../../services/maidPaymentsService';
import { IAutocompleteSelectOptionType } from '../../models/maidsInterfaces';
import { getUpdateURLParameter } from '../../helpers/url';
import { PAYOUT_STAFF } from '../../config/env';

export interface FilterState {
//   curValue: number;
  allHotelList: IAutocompleteSelectOptionType[];
//   hotelNameIds: IHotelNameId[];
  hotelsLoading: 'idle' | 'loading' | 'succeeded' | 'error';
//   curHotelOPt: IAutocompleteSelectOptionType[];
//   allHotelOpts: IAutocompleteSelectOptionType[];
//   allHotelOptsInactive: IAutocompleteSelectOptionType[];
//   isHotelActive: boolean;
}

const initialState: FilterState = {
//   curValue: 0,
//   hotelNameIds: [],
  hotelsLoading: 'idle',
//   // curHotelOPt: {} as IAutocompleteSelectOptionType,
//   curHotelOPt: [], 
//   allHotelOpts: [],
//   allHotelOptsInactive: [], 
//   isHotelActive: true,
allHotelList:[]
};

export const multiSelectSlice = createSlice({
  name: 'multiSelectSlice',
  initialState,
  reducers: {
    changeMultiSelect: (state, action: PayloadAction<any>) => {
        // console.log("action.payload",action.payload);
        
    //   state.allHotelList = action.payload;
      // if (action.payload != 0) {
      //   window.history.replaceState(
      //     '',
      //     '',
      //     getUpdateURLParameter(window.location.href, 'guest-hotel', action.payload)
      //   );
      // }
    //   let op = state.allHotelOpts.find((option) => option.value === action.payload);
    //   if (op !== undefined) {
    //     state.curHotelOPt = [op];
    //     let opVal = parseInt(op.value);
    //     if (opVal != undefined) {
    //       let hotel = state.hotelNameIds.find((option) => option.id === opVal);
    //       if (hotel !== undefined) {
    //         state.isHotelActive = hotel.isActive;
    //       } else {
    //         state.isHotelActive = true;
    //       }
    //     }
    //   }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHotelsNameId.pending, (state) => {
      state.allHotelList = [];
      hotelsLoading: 'loading';
    });
    builder.addCase(fetchHotelsNameId.fulfilled, (state, action) => {
      const hotels = action.payload.results as IHotelNameId[];
      
      let opts: IAutocompleteSelectOptionType[] = [];
      hotels.map((hotelNameId) => {
        opts.push({ value: hotelNameId.id.toString(), label: hotelNameId.hotelName, active: hotelNameId.isActive ? true : false,payout:hotelNameId.payout});
      });
      
      state.allHotelList = [{value:"all",label:"All Hotels",active:true,payout:PAYOUT_STAFF},...opts];  

      hotelsLoading: 'succeeded';
    });
    builder.addCase(fetchHotelsNameId.rejected, (state, { payload }: any) => {
      hotelsLoading: 'error';
    });
  },
});

// Action creators are generated for each case reducer function
export const { changeMultiSelect } = multiSelectSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const MultiSelectSlice = (state: RootState) =>
  state.multiSelectHotelList;

export const multiSelectSliceReducer = multiSelectSlice.reducer;
