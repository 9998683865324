import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface SearchState {
  searchValue: string;
}

const initialState: SearchState = {
  searchValue: '',
};

export const hotelPaymentsSearchSlice = createSlice({
  name: 'hotelPaymentsSearch',
  initialState,
  reducers: {
    changeSearch: (state, action: PayloadAction<any>) => {
      state.searchValue = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeSearch } = hotelPaymentsSearchSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectHotelPaymentsSearchSlice = (state: RootState) => state.hotelPaymentsSearch;

export const hotelPaymentsSearchReducer = hotelPaymentsSearchSlice.reducer;
