import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IHotelPaymentTimesheetDeails } from '../../models/hotelPaymentsInterfaces';
import { fetchMaidTimeSheets } from '../../services/maidPaymentsService';

export interface FilterState {
  timesheetDetails: IHotelPaymentTimesheetDeails;
}

const initialState: FilterState = {
  timesheetDetails: {} as IHotelPaymentTimesheetDeails,
};

export const maidPaymentsTimesheetSlice = createSlice({
  name: 'maidPaymentsTimesheet',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMaidTimeSheets.pending, (state) => {
      state.timesheetDetails = {} as IHotelPaymentTimesheetDeails;
    });
    builder.addCase(fetchMaidTimeSheets.fulfilled, (state, action) => {
      const hotels = action.payload as IHotelPaymentTimesheetDeails;
      state.timesheetDetails = hotels;
    });
    builder.addCase(fetchMaidTimeSheets.rejected, (state, { payload }: any) => {
      //   state.loading = "error";
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = maidPaymentsTimesheetSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMaidPaymentsTimesheetSlice = (state: RootState) => state.maidPaymentsTimesheet;

export const maidPaymentsTimesheetReducer = maidPaymentsTimesheetSlice.reducer;
