import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IHotelPayment } from '../../models/hotelPaymentsInterfaces';
import { fetchPayments } from '../../services/hotelPaymentService';
import { IPagination } from '../../services/interfaces';
import dayjs from 'dayjs';
import { GetStartDate, GetEndDateDate } from '../../helpers/dateOps';
// Based on filterState we need to change the date we actally send to backend
// if filterState is transDate you can send the selected date
// if filterState is payPeriod we actually need data that is one month old
// since payperiod is delayed by 1 month
// hence if payperiod is 1 feb to 15 feb
// we actually  we need data from 1 Jan to 1 Jan.
export interface DataState {
  hotelPayments: IHotelPayment[];
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
  totalSize: number;
  filterState: 'transDate' | 'payPeriod';
  filterFrom: string;
  filterTo: string;
}

const initialState: DataState = {
  hotelPayments: [],
  loading: 'idle',
  error: '',
  totalSize: 0,
  filterState: 'payPeriod',
  filterFrom: GetStartDate('hp'),
  filterTo: GetEndDateDate('hp'),
};

export const hotelPaymentsDataSlice = createSlice({
  name: 'hotelPaymentsData',
  initialState,
  reducers: {
    sethotelPayments: (state, action: PayloadAction<any>) => {
      state.hotelPayments = action.payload;
    },
    setFilterState: (state, action: PayloadAction<any>) => {
      state.filterState = action.payload;
      state.filterFrom = GetStartDate('hp');
      state.filterTo = GetEndDateDate('hp');
    },
    setFilterFrom: (state, action: PayloadAction<string>) => {
      state.filterFrom = action.payload;
    },
    setFilterTo: (state, action: PayloadAction<string>) => {
      state.filterTo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPayments.pending, (state) => {
      state.hotelPayments = [];
      state.loading = 'loading';
    });
    builder.addCase(fetchPayments.fulfilled, (state, action) => {
      const m = action.payload as IPagination<IHotelPayment[]>;
      state.hotelPayments = m.results;
      state.loading = 'succeeded';
      state.totalSize = m.count;
    });
    builder.addCase(fetchPayments.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { sethotelPayments, setFilterState, setFilterFrom, setFilterTo } =
  hotelPaymentsDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectHotelPaymentsDataSlice = (state: RootState) => state.hotelPaymentsData;

export const hotelPaymentsDataReducer = hotelPaymentsDataSlice.reducer;
