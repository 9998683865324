import { postWithoutAuth } from './apiService';
import { getAccessToken, getHotelAccessToken } from './localStorage';

export interface AuthPayload {
  username: string;
  password: string;
}

export const authenticate = async (username: string, password: string) => {
  const auth: AuthPayload = { username, password };
  return await (
    await postWithoutAuth('users/v1/admin-token/', auth)
  ).data;
};

export const isAuthenticated = (): boolean => {
  return getHotelAccessToken() ? true : false;
};

export const authenticateHotel = async (username: string, password: string) => {
  const auth: AuthPayload = { username, password };
  return await (
    await postWithoutAuth('hotels/v1/login/', auth)
  ).data;
}; 

export const isAuthenticatedHotel = (): boolean => {
  return getHotelAccessToken() ? true : false;
};
