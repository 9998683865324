import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

interface IProps {
  amountValue: string;
  amountCurrency: string;
  totalAmountValue: string;
}

const MoneyWithTotalMoneyField: React.FC<IProps> = ({
  amountValue,
  amountCurrency,
  totalAmountValue,
}) => {
  const currency = getSymbolFromCurrency(amountCurrency);

  return (
    <span className="text-center">
        {totalAmountValue !== '0.00' &&
          `${String(currency)}${amountValue}/${String(currency)}${totalAmountValue}`}
    </span>
  );
};

export default MoneyWithTotalMoneyField;
