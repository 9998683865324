import { LBL_HOTELS, LBL_HOTEL_PAYMENTS, LBL_MAIDS, LBL_MAID, LBL_MAID_PAYMENTS, LBL_SETTINGS, LBL_TIPS, LBL_ASSOCIATES } from './common_labels';
import { USER_ROLE_HOTEL_MANAGER, USER_ROLE_HOTEL_USER } from './env'; 

// PERMISSIONS FOR ACCESS TO PAGES AND ACTIONS 

// ******************* PAGE ACCESS PERMISSIONS **************************
export const ARR_HOTEL_USER_PAGES = [LBL_HOTELS, LBL_MAIDS, LBL_SETTINGS, LBL_TIPS];  

export const checkPagePermission = (role: any, pageName: string) => {
    if(role==USER_ROLE_HOTEL_MANAGER.toString()) {
        return true; 
    }
    else if(role==USER_ROLE_HOTEL_USER.toString()) {
        return ARR_HOTEL_USER_PAGES.includes(pageName); 
    }
    else {
        return false; 
    }
};

// ******************* ACTION PERMISSIONS **************************
export const PERM_MAID_VIEW          = "View "+LBL_MAID+" Details"; 
export const PERM_MAID_CSV           = "Download "+LBL_MAID+" CSV File"; 
export const PERM_MAID_IMPORT        = "Import "+LBL_MAIDS; 
export const PERM_MAID_EXPORT        = "Export "+LBL_MAIDS; 
export const PERM_MAID_EDIT          = "Edit "+LBL_MAID+" Details"; 
export const PERM_MAID_APPROVE       = "Approve "+LBL_MAID; 
export const PERM_MAID_W9_DOWNLOAD   = "Download W9 From"; 
export const PERM_MAID_DELETE        = "Delete "+LBL_MAID; 

export const PERM_TIPS_EXPORT        = "Export Tips"; 
export const PERM_TIPS_ACTION        = "Tips Action Menu"; 
export const PERM_TIPS_REFUND        = "Tip Refund"; 

export const PERM_HOTEL_PAYMENTS_EXPORT = "Export Commission";  // COMMISSION 

export const PERM_MAID_PAYMENTS_VIEW    = "View "+LBL_MAID+" Payments Details"; 
export const PERM_MAID_PAYMENTS_CSV     = "Download "+LBL_MAID+" Payments CSV File"; 
export const PERM_MAID_PAYMENTS_IMPORT  = "Import "+LBL_MAID+" Payments"; 
export const PERM_MAID_PAYMENTS_EXPORT  = "Export "+LBL_MAID+" Payments"; 

export const PERM_ADD_HOTEL_USER  = "Add Hotel User"; 

export const PERM_EDIT_HOTEL_DETAILS = "Edit Hotel Details"; 

export const ARR_HOTEL_MANAGER_ACTIONS = [PERM_MAID_VIEW, PERM_MAID_PAYMENTS_VIEW];  

// PERM_MAID_VIEW, PERM_MAID_CSV, PERM_MAID_IMPORT, PERM_MAID_EXPORT, PERM_MAID_EDIT, PERM_MAID_APPROVE, PERM_MAID_W9_DOWNLOAD, PERM_MAID_DELETE, PERM_TIPS_EXPORT, PERM_TIPS_ACTION, PERM_TIPS_REFUND, PERM_HOTEL_PAYMENTS_EXPORT, PERM_MAID_PAYMENTS_VIEW, PERM_MAID_PAYMENTS_CSV,  PERM_MAID_PAYMENTS_IMPORT, PERM_MAID_PAYMENTS_EXPORT

export const ARR_HOTEL_USER_ACTIONS = [PERM_MAID_VIEW, PERM_MAID_PAYMENTS_VIEW,PERM_MAID_PAYMENTS_EXPORT];  

export const checkActionPermission = (role: any, permission: string) => {
    if(role==USER_ROLE_HOTEL_MANAGER.toString()) {
        if(permission==PERM_TIPS_ACTION) {
            return false;
        }
        else {
            return true; 
        }
        
    }
    else if(role==USER_ROLE_HOTEL_USER.toString()) {
        return ARR_HOTEL_USER_ACTIONS.includes(permission); 
    }
    else {
        return false; 
    }
};
