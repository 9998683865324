import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IHotelNameId } from '../../models/hotelsInterfaces';
import { fetchHotelsNameId } from '../../services/maidPaymentsService';
import { IAutocompleteSelectOptionType } from '../../models/maidsInterfaces';
import { getUpdateURLParameter } from '../../helpers/url';

export interface FilterState {
  curValue: number;
  hotelNameIds: IHotelNameId[];
  curHotelOPt: IAutocompleteSelectOptionType;
  allHotelOpts: IAutocompleteSelectOptionType[];
  isHotelActive: boolean;
}

const initialState: FilterState = {
  curValue: 0,
  hotelNameIds: [],
  curHotelOPt: {} as IAutocompleteSelectOptionType,
  allHotelOpts: [],
  isHotelActive: true,
};

export const hotelAssociatesHotelFilterSlice = createSlice({
  name: 'hotelAssociatesHotelFilter',
  initialState,
  reducers: {
    changeHotelFilter: (state, action: PayloadAction<any>) => {
      state.curValue = action.payload;
      // if (action.payload != 0) {
      //   window.history.replaceState(
      //     '',
      //     '',
      //     getUpdateURLParameter(window.location.href, 'maid-pay-hotel', action.payload)
      //   );
      // }
      let op = state.allHotelOpts.find((option) => option.value === action.payload);
      if (op !== undefined) {
        state.curHotelOPt = op;
        let opVal = parseInt(op.value);
        if (opVal != undefined) {
          let hotel = state.hotelNameIds.find((option) => option.id === opVal);
          if (hotel !== undefined) {
            state.isHotelActive = hotel.isActive;
          } else {
            state.isHotelActive = true;
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHotelsNameId.pending, (state) => {
      state.hotelNameIds = [];
    });
    builder.addCase(fetchHotelsNameId.fulfilled, (state, action) => {
      const hotels = action.payload.results as IHotelNameId[];
      state.hotelNameIds = hotels;
      let opts: IAutocompleteSelectOptionType[] = [];
      hotels.map((hotelNameId) => {
        opts.push({ value: hotelNameId.id.toString(), label: hotelNameId.hotelName });
      });
      state.allHotelOpts = opts;
      if (opts.length > 0) {
        if (state.curValue == 0) {
          let opt = opts.find((option) => option.value !== undefined);
          if(!opt || opt == undefined) {
            opt = opts[0]; 
          }
          if (opt != undefined) {
            state.curHotelOPt = opt;
            state.curValue = parseInt(opt.value);
            let opVal = parseInt(opt.value);
            if (opVal != undefined) {
              let hotel = state.hotelNameIds.find((option) => option.id === opVal);
              if (hotel !== undefined) {
                state.isHotelActive = hotel.isActive;
              } else {
                state.isHotelActive = true;
              }
            }
          }
        } else {
          let opt = opts.find((option) => parseInt(option.value) == state.curValue);
          if(!opt || opt == undefined) {
            opt = opts[0]; 
          }
          if (opt != undefined) {
            state.curHotelOPt = opt;
            state.curValue = parseInt(opt.value);
            let opVal = parseInt(opt.value);
            if (opVal != undefined) {
              let hotel = state.hotelNameIds.find((option) => option.id === opVal);
              if (hotel !== undefined) {
                state.isHotelActive = hotel.isActive;
              } else {
                state.isHotelActive = true;
              }
            }
          }
        }
      }
    });
    builder.addCase(fetchHotelsNameId.rejected, (state, { payload }: any) => {
      //   state.loading = "error";
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { changeHotelFilter } = hotelAssociatesHotelFilterSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selecthotelAssociatesHotelFilterSlice = (state: RootState) =>
  state.hotelAssociatesHotelFilter;

export const hotelAssociatesHotelFilterReducer = hotelAssociatesHotelFilterSlice.reducer;
