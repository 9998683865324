import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import logo from './../../assets/img/tova.png';
import sideImageSmall from './../../assets/img/small_background.png';
import { APP_NAME, COPYRIGHT_TEXT, notification_shown_time_success } from '../../config/env';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { selectAuthentication, ResetPassword } from './hotelResetPasswordSlice';
import { Store } from 'react-notifications-component';
import { MSG_PASSOWRD_UPDATED } from '../../config/messages';

function HotelResetPswd(props: any) {

  const [password, setPassword] = useState('')
  const [confirm_password, SetConfirm_password] = useState('')
  const [mismatchError, setMismatchError] = useState(false)

  const history = useHistory();

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const f_token = new URLSearchParams(search).get("f_token");
  const token = new URLSearchParams(search).get("token");
  const user_id = new URLSearchParams(search).get("user_id");
  const expiry_time = new URLSearchParams(search).get("expiry_time");


  // if (isAuthenticatedResetHotel()) {
  //   history.push('/v1/password/reset/submit/');
  // }


  const { isProcessingRequest, isResetPasswordSuccess } = useAppSelector(selectAuthentication);

  React.useEffect(() => {
    if (isResetPasswordSuccess == true) {
      Store.addNotification({
        title: APP_NAME,
        message: MSG_PASSOWRD_UPDATED,
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: notification_shown_time_success,
          onScreen: true,
          click: false,
          showIcon: false
        }
      });
      history.push('/login');
    }
  }, [isResetPasswordSuccess]);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setMismatchError(false);
  }, [password, confirm_password]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (password == confirm_password) {
      dispatch(ResetPassword(f_token, token, user_id, expiry_time, password, confirm_password));
    }
    else {
      setMismatchError(true);
    }
  }


  return (
    <>

      <div className="align-center-register flex-col mb-4">
        <div className="box-p20-t10 border max-width-500">
          <div>
            <div className="justify-center mt-25px mb-50px">
              <img className="medium" src={logo} alt={APP_NAME} />
            </div>
          </div>

          <form autoComplete="off" onSubmit={handleSubmit} >
            <div className="content_container-register margin-top25">
              <label className="color-blue h3 pt-1 font-bold">Reset Password</label>
              <div >
                <div >
                  <div className=" mt-33">
                    <label className='resetpass-label'>Enter new password</label>
                  </div>
                  <div className="mt-10px">
                    <input
                      type="password"
                      required
                      name="newpass"
                      className="w-100px"
                      placeholder="Enter new password here"
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </div>
                </div>


                <div>
                  <div className=" mt-33">
                    <label className='resetpass-label'>Confirm password</label>
                  </div>
                  <div className="mt-10px">
                    <input
                      type="password"
                      className="w-100px"
                      required
                      name="confirmPass"
                      placeholder="Enter confirm password here"
                      onChange={(event) => SetConfirm_password(event.target.value)}
                    />
                  </div>
                  {mismatchError ? <p className='danger mt-2' >Password mismatch</p> : null}
                </div>


              </div>
              <div className="margin-top45 mb-50px">
                <button type="submit" className="primary p-3 w-100">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <footer>{COPYRIGHT_TEXT}</footer>
    </>
  )
}

export default HotelResetPswd;