const server_env = 'production'; // [production, staging, demo, development]

const env: any = {
  development: {
    API_URL: 'http://localhost:8000/api/',
  },
  demo: {
    API_URL: 'https://demo.usetova.com/api/',
  },
  staging: {
    API_URL: 'https://staging.usetova.com/api/',
  },
  production: {
    API_URL: 'https://v1admin.usetova.com/api/',
  }
};

const stripe_token: any = {
  development:
    'pk_test_51JwWdxBbuiOTOd22NxfWpZI5AiXAVDILVSmJs2992atRkzaiWE3b1NWiNpYYe4amEA8SvjcCEGfvcbdEIluAHGYE001YLpws1P',
  demo:
    'pk_test_51M5CVpDgjqVrWG5SKSZznH0S60qr1ZPGo0deVLwQTX0RBpDm1ufPR2n0LZbeZhZItaP9Cc5eQy61UVvl0ND8n9et00ELoWqZXI',
  staging:
    'pk_test_51JwWdxBbuiOTOd22NxfWpZI5AiXAVDILVSmJs2992atRkzaiWE3b1NWiNpYYe4amEA8SvjcCEGfvcbdEIluAHGYE001YLpws1P',
  production:
    'pk_live_51JwWdxBbuiOTOd22tDmHUiBKGRdYxAbQueBxvLI5hbVwG1B2BYrsnGzy5kBtgMmV8VR8yI6F6hc9s9AoScI0OUIU009jTaB7qS',
};

const server_url: any = {
  development: 'http://localhost:3000',
  demo: 'https://demo.usetova.com',
  staging: 'https://staging.usetova.com',
  production: 'https://v1admin.usetova.com',
};

const tip_server_url: any = {
  development: 'http://localhost:3000',
  demo: 'https://demo.usetova.com',
  staging: 'https://staging.usetova.com',
  production: 'https://pay.usetova.com',
};

export const env_var = env[server_env];

export const stripe_token_var = stripe_token[server_env];

export const server_url_var = server_url[server_env];

export const tip_server_url_var = tip_server_url[server_env];

export const APP_NAME = 'TOVA'; 

export const notification_shown_time_success = 2000;
export const notification_shown_time_failed  = 3000;
export const notification_shown_time_failed4 = 4000;

export const DEFAULT_COUNTRY  = 'US'; 
export const DEFAULT_CURRENCY = 'USD'; 

export const COPYRIGHT_TEXT = 'Copyright © 2022 '+APP_NAME+'. All rights reserved.'; 

export const TIP_AMT_MAX_VALUE = 999; 
export const SMS_MAX_LENGTH = 160; 
export const TIP_FACT_HEADER_MAX_LENGTH = 80;
export const TIP_FACT_BODY_MAX_LENGTH = 250;

export const USER_ROLE_ADMIN_SUPERADMIN = 1;
export const USER_ROLE_ADMIN_ADMIN      = 2;
export const USER_ROLE_HOTEL_MANAGER    = 3;
export const USER_ROLE_HOTEL_USER       = 4;

export const USER_GROUP_SUPERADMIN      = 1;
export const USER_GROUP_HOTELS          = 2;
export const USER_GROUP_MAIDS           = 3;

export const PAGINATION_NUM_ROWS  = 20;

export const ROOM_NUMBER_VALUE = 1;  // USED FOR HOTEL ROOM LABEL - IN HOTEL ADD/EDIT
export const ROOM_NAME_VALUE   = 2;

export const HOTEL_CODE_MAX_LENGTH = 50; 

export const PAYOUT_TOVA = 1;   // USED FOR ADDING BANK ACC - IN HOTEL ADD/EDIT
export const PAYOUT_HOTEL = 2;
export const PAYOUT_STAFF  = 3;

export const STAFF_PAYMENT_METHOD_BANK  = 1;   // USED IN STAFF DETAILS SLIDER 
export const STAFF_PAYMENT_METHOD_CHECK = 2;

export const STAFF_DOC_TYPE_PASSOPORT_ID            = 1;
export const STAFF_DOC_TYPE_PASSOPORT               = "Passport";
export const STAFF_DOC_TYPE_PASSOPORT_CARD_ID       = 2;
export const STAFF_DOC_TYPE_PASSOPORT_CARD          = "Passport card";
export const STAFF_DOC_TYPE_DRIVER_LICENSE_ID       = 3;
export const STAFF_DOC_TYPE_DRIVER_LICENSE          = "Driver license";
export const STAFF_DOC_TYPE_STATE_ID_CARD_ID        = 4;
export const STAFF_DOC_TYPE_STATE_ID_CARD           = "State issued ID card";
export const STAFF_DOC_TYPE_GREEN_CARD_ID           = 5;
export const STAFF_DOC_TYPE_GREEN_CARD              = "Resident permit ID / U.S. Green Card";
export const STAFF_DOC_TYPE_BORDER_CROSSING_CARD_ID = 6;
export const STAFF_DOC_TYPE_BORDER_CROSSING_CARD    = "Border crossing card";
export const STAFF_DOC_TYPE_CHILD_ID_CARD_ID        = 7;
export const STAFF_DOC_TYPE_CHILD_ID_CARD           = "Child ID card";
export const STAFF_DOC_TYPE_NYC_CARD_ID             = 8;
export const STAFF_DOC_TYPE_NYC_CARD                = "NYC card";
export const STAFF_DOC_TYPE_US_VISA_CARD_ID         = 9;
export const STAFF_DOC_TYPE_US_VISA_CARD            = "U.S. visa card"; 

export const COMPANY_DOC_TYPE_IRS_LETTER_147C_ID    = 10; // USED FOR DOCUMENT SUBMIT
export const COMPANY_DOC_TYPE_IRS_LETTER_147C       = "IRS Letter 147C"; 
export const COMPANY_DOC_TYPE_IRS_SS4_LETTER_ID     = 11;
export const COMPANY_DOC_TYPE_IRS_SS4_LETTER        = "IRS SS-4 confirmation letter"; 