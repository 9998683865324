import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface SearchState {
  searchValue: string;
}

const initialState: SearchState = {
  searchValue: '',
};

export const guestPaymentsSearchSlice = createSlice({
  name: 'guestPaymentsSearch',
  initialState,
  reducers: {
    changeSearch: (state, action: PayloadAction<any>) => {
      state.searchValue = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeSearch } = guestPaymentsSearchSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectGuestPaymentsSearchSlice = (state: RootState) => state.guestPaymentsSearch;

export const guestPaymentsSearchReducer = guestPaymentsSearchSlice.reducer;
