export const setTokens = (authData: any) => {
  // localStorage.setItem('user', JSON.stringify(authRes.user));
  localStorage.setItem('token', authData.access);
  localStorage.setItem('refreshToken', authData.refresh);
};

export const removeTokens = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('token');
  localStorage.removeItem('hotelUserName');
};

export const getAccessToken = () => localStorage.getItem('token');
export const getUser = () => localStorage.getItem('user');
export const setUser = (user: any) => localStorage.setItem('user', JSON.stringify(user));
export const getRefreshToken = () => localStorage.getItem('refresh_token');

// ******************************************************************
// ***************** FOR HOTEL LOGIN - 09 SEP 2022 ****************** 
// ******************************************************************

export const setHotelTokens = (authData: any) => {
  localStorage.setItem('hotelToken', authData.access);
  localStorage.setItem('hotelRefreshToken', authData.refresh);
  localStorage.setItem('hotelId', authData.hotelId);
  // localStorage.setItem('hotelLogo', authData.hotelLogo);
  localStorage.setItem('hotelUserId', authData.userId);
  localStorage.setItem('hotelUserName', authData.fullName);
  localStorage.setItem('hotelUserRole', authData.role);
  localStorage.setItem('hotelUserHotelIds', authData.hotelIds);
};

//export const setHotelId = (decode: any) => localStorage.setItem('hotelId', decode.user_id);

export const getHotelAccessToken = () => localStorage.getItem('hotelToken');
export const getHotelRefreshToken = () => localStorage.getItem('hotelRefreshToken');
// export const getHotelId = () => localStorage.getItem('hotelId');
export const getHotelLogo = () => localStorage.getItem('hotelLogo');
export const getHotelUserId = () => localStorage.getItem('hotelUserId');
export const getHotelUserName = () => localStorage.getItem('hotelUserName');
export const getHotelUserRole = () => localStorage.getItem('hotelUserRole');
export const getHotelUserHotelIds = () => localStorage.getItem('hotelUserHotelIds');

export const removeHotelTokens = () => {
  localStorage.removeItem('hotelToken');
  localStorage.removeItem('hotelRefreshToken');
  localStorage.removeItem('hotelUserRole');
  localStorage.removeItem('hotelUserHotelIds');
  localStorage.removeItem('hotelId');
  localStorage.removeItem('hotelUserId');
  localStorage.removeItem('hotelUserName');
  // localStorage.removeItem('hotelLogo');
  localStorage.removeItem('selectedHotelId');
  localStorage.removeItem('hotelUserName');
};

// **********************************************************************
// ***************** TO STORE AND FETCH SELECTED HOTEL ****************** 
// **********************************************************************

export const setSelectedHotelId = (hotelId: any) => localStorage.setItem('selectedHotelId', hotelId); 
export const getSelectedHotelId = () => localStorage.getItem('selectedHotelId');

