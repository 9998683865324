import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface SearchState {
  searchValue: string;
}

const initialState: SearchState = {
  searchValue: '',
};

export const maidListSearchSlice = createSlice({
  name: 'maidListSearch',
  initialState,
  reducers: {
    changeSearch: (state, action: PayloadAction<any>) => {
      state.searchValue = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeSearch } = maidListSearchSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMaidListSearchSlice = (state: RootState) => state.maidListSearch;

export const maidListSearchReducer = maidListSearchSlice.reducer;
