import { LBL_ASSOCIATE, LBL_MAIDS, LBL_MAID } from './common_labels'
import { APP_NAME } from './env'

export const MSG_FILEIMPORT_INVALID_HEADER  = 'File not imported. Invalid header name(s).'; 

export const MSG_NEW_USER_CREATION          = 'New '+LBL_ASSOCIATE+' created. An email has been sent to the specified email for verification.';
export const MSG_NEW_USER_CREATION_FAILED   = 'New '+LBL_ASSOCIATE+' creation failed.';
export const MSG_USER_MODIFICATION          = LBL_ASSOCIATE+' details updated successfully.'; 
export const MSG_USER_MODIFICATION_FAILED   = LBL_ASSOCIATE+' details updation failed.'; 
export const MSG_USER_STATUS_UPDATE         = LBL_ASSOCIATE+' status updated successfully.';
export const MSG_USER_STATUS_UPDATE_FAILED  = LBL_ASSOCIATE+' status updation failed.';
export const MSG_USER_VERIFY_EMAIL_RESENT   = 'Verification email has been resent';
export const MSG_USER_VERIFY_EMAIL_RESENT_FAILED  = 'Verification email sending failed';
export const MSG_PASSOWRD_UPDATED           = 'Your password has been successfully updated.';
export const MSG_REFUND_SUCCESS             = 'Refund success.'; 
export const MSG_HOTEL_DETAILS_UPDATED      = 'Hotel details updated.'; 
export const MSG_HOTEL_DETAILS_SAVED        = 'New hotel created.';   
export const MSG_HOTEL_STATUS_UPDATED       = 'Hotel status updated.'; 
export const MSG_HOTEL_DETAILS_UPDATE_FAILED= 'Hotel details updation failed.'; 
export const MSG_INVALID_PHONE_NUMBER       = 'Invalid phone number.'; 
export const MSG_LINK_COPIED                = 'Link has been copied to clipboard.'; 
export const MSG_ENTER_VALID_EMAIL          = 'Enter a valid email address.'; 
export const MSG_INVALID_AMOUNT             = 'Invalid amt.'; 
export const MSG_RESET_PASSWORD_EMAIL_SENT  = 'Reset password email sent.'; 
export const MSG_RESET_PASSWORD_EMAIL_FAILED= 'Reset password email sending failed.'; 
export const MSG_RESET_PASSWORD_USER_NOT_EXISTS= 'Invalid Credentials.'; 
export const MSG_INVITATION_SENT            = 'Invitation sent.'; 
export const MSG_HOTEL_USERNAME_SAVED       = 'Hotel username saved.'; 
export const MSG_HOTEL_IMPORT_SUCCESS       = 'Hotel details has been imported successfully.'; 
export const MSG_NO_RECORDS_IMPORTED        = 'No records imported.'; 
export const MSG_HOTEL_IMPORT_FAILED        = 'Hotel import failed.'; 
export const MSG_INVALID_INPUT_FILE         = 'Invalid input file.';  
export const MSG_STARTED_PROCESS_FAILED_TRANS='Started processing failed transactions.';
export const MSG_INVALID_FILE_FORMAT        = 'Invalid file format.';   
export const MSG_MAID_ACTIVATED             = LBL_MAID+' has been activated.';  
export const MSG_MAID_DELETED               = LBL_MAID+' has been deleted.';  
export const MSG_MAID_DETAILS_UPDATED       = LBL_MAID+' details updated.';  
export const MSG_MAID_DETAILS_UPDATE_FAILED = LBL_MAID+' details updation failed.';  
export const MSG_MAID_STATUS_UPDATED        = LBL_MAID+' status has been updated successfully.';  
export const MSG_ROUTING_9_DIGITS           = 'Routing number must have 9 digits.';  
export const MSG_W9_FORM_CREATED            = 'W9 form created.';  
export const MSG_W9_FORM_CREATION_FAILED    = 'W9 form creation failed.';  
export const MSG_W9_FORM_GENERATE_FAILED    = 'Failed to gnerate W9 form.';  
export const MSG_ID_PROOF_UPLOADED          = 'ID proof uploaded.';  
export const MSG_ID_PROOF_UPLOAD_FAILED     = 'ID proof upload failed.';  
export const MSG_ID_PROOF_REMOVED           = 'ID proof removed.';  
export const MSG_ID_PROOF_REMOVAL_FAILED    = 'ID proof removal failed.';  
export const MSG_TIMESHEET_IMPORT_SUCESS    = 'Timesheet has been imported successfully.'; 
export const MSG_TIMESHEET_IMPORT_DATES_INVALID='Some dates are invalid in the timesheet.'; 
export const MSG_APPROVE_USERS_BEFORE_IMPORT= 'Approve the user(s) before importing timesheet.'; 
export const MSG_NO_RECORDS_AVAILABLE       = 'No records available.'; 
export const MSG_SETTINGS_UPDATION_FAILED   = 'Settings details updation failed.'; 
export const MSG_SETTINGS_UPDATED           = 'Settings updated.'; 
export const MSG_INVALID_TIP                = 'Invalid tip.'; 
export const MSG_USER_REG_FAILED            = 'User registration failed.'; 
export const MSG_OTP_SENDING_FAILED         = 'OTP sending failed.'; 
export const MSG_PRIMARY_USERNAME_EXISTS    = 'Hotel primary username (email) already exists.'; 
export const MSG_HOTEL_CODE_EXISTS          = 'Hotel code already exists.'; 
export const MSG_HOTEL_CREATION_FAILED      = 'Hotel creation failed.'; 
export const MSG_HOTEL_QR_CODE_FAILED       = 'Not able to generate QR code now. Please try after sometime.'; 
export const MSG_HOTEL_IMAGE_SIZE_SIZE      = 'Image file size exceeded. Max allowed logo file size is 512 kb.'; 
export const MSG_USER_NOT_ELIGIBLE          = 'User is not eligible for admin approval. Some mandatory fields are missing.'; 
export const MSG_MAID_APPROVAL_FAILED       = LBL_MAID+' approval failed.'; 
export const MSG_MAID_IMPORT_SUCCESS        = LBL_MAIDS+' details has been imported successfully.'; 
export const MSG_MAID_IMPORT_FAILED         = LBL_MAIDS+' import failed.'; 
export const MSG_USER_ALREADY_REGISTERED    = 'Username already registered.'; 
export const MSG_USERNAME_SAVING_FAILED     = 'Username saving failed.'; 
export const MSG_LINK_EXPIRED               = 'This link has already expired.'; 
export const MSG_PWD_RESET_FAILED           = 'Password reset failed.'; 
export const MSG_INVALID_USER               = 'Invalid user.'; 
export const MSG_USERNAME_EXISTS            = 'Username (email) already exists.'; 
export const MSG_MOBILE_EXISTS              = 'Mobile number already exists.'; 
export const MSG_INVALID_EMAIL              = 'Invalid email.';  
export const MSG_USER_NOT_REGISTERED        = 'User not registered.';  
export const MSG_UNAUTHORISED               = 'Unauthorized.';  
export const MSG_NO_ACTIVE_ACCOUNT          = 'No active account found with the given credentials.';  
export const MSG_INVALID_CREDENTIALS        = 'Invalid credentials.';  
export const MSG_INVALID_OTP                = 'Invalid OTP.';  
export const MSG_LOGIN_AGAIN                = 'You need to login again.';  
export const MSG_APPNAME_ERROR             =  APP_NAME+' - error.';  
export const MSG_SOMETHING_WENT_WRONG       = 'Something went wrong.';  
export const MSG_SERVER_ERROR               = 'An issue has occured at the server side.';  
export const MSG_FILE_FORMAT_ERROR          = 'File format - error.';  
export const MSG_VERIFY_CODE_SENT_TO        = 'Verification code sent to ';  
export const MSG_OTP_SENT                   = 'OTP has been sent.';  
export const MSG_CONFIRM_REM_ID_PROOF_IMAGE = 'Are you sure you want to remove this ID Proof image?';  
export const MSG_CONFIRM_RESET_PASSWORD     = 'Are you sure you want to reset the password ';  
export const MSG_USER_EDIT_DUPLICATE        = 'This user already exists in the system. Use Add User option to add existing users.'
export const MSG_INVALID_SESSION            = 'Invalid session. Please re-login. ';  
export const MSG_USER_DELETED               = LBL_ASSOCIATE+' deleted successfully.';
export const MSG_USER_DELETION_FAILED       = LBL_ASSOCIATE+' deletion failed.';
export const MSG_STAFF_ID_PROOF_TYPE_ERROR  = 'Only .png or .jpg files are supported.';

















