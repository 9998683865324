import { get, post, put, deleteApi, getFile, postFile, getAuthImg, patch } from './apiService';
import { env_var, notification_shown_time_failed } from '../config/env';
import { IMaidsRequestData, IMaidsExportRequestData, IMaidEdit, IStaffHotelStatus } from '../models/maidsInterfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Store } from 'react-notifications-component';
import { MSG_W9_FORM_GENERATE_FAILED, MSG_FILE_FORMAT_ERROR, MSG_INVALID_FILE_FORMAT, MSG_APPNAME_ERROR } from '../config/messages';
import { IHotelIdMaid } from '../models/hotelsInterfaces';

export const fetchMaids = createAsyncThunk(
    'maids/fetchMaids',
    async ({ page, search, hotel, sort, document_verified, joining_from_date, joining_to_date, active }: IMaidsRequestData, thunkAPI: any) => {
        try {
            //let url = 'users/v1/maids/?page=' + page;
            let url = 'hotel-users/v1/maids/?page=' + page + '&hotel=' + hotel;
            if (search != '') {
                url = url + '&search=' + search?.trim();
            }
            if (sort != '') {
                url = url + '&sort=' + sort;
            }
            if (document_verified != '') {
                url = url + '&document_verified=' + document_verified;
            }
            if (joining_from_date != '') {
                url = url + '&joining_from_date=' + joining_from_date;
            }
            if (joining_to_date != '') {
                url = url + '&joining_to_date=' + joining_to_date;
            }
            if (active != '') {
                url = url + '&active=' + active;
            }
            const response = get(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const fetchMaidDetails = createAsyncThunk(
    'maids/fetchMaidDetails',
    async (id: number, thunkAPI: any) => {
        try {
            let url = 'hotel-users/v1/maid-details/' + id + '/';
            const response = get(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const approveMaid = createAsyncThunk(
    'maids/approveMaid',
    async (form:IHotelIdMaid, thunkAPI: any) => {
        try {
            let url = 'hotel-users/v1/maid-approve/' + form.id + '/'+ form.hotelId + '/';
            const response = post(url, null);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);  

export const adminUpdateMaid = createAsyncThunk(
    'maids/adminUpdateMaid',
    async (maidEditData: IMaidEdit, thunkAPI: any) => {
        try {
            let url = 'hotel-users/v1/maid-update/' + maidEditData.id + '/';
            const response = patch(url, maidEditData);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
); 

export const getImageId = createAsyncThunk(
    'maids/getImageId',
    async (id: number, thunkAPI: any) => {
        try {
            let url = 'hotel-users/v1/upload-id-proof/' + id + '/';
            const response = get(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const exportMaids = ({ search, hotel , sort, document_verified, joining_from_date, joining_to_date }: IMaidsRequestData): void => {
    try {
        let url = 'hotel-users/v1/maid/download-csv/?hotel=' + hotel;
        if (search != '') {
            url = url + '&search=' + search?.trim();
        }
        if (sort != '') {
            url = url + '&sort=' + sort;
        }
        if (document_verified != '') {
            url = url + '&document_verified=' + document_verified;
        }
        if (joining_from_date != '') {
            url = url + '&joining_from_date=' + joining_from_date;
        }
        if (joining_to_date != '') {
            url = url + '&joining_to_date=' + joining_to_date;
        }        
        getFile(url, '');
    } catch (error) {
    }
};

export const exportW9 = async (id: number): Promise<any> => {
    try {
        let url = 'hotel-users/v1/maids/generate-tax-form/' + id + '/';
        const resp = get(url);
        return await resp;
    } catch (error) {
        return new Promise((resolve) => {
            resolve(500);
            Store.removeNotification('w9');
            Store.addNotification({
                title: MSG_APPNAME_ERROR,
                message: MSG_W9_FORM_GENERATE_FAILED,
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: notification_shown_time_failed,
                    onScreen: true,
                },
            });
        });
    }
};

export const importMaids = async (hotel: any, file: any): Promise<any> => {
    const url = 'hotel-users/v1/maid/upload-csv/?hotel=' + hotel;
    let resp = await postFile(url, file);
    // console.log('importMaids resp: ',resp); 
    return (resp);

    // try {
    //     const url = 'users/v1/maids/upload-csv/?hotel=' + hotel;
    //     const resp = postFile(url, file);
    //     return (await resp)?.status;
    // } catch (error) {        
    //     // return new Promise((resolve) => {
    //     //     resolve(500);
    //     // });
    //     Store.addNotification({
    //         title: MSG_FILE_FORMAT_ERROR,
    //         message: MSG_INVALID_FILE_FORMAT,
    //         type: 'danger',
    //         insert: 'top',
    //         container: 'top-center',
    //         animationIn: ['animate__animated', 'animate__fadeIn'],
    //         animationOut: ['animate__animated', 'animate__fadeOut'],
    //         dismiss: {
    //             duration: notification_shown_time_failed,
    //             onScreen: true,
    //         },
    //     });
    // }
};

export const getSampleMaidImport = (): void => {
    try {
        let url = 'users/v1/csv/maidimport/';
        getFile(url, 'Staff_List_Import');
    } catch (error) {
    }
};

export const deleteMaid = createAsyncThunk(
    'maids/deleteMaid',
    async (id: number, thunkAPI: any) => {
        try {
            let url = 'hotel-users/v1/delete-maid/' + id + '/';
            const response = deleteApi(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const changeStaffStatus = createAsyncThunk(
    'maids/staffStatus',
    async (staffHotel: IStaffHotelStatus, thunkAPI: any) => {
        try {
            let url = 'hotels/v1/staff-active-change/'+staffHotel.staffId+'/'+staffHotel.hotelId+'/';
            const response = post(url,null);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);