import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { changeSearch, selectHotelListSearchSlice } from './hotelListSearchSlice';
import {
  setCurrentPage,
  setTotalPage,
  setStartLoading,
  setStopLoading,
  selectHotelListPaginationSlice,
} from './hotelListPaginationSlice';
import SearchBar from '../../components/SearchBar';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { selectHotelListDataSlice, setDataLoading } from './hotelListDataSlice';
import { IHotel, IHotelListRequestData } from '../../models/hotelsInterfaces';
import {
  fetchHotels,
  importHotels,
  exportHotels,
  getHotelSample,
} from '../../services/hotelService';
import { ITableHeader } from '../../models/genericInterfaces';
import { hotelListFields } from '../../config/tables';
import { useHistory } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import logo from './../../assets/img/csv.png';
import { notification_shown_time_success, notification_shown_time_failed, APP_NAME } from '../../config/env';
import { MSG_FILEIMPORT_INVALID_HEADER, MSG_HOTEL_IMPORT_FAILED, MSG_HOTEL_IMPORT_SUCCESS, MSG_INVALID_INPUT_FILE, MSG_NO_RECORDS_IMPORTED } from '../../config/messages';

export const HotelList = () => {
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [listFetchHotelPromise, setListFetchHotelPromise] = useState<Array<any>>([]);
  const [displaySearch, setDisplaySearch] = useState<string>('');
  const { searchValue } = useAppSelector(selectHotelListSearchSlice);
  const { currentPage } = useAppSelector(selectHotelListPaginationSlice);
  const { hotels, totalSize, loading } = useAppSelector(selectHotelListDataSlice);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [applySearch, setApplySearch] = useState(0);

  const onNavigate = (value: string): void => {
    history.push(value);
  };

  const fetchall = (): void => {
    let sVal = searchValue;
    if (displaySearch.length > 2) {
      dispatch(setDataLoading());
      changeSearch(displaySearch);
    }
    sVal = displaySearch;
    listFetchHotelPromise.forEach((p) => {
      p.abort();
    });
    const req: IHotelListRequestData = {
      page: currentPage,
      search: sVal,
    };
    const promise = dispatch(fetchHotels(req));
    setListFetchHotelPromise([promise]);
  };

  React.useEffect(() => {
    fetchall();
  }, [dispatch, applySearch, currentPage]); // displaySearch

  const onSearchButton = () => {
    acceptPage(1); 
    setApplySearch(applySearch+1);
  };

  const acceptSearch = (e: any): void => {
    setDisplaySearch(e.target.value as string);
  };

  const acceptPage = (e: any): void => {
    dispatch(setCurrentPage(e));
  };

  const onImport = (): void => {
    inputFile?.current?.click();
  };

  const onExport = (): void => {
    const req: IHotelListRequestData = {
      page: currentPage,
      search: searchValue?.trim(),
    };
    exportHotels(req);
  };

  const postHotels = (e: any): void => {
    (async () => {
      const uploadStatus = await importHotels(e.target.files[0]);

      if (uploadStatus?.status === 200) {
        fetchall();
        let msgTitle = APP_NAME; 
        let msgText  = MSG_HOTEL_IMPORT_SUCCESS; 
        let dismisObj = {
          duration: notification_shown_time_success,
          onScreen: true,
          click: true,
          showIcon: false
        }        
        let notImpCount = uploadStatus?.data?.notImported ? uploadStatus.data.notImported.length : 0; 
        let totCount = uploadStatus?.data?.totalCount ? uploadStatus.data.totalCount : 0; 
        let impCount = totCount-notImpCount; 
        msgText  = MSG_HOTEL_IMPORT_SUCCESS+' '+impCount+'/'+totCount+' records imported. '; 

        if(impCount > 0 && totCount===impCount) {
          Store.addNotification({
            title: msgTitle,
            message: msgText,
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: notification_shown_time_success,
              onScreen: true,
              click: false,
              showIcon: false
            }
          });
        }
        else if(totCount===notImpCount) {
          msgText  = MSG_NO_RECORDS_IMPORTED; 
          Store.addNotification({
            title: msgTitle,
            message: msgText,
            type: 'danger',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: notification_shown_time_success,
              onScreen: true,
              click: false,
              showIcon: false
            }
          });
        }
        else if(notImpCount > 0) { 
          let rowText = uploadStatus.data.notImported.length===1 ? ' Row ' : ' Rows ';  
          msgText = impCount+'/'+totCount+' records imported. '+rowText+uploadStatus.data.notImported.join(',')+' not imported.'; 
          Store.addNotification({
            title: msgTitle,
            message: msgText,
            type: 'danger',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 0,
              onScreen: true,
              click: true,
              showIcon: true
            },
          });
        }
      }
      else {
        let msgTitle = APP_NAME; 
        let msgText  = MSG_HOTEL_IMPORT_FAILED; 
        if(uploadStatus.response.status === 400) {
          msgTitle = APP_NAME; 
          msgText  = MSG_INVALID_INPUT_FILE; 
          if(uploadStatus?.response?.data?.message && uploadStatus?.response?.data.message === 'invalid_headings') {
            msgText  = MSG_FILEIMPORT_INVALID_HEADER;  
        }

          Store.addNotification({
            title: msgTitle,
            message: msgText,
            type: 'danger',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 0,
              onScreen: true,
              click: true,
              showIcon: true
            }
        });
        }
      }
      inputFile!.current!.value = '';
    })();
  };

  const onClickable = (id: number, name: string): void => {
    history.push('edit-hotel/' + id);
  };

  const downloadSample = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    getHotelSample();
  };

  return (
    <>
      <div className="content_container">
      <div className="box">
        <div className="col-12 row mb-2">
          <h4 className="page-heading div-left maid-list-title">Hotels List</h4>
        </div>
        <div className="justify-space-between">
          <SearchBar onSearch={acceptSearch} currentSearch={displaySearch} place_holder="&#xf002; Search hotel name or code" searchButton={true} onSearchButton={onSearchButton}/>
          <div className="justify-end">
            <input
              type="file"
              onChange={postHotels}
              id="file"
              ref={inputFile}
              style={{ display: 'none' }}
            />

            {/* <button type="button" className="primary csv-button" onClick={downloadSample} title="CSV template for importing hotels.">
              <img
                src={logo}
                className="info-sample invert-filter"
                title="CSV template for importing hotels."
              ></img>
            </button> */}
            {/* <button type="button" className="primary margin-left-15px" onClick={() => onImport()}>
              Import Hotels
            </button> */}
            {/* <button type="button" className="primary  margin-left-15px" onClick={() => onExport()}>
              Export Hotels
            </button> */}
            {/* <button
              type="button"
              className="primary margin-left-15px"
              onClick={() => onNavigate('add-hotel')}
            >
              Add Hotel
            </button> */}
          </div>
        </div>
        <div className="content-box table-wrapper">
          <Table
            titles={hotelListFields}
            data={hotels}
            isLoading={loading === `loading` ? true : false}
            onClickable={onClickable}
            currentPage={0}
          />
          <div className="justify-right">
            <div className="pagination">
              <Pagination
                isTableLoading={loading === `loading` ? true : false}
                totalCount={totalSize}
                currentPage={currentPage}
                setCurrentPage={acceptPage}
                pageSize={20}
                siblingCount={2}
              />
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};
