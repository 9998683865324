import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface SearchState {
  searchValue: string;
}

const initialState: SearchState = {
  searchValue: '',
};

export const maidPaymentsSearchSlice = createSlice({
  name: 'maidPaymentsSearch',
  initialState,
  reducers: {
    changeSearch: (state, action: PayloadAction<any>) => {
      state.searchValue = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeSearch } = maidPaymentsSearchSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMaidPaymentsSearchSlice = (state: RootState) => state.maidPaymentsSearch;

export const maidPaymentsSearchReducer = maidPaymentsSearchSlice.reducer;
