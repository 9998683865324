import React, { useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import { PaymentsLogFields } from '../../config/tables';
import { selectPaymentListDataSlice, setDataLoading } from './paymentListDataSlice';
import { IHotel, IHotelListRequestData } from '../../models/hotelsInterfaces';
import {
  setCurrentPage,
  setTotalPage,
  setStartLoading,
  setStopLoading,
  selectHotelListPaginationSlice,
} from '../HotelList/hotelListPaginationSlice';
import { changeSearch, selectHotelListSearchSlice } from '../HotelList/hotelListSearchSlice';
import AddPageHeader from '../../components/AddPageHeader';
import logo from './../../assets/img/tova_stack.png';


import { fetchPaymentLog } from '../../services/hotelService';

export const PaymentLog = (props: any) => {
	const { paymentlogs, totalSize, loading } = useAppSelector(selectPaymentListDataSlice);
	 const [listFetchHotelPromise, setListFetchHotelPromise] = useState<Array<any>>([]);
	 const [displaySearch, setDisplaySearch] = useState<string>('');
   const [appLogo, setAppLogo] = useState<boolean>(true);
   const [smallHeader, setSmallHeader] = useState<boolean>(false);
   const [contentTitle, setContentTitle] = useState<string>('Payment Log');
	 const dispatch = useAppDispatch();
	 const [applySearch, setApplySearch] = useState(0);
	 const { searchValue } = useAppSelector(selectHotelListSearchSlice);
	 const { currentPage } = useAppSelector(selectHotelListPaginationSlice);
  const [title, setTitle] = useState<string>('Payment Log');

	const fetchall = (): void => {
    let sVal = searchValue;
    if (displaySearch.length > 2) {
      dispatch(setDataLoading());
      changeSearch(displaySearch);
    }
    sVal = displaySearch;
    listFetchHotelPromise.forEach((p) => {
      p.abort();
    });
    const req: IHotelListRequestData = {
      page: currentPage,
      search: sVal,
    };
    const promise = dispatch(fetchPaymentLog(req));
    setListFetchHotelPromise([promise]);
  };

  const acceptPage = (e: any): void => {
    dispatch(setCurrentPage(e));
  };


  React.useEffect(() => {
    fetchall();
  }, [dispatch, applySearch, currentPage]); // displaySearch


  	return (
  	<div className="content-box table-wrapper">
      <div className="content_header">
      <div className="justify-space-between">
        <div className="justify-left">
          {appLogo &&(
                <a className="text-white hotel-logo-right" href="#">
                  <img src={logo} alt="logo" className="small headerSmall"/>
                </a>
              )
            }
          <div>
            <div>
              {!smallHeader && <h1 className="font-bold color-blue">{contentTitle}</h1>}
              {smallHeader && <h3 className="font-bold color-blue margin-top10 margin-left-15px ">{contentTitle}</h3>}
            </div>
          </div>
        </div>
      </div>
    </div>


          <Table
            titles={PaymentsLogFields}
            data={paymentlogs}
            isLoading={loading === `loading` ? true : false}
            currentPage={0}
          />
          <div className="justify-right">
            <div className="pagination">
              <Pagination
                isTableLoading={loading === `loading` ? true : false}
                totalCount={totalSize}
                currentPage={currentPage}
                setCurrentPage={acceptPage}
                pageSize={20}
                siblingCount={2}
              />
            </div>
          </div>
        </div>
	);
};