import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IMaidPayment } from '../../models/maidPaymentsInterface';
import { fetchMaidPayments } from '../../services/maidPaymentsService';
import { IGenericResponse, IPagination } from '../../services/interfaces';
import { GetStartDate, GetEndDateDate } from '../../helpers/dateOps';
import { getUpdateURLParameter } from '../../helpers/url';

export interface DataState {
  maidPayments: IMaidPayment[];
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  maidPaymentsLoading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
  totalSize: number;
  filterState: 'transDate' | 'payPeriod';
  filterFrom: string;
  filterTo: string;
  filterDateRange: 'current' | 'prev' | 'manual' | 'next';
  filterIndex: number;
}

const initialState: DataState = {
  maidPayments: [],
  loading: 'idle',
  maidPaymentsLoading: 'idle', 
  error: '',
  totalSize: 0,
  filterState: 'payPeriod',
  filterFrom: GetStartDate('mp'),
  filterTo: GetEndDateDate('mp'),
  filterDateRange: 'prev',
  filterIndex: -1,
};

export const maidPaymentsDataSlice = createSlice({
  name: 'maidPaymentsData',
  initialState,
  reducers: {
    setmaidPayments: (state, action: PayloadAction<any>) => {
      console.log('setmaidPayments: ',action.payload); 
      state.maidPayments = action.payload;
    },
    setFilterPreserveDatesState: (state, action: PayloadAction<any>) => {
      state.filterState = action.payload;
      state.filterIndex = -1;
    },
    setFilterState: (state, action: PayloadAction<any>) => {
      state.filterState = action.payload;
      state.filterFrom = GetStartDate('null');
      state.filterTo = GetEndDateDate('null');
      state.filterDateRange = 'prev';
      state.filterIndex = -1;
      window.history.replaceState(
        '',
        '',
        getUpdateURLParameter(window.location.href, 'mp-filter', action.payload)
      );
    },
    setFilterFrom: (state, action: PayloadAction<any>) => {
      state.filterFrom = action.payload;
    },
    setFilterTo: (state, action: PayloadAction<any>) => {
      state.filterTo = action.payload;
    },
    setFlterIndex: (state, action: PayloadAction<'+' | '-'>) => {
      if (action.payload === '+') {
        state.filterIndex = state.filterIndex + 1;
      }
      if (action.payload === '-') {
        state.filterIndex = state.filterIndex - 1;
      }
      if (state.filterIndex === 0) {
        state.filterDateRange = 'current';
      } else if (state.filterIndex === -1) {
        state.filterDateRange = 'prev';
      } else if (state.filterIndex > 0) {
        state.filterDateRange = 'next';
      } else {
        state.filterDateRange = 'manual';
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMaidPayments.pending, (state) => {
      state.maidPayments = [];
      state.loading = 'loading';
      state.maidPaymentsLoading = 'loading';
    });
    builder.addCase(fetchMaidPayments.fulfilled, (state, action) => {
      const m = action.payload as IPagination<IMaidPayment[]>;
      console.log('setmaidPayments: ',m.results); 
      state.maidPayments = m.results;
      state.loading = 'succeeded';
      state.maidPaymentsLoading = 'succeeded';
      state.totalSize = m.count;
    });
    builder.addCase(fetchMaidPayments.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      state.maidPaymentsLoading = 'error';
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setmaidPayments,
  setFilterState,
  setFilterFrom,
  setFilterTo,
  setFlterIndex,
  setFilterPreserveDatesState,
} = maidPaymentsDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMaidPaymentsDataSlice = (state: RootState) => state.maidPaymentsData;

export const maidPaymentsDataReducer = maidPaymentsDataSlice.reducer;
