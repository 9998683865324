import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import dayjs from 'dayjs';
import Select, { Options } from 'react-select';
import { Store } from 'react-notifications-component';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Autocomplete, Checkbox, TextField } from '@mui/material';

import { changeSearch, selectGuestPaymentsSearchSlice } from './guestPaymentsSearchSlice';
import {
  setCurrentPage,
  setTotalPage,
  setStartLoading,
  setStopLoading,
  selectGuestPaymentsPaginationSlice,
} from './guestPaymentsPaginationSlice';
import SearchBar from '../../components/SearchBar';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import {
  selectGuestPaymentsDataSlice,
  setFilterState,
  setFilterFrom,
  setFilterTo,
  resetGuestPaymentsSummary,
  resetGuestPayments,
} from './guestPaymentsDataSlice';

import {
  setguestPayments,
  selectGuestRefundSlice,
  selectGuestRefundReducer,
} from './guestRefundSlice';
import { IGuestPaymentsRequestData } from '../../models/guestPaymentsInterfaces';

import {
  fetchPayments,
  fetchPaymentsSummary,
  exportGuestPayments,
} from '../../services/guestPaymentService';
import { getSelectedHotelId, setSelectedHotelId } from '../../services/localStorage';
import { getHotelUserRole } from '../../services/localStorage';
import {
  PERM_TIPS_EXPORT,
  checkPagePermission,
  checkActionPermission,
} from '../../config/permissions';

import { guestPaymentsFields } from '../../config/tables';
import {
  selectGuestPaymentsHotelFilterSlice,
  changeHotelFilter,
} from './guestPaymentHotelFilterSlice';
import { fetchHotelsNameId } from '../../services/maidPaymentsService';
import DateFilterComponent from '../../components/DateFilterComponent';
import { performRefund } from '../../services/guestPaymentService';
import { getLowerDateRange, getNextDateRange } from '../../helpers/dateOps';
import {
  LBL_EXPORT_TIPS,
  LBL_TIPS_OF,
  LBL_TIPS,
  LBL_INCLUDE_INACTIVE_HOTELS,
} from '../../config/common_labels';
import { IAutocompleteSelectOptionType } from '../../models/maidsInterfaces';
import { PAYOUT_STAFF } from '../../config/env';
import MultiSelect from '../../components/MultiSelect';

export const GuestPayments = (props: any) => {
  let hotelUserRole = getHotelUserRole();

  const { searchValue } = useAppSelector(selectGuestPaymentsSearchSlice);
  const { currentPage, isLoading } = useAppSelector(selectGuestPaymentsPaginationSlice);
  const { curHotelOPt, allHotelOpts, allHotelOptsInactive } = useAppSelector(
    selectGuestPaymentsHotelFilterSlice
  );
  const { refundLoading } = useAppSelector(selectGuestRefundSlice);
  const {
    guestPayments,
    guestPaymentsSummary,
    totalSize,
    loading,
    guestPaymentsLoading, 
    filterState,
    filterFrom,
    filterTo,
  } = useAppSelector(selectGuestPaymentsDataSlice);

  const dispatch = useAppDispatch();

  const { hotelNameIds, curValue, hotelsLoading, isHotelActive } = useAppSelector(
    selectGuestPaymentsHotelFilterSlice
  );

  const [applySearch, setApplySearch] = useState(0);
  const [localStorageHotelId, setLocalStorageHotelId] = useState(getSelectedHotelId());

  const [hotelListObj, setHotelListObj] = useState<IAutocompleteSelectOptionType[]>([]);
  const [hotelList, setHotelList] = useState<string[]>([]); // useState<number[]>([]);

  const [paymentFields, setPaymentFields] = useState(guestPaymentsFields);

  const [inactiveHotel, setInactiveHotel] = useState(false);
  const [activeHotel, setactiveHotel] = useState<IAutocompleteSelectOptionType[]>([]);
  const [includePayout, setIncludePayout] = useState(false);
  const [currHotelIds, setCurrHotelIds] = useState<string>(''); 

  const [multiSearchText, setMultiSearchText] = useState(''); 
  const [searchDetailsChanged, setSearchDetailsChanged] = useState(true); 

  React.useEffect(() => { 
    if(guestPaymentsLoading!='loading' && guestPaymentsLoading!='idle') {
      setSearchDetailsChanged(false); 
    }
  }, [guestPaymentsLoading]);  

  React.useEffect(() => {
    // dispatch(fetchHotelsNameId(''));
    // let search = window.location.search;
    // let params = new URLSearchParams(search);
    // if (localStorageHotelId) {
    //   dispatch(changeHotelFilter(localStorageHotelId));
    // } else if (props.match.params.id !== undefined) {
    //   dispatch(changeHotelFilter(props.match.params.id));
    //   setSelectedHotelId(props.match.params.id);
    //   setLocalStorageHotelId(props.match.params.id);
    // } else if (params.get('guest-hotel')) {
    //   dispatch(changeHotelFilter(params.get('guest-hotel')));
    //   setSelectedHotelId(params.get('guest-hotel'));
    //   setLocalStorageHotelId(params.get('guest-hotel'));
    // }

    return function cleanup() {
      dispatch(changeHotelFilter(0));
      // dispatch(setCurrentPage(1));
    };
  }, []);

  // const onSetHotelList = (value: any) => {
  //   setHotelListObj(value);
  //   setHotelList(value.map((item: any) => parseInt(item.value, 10)));
  // };

  // const fetchPayDetailsBKUP = () => {
  //   if (hotelList.length <= 1) {
  //     let arrFields = guestPaymentsFields.filter((item) => item.title != 'hotelName');
  //     setPaymentFields(arrFields);
  //   } else {
  //     setPaymentFields(guestPaymentsFields);
  //   }

  //   const req: IGuestPaymentsRequestData = {
  //     page: currentPage,
  //     hotel: hotelList.length > 0 ? hotelList.join() : '0',
  //     start: encodeURIComponent(filterFrom),
  //     end: encodeURIComponent(filterTo),
  //     search: searchValue.trim(),
  //   };
  //   dispatch(fetchPayments(req));
  //   dispatch(fetchPaymentsSummary(req));
  // };


  const fetchPayDetails = () => {
    if (hotelList.length <= 1) {
      let arrFields = guestPaymentsFields.filter((item) => item.title != 'hotelName');
      setPaymentFields(arrFields);
    } else {
      setPaymentFields(guestPaymentsFields);
    }

    let hIds = hotelList.join(',').replace('all,','');
    if (searchDetailsChanged || (hotelList.length > 0 && hIds!=currHotelIds)) {
      const hasAllOption = hotelList.some((hotel) => hotel === 'all');
      let hotelsList =
        hasAllOption && inactiveHotel
          ? 'all'
          : hasAllOption && !inactiveHotel
          ? 'active_all'
          : hIds;
      if(hotelsList && hotelsList.length > 0) {
        const req: IGuestPaymentsRequestData = {
          page: currentPage,
          hotel: hotelsList,
          start: encodeURIComponent(filterFrom),
          end: encodeURIComponent(filterTo),
          search: searchValue.trim(),
        };
        dispatch(fetchPayments(req));
        dispatch(fetchPaymentsSummary(req));
        setCurrHotelIds(hIds); 
      }
    } else if (hotelList.length==0) { 
      dispatch(
        resetGuestPaymentsSummary({
          paymentCommission: '',
          total: 0,
          totalCommission: 0,
          totalCommissionCurrency: '',
          totalCurrency: '',
        })
      );
      dispatch(resetGuestPayments([]));
      setCurrHotelIds(''); 
    }
  };

  // const fetchPayDetails = () => {
  //   if (hotelList.length <= 1) {
  //     let arrFields = guestPaymentsFields.filter((item) => item.title != 'hotelName');
  //     setPaymentFields(arrFields);
  //   } else {
  //     setPaymentFields(guestPaymentsFields);
  //   }

  //   if (hotelList.length > 0) {
  //     const hasAllOption = hotelList.some((hotel) => hotel === 'all');
  //     let hotelsList =
  //       hasAllOption && inactiveHotel
  //         ? 'all'
  //         : hasAllOption && !inactiveHotel
  //         ? 'active_all'
  //         : hotelList.join();
  //     const req: IGuestPaymentsRequestData = {
  //       page: currentPage,
  //       //hotel: hotelList.length > 0 ? hotelList.join() : '0',
  //       hotel: hotelsList,
  //       start: encodeURIComponent(filterFrom),
  //       end: encodeURIComponent(filterTo),
  //       search: searchValue.trim(),
  //     };
  //     dispatch(fetchPayments(req));
  //     dispatch(fetchPaymentsSummary(req));
  //   } else {
  //     dispatch(
  //       resetGuestPaymentsSummary({
  //         paymentCommission: '',
  //         total: 0,
  //         totalCommission: 0,
  //         totalCommissionCurrency: '',
  //         totalCurrency: '',
  //       })
  //     );
  //     dispatch(resetGuestPayments([]));
  //   }
  // };

  // React.useEffect(() => {
  //   let selectedHotelId: any = localStorage.getItem('selectedHotelId');
  //   if (selectedHotelId !== 0) {
  //     let arr: any = [];
  //     let arr2: any = [];
  //     allHotelOptsInactive.map((d: any) => {
  //       arr.push(d);
  //     });

  //     arr.filter((d: any) => (d.value == selectedHotelId ? arr2.push(d) : null));
  //     console.log('arr2: ', arr2);
  //     setHotelListObj(arr2);
  //     setHotelList(arr2.map((item: any) => parseInt(item.value, 10)));
  //   }
  // }, [curValue]);

  React.useEffect(() => {
    fetchPayDetails();
  }, [applySearch, currentPage, filterState, hotelList]); 

  React.useEffect(() => {
    setSearchDetailsChanged(true); 
  }, [filterFrom, filterTo]);

  React.useEffect(() => {
    fetchPayDetails();
  }, [searchDetailsChanged]);

  const onSearchButton = () => {
    acceptPage(1);
    setApplySearch(applySearch + 1);
  };

  React.useEffect(() => {
    if (refundLoading == 'success') {
      fetchPayDetails();
    }
  }, [refundLoading]);

  const acceptSearch = (e: any): void => {
    dispatch(changeSearch(e.target.value));
  };

  const acceptPage = (e: any): void => {
    dispatch(setCurrentPage(e));
  };

  const setStartDate = (e: Date): void => {
    dispatch(setFilterFrom(dayjs(e).format(`YYYY-MM-DDTHH:mm:ssZZ`)));
  };
  const setEndDate = (e: Date): void => {
    dispatch(setFilterTo(dayjs(e).format(`YYYY-MM-DDT23:59:59ZZ`)));
  };

  const setLess = (): void => {
    let dateRange = getLowerDateRange(filterFrom, filterTo, 'guest');
    dispatch(setFilterFrom(dateRange[0]));
    dispatch(setFilterTo(dateRange[1]));
  };

  const setMore = (): void => {
    let dateRange = getNextDateRange(filterFrom, filterTo, 'guest');
    dispatch(setFilterFrom(dateRange[0]));
    dispatch(setFilterTo(dateRange[1]));
  };

  const dateFilter = (): JSX.Element => {
    if (filterState === 'transDate') {
      return (
        <div className="div-left" id="transDate">
          <div className="div-left">
            <div className="div-left margin-left-15px pay-period">From</div>
          </div>
          <div className="div-left  margin-left-5px pointer-cursor">
            <DateFilterComponent
              defaultDate={dayjs(filterFrom).toDate()}
              setDate={setStartDate}
              format={`YYYY-MM-DDTHH:mm:ssZZ`}
              page="guest"
              type="start"
            />
          </div>
          <div className="div-left">
            <div className="div-left margin-left-15px pay-period">To</div>
          </div>
          <div className="div-left  margin-left-5px pointer-cursor">
            <DateFilterComponent
              defaultDate={dayjs(filterTo).toDate()}
              minDate={filterFrom}
              setDate={setEndDate}
              format={`YYYY-MM-DDTHH:mm:ssZZ`}
              page="guest"
              type="end"
            />
          </div>
        </div>
      );
    }
    return <span />;
  };

  const payPeriodFilter = (): JSX.Element => {
    if (filterState === 'payPeriod') {
      return (
        <div className="div-left" id="payPeriod">
          {loading != 'loading' ? <div onClick={() => setLess()} className="box-data div-left pointer-cursor">
            &lt;
          </div> : <div className="box-data div-left">
            &lt;
          </div>}
          <div className="box-data  div-left">{dayjs(filterFrom).format('MMM DD, YYYY')}</div>
          <div className="box-data  div-left">{dayjs(filterTo).format('MMM DD, YYYY')}</div>
          {loading != 'loading' ? <div onClick={() => setMore()} className="box-data div-left pointer-cursor">
            &gt;
          </div> : <div className="box-data div-left">
            &gt;
          </div>}
        </div>
      );
    }
    return <span />;
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    dispatch(setFilterState(e.target.value));
  };

  const handleHotelFilterChange = (e: any): void => {
    acceptPage(1);
    dispatch(changeHotelFilter(e.value));
    setSelectedHotelId(e.value);
    setLocalStorageHotelId(e.value);
  };

  const onExport = (): void => {
    const req: IGuestPaymentsRequestData = {
      page: currentPage,
      hotel: hotelList.length > 0 ? hotelList.join() : '0',
      start: encodeURIComponent(filterFrom),
      end: encodeURIComponent(filterTo),
      search: searchValue?.trim(),
    };
    exportGuestPayments(req);
  };

  const onClickable = (id: number, name: string): void => {
    if (name === `Refund`) {
      let gp = guestPayments.find((option) => option.id === id);
      if (gp != undefined) {
        if (gp.refunded == false) {
          Store.addNotification({
            title: 'Refund',
            id: 'refund-modal',
            content: (
              <div className="refund-modal">
                <div className="refund-flex-center page-heading">
                  <h4>Perform Refund</h4>
                </div>
                <div className="refund-flex-center">
                  Are you sure you want to refund {getSymbolFromCurrency(gp.amountCurrency)}
                  {gp.amount} to {gp.customerName}?
                </div>
                <div className="refund-margin"></div>
                <div className="refund-flex-center">
                  <button
                    type="button"
                    className="primary"
                    onClick={() => dispatch(performRefund(id))}
                  >
                    Refund
                  </button>
                  <button
                    type="button"
                    className="margin-left-15px"
                    onClick={() => Store.removeNotification('refund-modal')}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ),
            type: 'default',
            container: 'center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 1000000000,
              click: false,
              touch: false,
            },
          });
        }
      }
      // dispatch(setguestPayments(id));
      // dispatch(performRefund(id));
    }
  };

  // useEffect(() => {
  //     document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-light-grey');
  // }, [])

  // function selectBoxOnFocus() {
  //     document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-grey');
  //     document.getElementsByClassName('react-select-maids__single-value')[0].classList.remove('color-blue');
  // }

  // function selectBoxOnBlur() {
  //     document.getElementsByClassName('react-select-maids__single-value')[0].classList.remove('color-grey');
  //     document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-blue');
  // }

  return (
    <>
      <div className="header-content-container">
        <div className="box">
          {/* <div className="col-12 mb-2 row">
                        <h4 className="page-heading div-left ml-5px">{LBL_TIPS}</h4>
                    </div> */}
          <div className="row">
            <div className="col-12 mb-3">
              <h4 className="page-heading div-left ml-5px padding-top5 ">{LBL_TIPS_OF}</h4>
              <div className="div-left col-3 ml-10px">
                <MultiSelect
                  getHotellist={setHotelList}
                  getIncludeInactiveStatus={setInactiveHotel}
                  getIncludePayoutStatus={setIncludePayout}
                  includePayout={false}
                />
                {/* <Select
                      onChange={handleHotelFilterChange}
                      name="hotelName"
                      onFocus={selectBoxOnFocus}
                      onBlur={selectBoxOnBlur}
                      className="margin-left-15px div-left"
                      classNamePrefix="react-select-maids"
                      options={allHotelOpts}
                      value={curHotelOPt}
                      isSearchable={true}
                  /> */}
                
              </div>
              <div className="div-left margin-left-25px">
                <select
                  value={filterState}
                  name="payPeriod"
                  className="div-left box-data  sel-box min-width-170"
                  onChange={(e) => handlePeriodChange(e)}
                >
                  <option value="payPeriod">Pay Period</option>
                  <option value="transDate">Transaction Date</option>
                </select>
                {payPeriodFilter()}

                {dateFilter()}
              </div>

              {/* <div className="div-left margin-left-25px">
                                <div className="div-left">
                                    <div className="div-left margin-right-15px pay-period">Pay Period </div>
                                </div>
                                {payPeriodFilter()}
                            </div> */}
            </div>
            {/*  {guestPayments.length > 0 && guestPayments[0].total && */}
            <div className="page-sub-heading col-3 div-left margin-left-15px ">
              <div>Total Tip Received</div>
              <div className="page-sub-data">
                <h4>
                  {/* $ {guestPaymentsSummary.length > 0 && guestPaymentsSummary[0].total ? guestPaymentsSummary[0].total : '0.00'} */}
                  {getSymbolFromCurrency(guestPaymentsSummary.totalCurrency || 'usd')}{' '}
                  {Number(guestPaymentsSummary.total).toFixed(2)}
                </h4>
              </div>
            </div>
            {/* } */}
          </div>
        </div>
      </div>
      <div className="header-content-container pt-0">
        <div className="box">
          <div className="justify-space-between">
            <SearchBar
              onSearch={acceptSearch}
              currentSearch={searchValue}
              place_holder="&#xf002; Search name"
              searchButton={true}
              onSearchButton={onSearchButton}
            />
            <div className="justify-end">
              {checkActionPermission(hotelUserRole, PERM_TIPS_EXPORT) && guestPayments.length > 0 && (
                <button type="button" className="primary  margin-left-15px" onClick={onExport}>
                  {LBL_EXPORT_TIPS}
                </button>
              )}
            </div>
          </div>
          <div className="content-box table-wrapper">
            <Table
              titles={paymentFields}
              data={guestPayments}
              onClickable={onClickable}
              isLoading={loading === `loading` ? true : false}
              currentPage={0}
              hotelUserRole={hotelUserRole}
            />
            <div className="justify-right">
              {guestPayments.length > 0 && (
                <div className="pagination">
                  <Pagination
                    isTableLoading={loading === `loading` ? true : false}
                    totalCount={totalSize}
                    currentPage={currentPage}
                    setCurrentPage={acceptPage}
                    pageSize={20}
                    siblingCount={2}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
