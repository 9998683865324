import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { APP_NAME, COPYRIGHT_TEXT } from '../../config/env';
import logo from './../../assets/img/tova.png';
import base from './../../assets/img/me_bg.jpg';

export const Privacypolicy = (props: any) => {
  const history = useHistory();

  const onLogin = () => {
    history.push('/login');
  };

  return (
    <>
      <div>
        <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
          <div className="container">
            <a className="navbar-brand text-white" href="#">
              <img src={logo} alt="logo" className="small" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              Menu
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              {/* <ul className="navbar-nav ml-auto justify-content-end d-flex w-100">
                <li className="nav-item text-white" onClick={onLogin}>
                  <Link className="none text-white" to="/login">
                    Login
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>
        </nav>
        <div className="">
          <img src={base} alt="image" className="standard" />
          <div className="centered text-white">
            <p className="h1 text-center">Privacy Policy</p>
            <br />
            <p className="h4">Privacy Statement</p>
            <p className="h6">
              A Privacy Policy agreement is required by law if you collect personal data about your site visitors.
              Customize the details of your policy to reflect the steps in place to protect your users’ information.
            </p>
            <br/>
            <p className="h4">Introduction</p>
            <p className="h6">
              {APP_NAME} is committed to protecting the data collected pertaining to my users.
              This policy details the collection, use, and protection of personal information
              we receive from application users and visitors. By entering our application you
              consent to our use of cookies as pertaining to the terms of this policy.
            </p>
            <br/>
            <p className="h4">Data Collection</p>
            <p className="h6">
              {APP_NAME} collects the minimum amount of personally identifiable information necessary
              to improve our application and the service it provides. Data we collect includes any or
              all of the information you provide while using the application to its full capability.
            </p>
            <br/>
            <p className="h4">Use of Information</p>
            <p className="h6">
              {APP_NAME} may use the personal information collected through the application specifically
              for the purposes detailed in this policy. Personal information that we have processed for
              any reason won’t be kept longer than is necessary for those purposes. We will take reasonable
              measures to prevent the loss or misuse of your personal information.
            </p>
            <br />
            {/* <p className="h4 text-center">{APP_NAME}… Coming Soon! </p> */}
          </div>
        </div>
        <div className="foot">
          <div className="bg-secondary bg-gradient text-center text-white-50 p-2">
            {COPYRIGHT_TEXT}
          </div>
        </div>
      </div>
    </>
  );
};
