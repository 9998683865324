import React, { FC } from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage';
import { PrivateRoute } from './components/PrivateRoute';
import { PageLayout } from './pages/PageLayout';

import { history } from './helpers/history';
import { setIntercept } from './services/apiService';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
import { BasePage } from './pages/Base';
import { HotelAddSuccess } from './pages/HotelAddSuccess';
import { Privacypolicy } from './pages/privacyPolicy';

import { PaymentLog } from './pages/PaymentLog';

import { HotelLogin } from './pages/HotelLogin/HotelLogin'; 
import HotelResetPswd from './pages/HotelResetPassword/hotelresetPassword';

export const App: FC = () => {
  React.useEffect(() => {
    setIntercept(history);
    if (process.env.NODE_ENV != 'development') {
      console.log = () => {};
    }
  }, []);

  return (
    <Router history={history}>
      <ReactNotifications />
      <Switch>
        <Route exact path="/  " component={Privacypolicy} />
        <Route exact path="/" component={HotelLogin} />
        {/* <Route exact path="/login" component={LoginPage} /> */}
        <Route exact path="/login" component={HotelLogin} />
        <Route exact path="/hotel-success" component={HotelAddSuccess} />


        {/* STAFF LOGIN PAGES */}
        <Route exact path="/payment/log" component={PaymentLog} />
        <Route exact path="/payment/log/" component={PaymentLog} />

        {/* HOTEL LOGIN PAGE */}
        <Route exact path="/hotel" component={HotelLogin} />

        <Route exact path="/resetpassword/" component={HotelResetPswd}/>

        <PrivateRoute exact path="/">
          <Redirect to={{ pathname: '/' }} />
        </PrivateRoute>
        <PrivateRoute path="/v1" component={PageLayout} />
      </Switch>
    </Router>
  );
};
