import { postWithoutAuth } from './apiService';

export interface AuthPayload {
  f_token: string;
  token: string;
  user_id: string; 
  username: string;
  password: string;
  expiry_time: string;
}

export interface AuthPay{
  f_token: string;
  token: string;
  user_id: string; 
  password: string;
  confirm_password: string;
  expiry_time: string;
}

export const ResetPasswordAction = async (f_token: any, token: any, user_id: any, expiry_time: any, password: string, confirm_password: string) => {
  const auth: AuthPay = { f_token, token, user_id, expiry_time, password, confirm_password };
  return await (
    await postWithoutAuth('hotels/v1/password/reset/submit/', auth)
  ).data;
}; 


