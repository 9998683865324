import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IHotelPaymentFilterData } from '../../models/hotelPaymentsInterfaces';
import { fetchPaymentsFilterData } from '../../services/hotelPaymentService';
import { IGenericResponse, IPagination } from '../../services/interfaces';
import dayjs from 'dayjs';

export interface DataState {
  hotelPaymentsFilterData: IHotelPaymentFilterData;
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
}

const initialState: DataState = {
  hotelPaymentsFilterData: {
    currency: '',
    totalDistributed: 0,
    successTransactions: 0,
    failedTransactions: 0,
    pendingTransactions: 0,
    totalTransactions: 0,
    totalCommission: 0,
    totalTip: 0,
  },
  loading: 'idle',
  error: '',
};

export const hotelPaymentsFilterDataSlice = createSlice({
  name: 'hotelPaymentsFilterData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentsFilterData.pending, (state) => {
      state.hotelPaymentsFilterData = {
        currency: '',
        totalDistributed: 0,
        successTransactions: 0,
        failedTransactions: 0,
        pendingTransactions: 0,
        totalTransactions: 0,
        totalCommission: 0,
        totalTip: 0,
      };
      state.loading = 'loading';
    });
    builder.addCase(fetchPaymentsFilterData.fulfilled, (state, action) => {
      const resp = action.payload as IHotelPaymentFilterData;
      state.hotelPaymentsFilterData = {
        currency: resp.currency,
        totalDistributed: resp.totalDistributed,
        successTransactions: resp.successTransactions,
        failedTransactions: resp.failedTransactions,
        pendingTransactions: resp.pendingTransactions,
        totalTransactions: resp.totalTransactions,
        totalCommission: resp.totalCommission,
        totalTip: resp.totalTip,
      };
      state.loading = 'succeeded';
    });
    builder.addCase(fetchPaymentsFilterData.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = hotelPaymentsFilterDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectHotelPaymentsFilterDataSlice = (state: RootState) =>
  state.hotelPaymentsFilterData;

export const hotelPaymentsFilterDataReducer = hotelPaymentsFilterDataSlice.reducer;
