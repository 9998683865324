import { LBL_HOTELS, LBL_HOTEL_PAYMENTS, LBL_MAIDS, LBL_MAID_PAYMENTS, LBL_TIPS, LBL_SETTINGS, LBL_ASSOCIATES, LBL_URL_MAIDS, LBL_URL_MAID_PAYMENTS } from '../../config/common_labels'; 

// COMMISSION TAB REMOVED FROM HOTEL USER's LOGIN SECTION AS PER CLIENT REQUEST - 27 OCT 2022 
// navBarList ARRAY USED FOR HOTEL USERS RELATED WITH SINGLE HOTEL
export const navBarList = [
  {
    name: LBL_MAIDS,
    path: '/v1/'+LBL_URL_MAIDS,
    imgSrc: '/assets/images/tickets.svg',
    imgSrcActive: '/assets/images/tickets-white-icon.svg',
  },
  {
    name: LBL_TIPS,
    path: '/v1/guest-payments',
    imgSrc: '/assets/images/tickets.svg',
    imgSrcActive: '/assets/images/tickets-white-icon.svg',
  },
  {
    name: LBL_MAID_PAYMENTS,
    path: '/v1/'+LBL_URL_MAID_PAYMENTS,
    imgSrc: '/assets/images/ticket-status-blue-icon.svg',
    imgSrcActive: '/assets/images/ticket-status-icon.svg',
  },
  {
    name: LBL_ASSOCIATES,
    path: '/v1/associates',
    imgSrc: '/assets/images/tickets.svg',
    imgSrcActive: '/assets/images/tickets-white-icon.svg',
  },
  {
    name: LBL_SETTINGS,
    path: '/v1/settings',
    imgSrc: '/assets/images/tickets.svg',
    imgSrcActive: '/assets/images/tickets-white-icon.svg',
  }
];

// navBarListMultiHotel ARRAY USED FOR HOTEL USERS RELATED WITH MULTIPLE HOTELS
export const navBarListMultiHotel = [
  {
    name: LBL_HOTELS,
    path: '/v1/hotels',
    imgSrc: '/assets/images/dashboard.svg',
    imgSrcActive: '/assets/images/dashboard-active.svg',
  },
  {
    name: LBL_MAIDS,
    path: '/v1/'+LBL_URL_MAIDS,
    imgSrc: '/assets/images/tickets.svg',
    imgSrcActive: '/assets/images/tickets-white-icon.svg',
  },
  {
    name: LBL_TIPS,
    path: '/v1/guest-payments',
    imgSrc: '/assets/images/tickets.svg',
    imgSrcActive: '/assets/images/tickets-white-icon.svg',
  },
  {
    name: LBL_MAID_PAYMENTS,
    path: '/v1/'+LBL_URL_MAID_PAYMENTS,
    imgSrc: '/assets/images/ticket-status-blue-icon.svg',
    imgSrcActive: '/assets/images/ticket-status-icon.svg',
  },
  {
    name: LBL_ASSOCIATES,
    path: '/v1/associates',
    imgSrc: '/assets/images/tickets.svg',
    imgSrcActive: '/assets/images/tickets-white-icon.svg',
  },
];

// {
//   name: LBL_HOTEL_PAYMENTS,
//   path: '/v1/hotel-payments',
//   imgSrc: '/assets/images/application-icon.svg',
//   imgSrcActive: '/assets/images/application-white-icon.svg',
// },

// {
//   name: LBL_HOTEL_PAYMENTS,
//   path: '/v1/hotel-payments',
//   imgSrc: '/assets/images/application-icon.svg',
//   imgSrcActive: '/assets/images/application-white-icon.svg',
// },
