import React, { useState, useEffect, useRef } from 'react';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
} from '@material-ui/core';
import { Box, Modal } from '@mui/material';
import ReactCrop, { PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

interface IProps {
  onUpload: (e: any) => void;
  defaultImage?: any;
  contentTitle?: string;
  id: string;
  label: string;
  url: string;
  checkDisable?: boolean;
  hideDeleteOption?: boolean;
}

const ImageBox: React.FC<IProps> = ({ defaultImage, onUpload, contentTitle, checkDisable, id, hideDeleteOption }) => {
  const wrapperRef = useRef<any | never>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const [currentimg, setCurrentimg] = React.useState<string | ArrayBuffer | null>('');
  const [open, setOpen] = useState(false);
  const [src, selectfile] = useState<string | ArrayBuffer | null>('');
  const [crop, setCrop] = useState<any>({ aspect: 1 / 1 });

  useEffect(() => {
    if (defaultImage !== undefined) {
      setCurrentimg(defaultImage);
    }
  }, [defaultImage]);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop({
      x: width / 35,
      y: height / 25,
      width: width - 2 * (width / 35),
      height: height - 2 * (height / 25),
      unit: 'px',
    });
  }

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');
  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
  const onDrop = () => wrapperRef.current.classList.remove('dragover');
  const onInputClick = (event: any) => {
    event.target.value = '';
  };

  // const onFileDrop = (e: any) => {
  //     const reader = new FileReader();

  //     reader.onload = () => {
  //         if (reader.readyState === 2) {
  //             setCurrentimg(reader.result)
  //             onUpload(reader.result);

  //         }
  //     }
  //     reader.readAsDataURL(e.target.files[0])
  // }

  const dragImage = (e: any) => {
    setOpen(true);
    selectfile(URL.createObjectURL(e.target.files[0]));
    e.target.value = '';
    // const reader = new FileReader();

    // reader.onload = () => {
    //     if (reader.readyState === 2) {
    //         selectfile(reader.result)
    // setCurrentimg(reader.result)
    // onUpload(reader.result);
    // console.log(src, currentimg, "hh");

    //     }
    // }
    // reader.readAsDataURL(e.target.files[0])
  };

  const fileRemove = () => {
    setCurrentimg('');
    onUpload('');
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    // bgcolor: 'transparent',
    border: 'none',
    boxShadow: 24,
    // p: 4,
  };

  function canvasPreview(image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop) {
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('No 2d context');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    ctx.translate(-cropX, -cropY);
    ctx.translate(centerX, centerY);
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );

    ctx.restore();

    const dataURL = canvas.toDataURL('image/png');
    // console.log(dataURL, "dataURL");

    setCurrentimg(dataURL);
    onUpload(dataURL);
    setOpen(false);
  }

  const handleCrop = () => {
    if (crop?.width && crop?.height && imgRef.current && previewCanvasRef.current) {
      canvasPreview(imgRef.current, previewCanvasRef.current, crop);
    }
  };

  return (
    <>
      <div className="div-label font-bold mb-2">{contentTitle}</div>
      <label
        className="rounded mb-3 drop-file-input"
        ref={wrapperRef}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        htmlFor={id}
      >
        {!currentimg && (
          <>
            <MoveToInboxIcon className="movetoinboxicon" />
            <p>Click or drag a file to this area to upload.</p>
          </>
        )}

        <input
          id={id}
          type="file"
          accept="image/*"
          onChange={dragImage}
          onClick={onInputClick}
          disabled={checkDisable}
        />

        {currentimg && (
          <div className="drop-file" ref={wrapperRef}>
            <div className="drop-file-preview ">
              <img
                src={`${currentimg}`}
                alt=""
                className="drop-file-preview_img"
                ref={wrapperRef}
              />

              {(hideDeleteOption || checkDisable) ? (
                <></>
              ) : (
                <span className="drop-file-preview-item-del" onClick={fileRemove}>
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                </span>
              )}
            </div>
          </div>
        )}
      </label>

      <Modal open={open} onClose={() => setOpen(false)} aria-describedby="Crop image">
        <Box sx={style}>
          <Card className="max-width-100">
            <CardHeader className="crophead" title="Crop" />

            <CardContent className="cropbody">
              {src && (
                <ReactCrop crop={crop} onChange={(c, percentCrop) => setCrop(c)}>
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={`${src}`}
                    onLoad={onImageLoad}
                    className="cropimage"
                  />
                </ReactCrop>
              )}
            </CardContent>
            <CardActions className="cropaction">
              <button
                type="button"
                className="primary div-right margin-right-15px"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="primary div-right margin-right-15px"
                onClick={handleCrop}
              >
                Crop Image
              </button>
            </CardActions>
          </Card>

          <canvas ref={previewCanvasRef} className="cropcanvas" />
        </Box>
      </Modal>
    </>
  );
};

export default ImageBox;
