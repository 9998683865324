import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { paymentlogs } from '../../models/hotelsInterfaces';
import { fetchPaymentLog } from '../../services/hotelService';
import { IGenericResponse, IPagination } from '../../services/interfaces';

export interface DataState {
  paymentlogs: paymentlogs[];
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
  totalSize: number;
}

const initialState: DataState = {
  paymentlogs: [],
  loading: 'idle',
  error: '',
  totalSize: 0,
};

export const paymentListDataSlice = createSlice({
  name: 'paymentlogListData',
  initialState,
  reducers: {
    setPaymentList: (state, action: PayloadAction<any>) => {
      state.paymentlogs = action.payload;
    },
    setDataLoading: (state) => {
      state.loading = 'loading';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentLog.pending, (state) => {
      state.paymentlogs = [];
      state.loading = 'loading';
    });
    builder.addCase(fetchPaymentLog.fulfilled, (state, action) => {
      const m = action.payload as IPagination<paymentlogs[]>;
      state.paymentlogs = m.results;
      state.loading = 'succeeded';
      state.totalSize = m.count;
    });
    builder.addCase(fetchPaymentLog.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setPaymentList, setDataLoading } = paymentListDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectPaymentListDataSlice = (state: RootState) => state.paymentListData;

export const paymentListDataReducer = paymentListDataSlice.reducer;
