import React from 'react';

interface IProps {
  successTransaction: string;
  failedTransactions: string;
}

const TransactionCountField: React.FC<IProps> = ({ successTransaction, failedTransactions }) => {
  const totalTransaction = successTransaction + failedTransactions;
  return (
    <span className="text-center">
      {totalTransaction} (<span className="success">{successTransaction}</span>/
      <span className="danger">{failedTransactions}</span>)
    </span>
  );
};

export default TransactionCountField;
