import { get, postWithoutAuth, put, deleteApi, getFile, post } from './apiService';
import { IGuestPaymentsRequestData } from '../models/guestPaymentsInterfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchPayments = createAsyncThunk(
  'guest/fetchPayments',
  async ({ page, start, end, hotel, search }: IGuestPaymentsRequestData, thunkAPI: any) => {
    try {
      let url = 'hotel-users/v1/tips/?page=' + page;
      if (start != '') {
        url = url + '&start=' + start;
      }
      if (end != '') {
        url = url + '&end=' + end;
      }
      if (search != '') {
        url = url + '&search=' + search.trim();
      }
      url = url + '&hotel=' + hotel;
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchPaymentsSummary = createAsyncThunk(
  'guest/fetchPaymentsSummary',
  async ({ page, start, end, hotel, search }: IGuestPaymentsRequestData, thunkAPI: any) => {
    try {
      let url = 'hotel-users/v1/tips/summary/?page=' + page;
      if (start != '') {
        url = url + '&start=' + start;
      }
      if (end != '') {
        url = url + '&end=' + end;
      }
      if (search != '') {
        url = url + '&search=' + search.trim();
      }
      url = url + '&hotel=' + hotel;
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const performRefund = createAsyncThunk(
  'guest/performRefund',
  async (id: number, thunkAPI: any) => {
    try {
      let url = 'hotel-users/v1/guest-payments/refund/?tip_id=' + id;
      const response = post(url, null);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
); 

export const exportGuestPayments = ({
  search,
  start,
  end,
  hotel,
}: IGuestPaymentsRequestData): void => {
  try {
    let url = 'hotel-users/v1/guest-payments/download-csv/?csv=true'+ '&hotel=' + hotel;
    if (start != '') {
      url = url + '&start=' + start;
    }
    if (end != '') {
      url = url + '&end=' + end;
    }
    if (search != '') {
      url = url + '&search=' + search.trim();
    }
    getFile(url, 'Guest_Payments_Exported');
  } catch (error) {
  }
};
