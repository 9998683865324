import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
const initialState = {
  message: '',
  type: '',
};
export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    successAlert: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        message: action.payload,
        type: 'success',
      };
    },
    warningAlert: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        message: action.payload,
        type: 'warning',
      };
    },
    errorAlert: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        message: action.payload,
        type: 'error',
      };
    },
    clear: (state) => {
      return {
        ...state,
        message: '',
        type: '',
      };
    },
  },
});
export const { successAlert, warningAlert, errorAlert, clear } = alertSlice.actions;
export const alertState = (state: RootState) => state.alert;
export const alertReducer = alertSlice.reducer;
