import { get, getWithoutAuth, post, put, postFile, getFile, patch, getPdfFile, postWithoutAuth } from './apiService';
import { useAppDispatch, useAppSelector } from '../helpers/hook';
import { setHotelList } from '../pages/HotelList/hotelListDataSlice';
import { IHotel, IHotelIdUsername, IHotelInviteByEmail, IHotelListRequestData, IHotelSettingsForm } from '../models/hotelsInterfaces';
import { IGenericResponse } from './interfaces';
import { createSlice, createSelector, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { IHotelForm, IHotelId } from '../models/hotelsInterfaces';
import { Store } from 'react-notifications-component';

export const fetchHotels = createAsyncThunk(
  'hotels/fetchHotels',
  async ({ page, search }: IHotelListRequestData, thunkAPI: any) => {
    try {
      let url = 'hotel-users/v1/hotel/?page=' + page;
      if (search != '') {
        url = url + '&search=' + search?.trim();
      }
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchHotel = createAsyncThunk(
  'hotel-users/fetchHotel',
  async (id: any, thunkAPI: any) => {
    try {
      const url = 'hotel-users/v1/hotel/' + id + '/';
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);  

export const fetchSettings = createAsyncThunk(
  'hotels/fetchSettings',
  async (id: number, thunkAPI: any) => {
    try {
      const url = 'users/v1/tip-settings/' + id + '/';
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchConfig = createAsyncThunk('hotels/fetchConfig', async () => {
  try {
    const url = 'hotels/v1/hotel/config/';
    const response = get(url);
    return (await response).data;
  } catch (error) {
    //return thunkAPI.rejectWithValue(error);
  }
});

export const fetchHotelAdminHotelConfig = createAsyncThunk('hotels/fetchHotelAdminHotelConfig', async () => {
  try {
    const url = 'hotels/v1/hotel/hotel-admin-config-hotel/';
    const response = getWithoutAuth(url);
    return (await response).data;
  } catch (error) {
    //return thunkAPI.rejectWithValue(error);
  }
});



export const getHotelsettings = createAsyncThunk(
  'hotels/getHotelsettings',
  async (id: number, thunkAPI: any) => {
    try {
      const url = 'users/v1/tip-settings/1/';
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);



export const getHotelAdminsettings = createAsyncThunk(
  'hotels/hotelAdminGetHotelsettings',
  async (id: number, thunkAPI: any) => {
    try {
      const url = 'users/v1/hotel-admin-tip-settings/1/'; // 1
      const response = getWithoutAuth(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const addHotelsettings = createAsyncThunk(
  'hotels/addsettings',
  async (form: IHotelSettingsForm, thunkAPI: any) => {
    console.log(form)
    try {
      const url = 'users/v1/tip-settings/1/';
      const response = put(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateHotel = createAsyncThunk(
  'hotels/updateHotel',
  async (form: IHotelForm, thunkAPI: any) => {
    try {
      const url = 'hotel-users/v1/hotel/' + form.id + '/';
      const response = put(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createHotelNew = createAsyncThunk(
  'hotels/createHotel',
  async (form: IHotelForm, thunkAPI: any) => {
    try {
      const url = 'hotels/v1/hotel/';
      const response = post(url, form);
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const inviteByEmail = createAsyncThunk(
  'hotels/inviteByEmail',
  async (form: IHotelInviteByEmail, thunkAPI: any) => {
    try {
      const url = 'hotel-users/v1/maid-invite-link/';
      const response = post(url, form);
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const activateHotel = createAsyncThunk(
  'hotels/activateHotel',
  async (form: IHotelId, thunkAPI: any) => {
    try {
      const url = 'hotels/v1/hotel/activate-hotel/';
      const response = patch(url, form);
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deactivateHotel = createAsyncThunk(
  'hotels/deactivateHotel',
  async (form: IHotelId, thunkAPI: any) => {
    try {
      const url = 'hotels/v1/hotel/deactivate-hotel/';
      const response = patch(url, form);
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const modifyHotelUsername = createAsyncThunk(
  'hotels/modifyHotelUsername',
  async (form: IHotelIdUsername, thunkAPI: any) => {
    try {
      const url = 'hotels/v1/user/create/?username=' + form.username + '&hotel_id=' + form.hotelId
      const response = post(url, "");
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchHotelsNameId = createAsyncThunk(
  'hotels/fetchHotelsNameId',
  async (search: string, thunkAPI: any) => {
    try {
      let url = 'hotel-users/v1/hotel-name/list/';
      if (search != '') {
        url = url + '?search=' + search?.trim();
      }
      const response = get(url);
      console.log('response:: ', response); 
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const importHotels = async (file: any): Promise<any> => {
  const url = 'hotels/v1/hotel/csv-upload/';
  let resp = await postFile(url, file);
  // console.log('importHotels resp: ',resp); 
  return (resp);
  // try {
  //   const url = 'hotels/v1/hotel/csv-upload/';
  //   let resp = postFile(url, file);
  //   return (await resp)?.status;
  // } catch (error) {
  //   new Promise((resolve) => {
  //     resolve(500);
  //   });
  // }
};

export const getHotel = (id: number): any => {
  try {
    const url = 'hotels/v1/hotel/' + id + '/';
    const response = get(url);
    response.then((res) => {
      return res.data;
    });
  } catch (error) {
  }
};

export const exportHotels = ({ page, search }: IHotelListRequestData): void => {
  try {
    let url = 'hotels/v1/hotel/csv-download/';
    if (search != '') {
      url = url + '?search=' + search?.trim();
    }
    getFile(url, 'Hotel_List_Exported');
  } catch (error) {
  }
};

export const createHotel = async (data: IHotelForm): Promise<any> => {
  try {
    const url = 'hotels/v1/hotel/';
    const response = post(url, data);
    return response;
  } catch (error) {
  }
};

export const getHotelSample = (): void => {
  try {
    let url = 'users/v1/csv/hotels/';
    getFile(url, 'Hotel_List_Import');
  } catch (error) {
  }
};

export const getHotelQrCode = (id: string): void => {
  try {
    let url = 'hotels/v1/hotel/get-qrcode/' + id + '/';
    getPdfFile(url, id);
  } catch (error) {
  }
};

export const createHotelHotelAdmin = createAsyncThunk(
  'hotels/createHotel',
  async (form: IHotelForm, thunkAPI: any) => {
    try {
      const url = 'hotels/v1/hotel-admin/';
      const response = postWithoutAuth(url, form);
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


export const fetchPaymentLog = createAsyncThunk(
  'payment/log',
  async ({ page, search }: IHotelListRequestData, thunkAPI: any) => {
    try {
      let url = 'payments/v1/payments/log/?page=' + page;
      if (search != '') {
        url = url + '&search=' + search?.trim();
      }
      const response = getWithoutAuth(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);