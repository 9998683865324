import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { authenticate, selectAuthentication } from './authenticationSlice';
import { isAuthenticatedHotel } from '../../services/authenticationService';
import { useHistory } from 'react-router-dom';
import logo from './../../assets/img/tova.png';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { APP_NAME, COPYRIGHT_TEXT } from '../../config/env';

import sideImage from './../../assets/img/login_side_image.svg';
import sideImageSmall from './../../assets/img/small_background.png';

import { getHotelUserHotelIds } from '../../services/localStorage';
import { resetUserPasswordNoAuth } from '../../services/hotelAssociateService';  
import { LBL_MAIDS, LBL_URL_MAIDS } from '../../config/common_labels';

export const HotelLogin = () => {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);

  const [resetpwdUsername, setResetpwdUsername] = useState('');
  const [usernameError, setUsernameError] = useState('required');
  const [modalHeader, setModalHeader] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSetResetpwdUsername = (emailValue:string) => {
    setResetpwdUsername(emailValue); 
    if (emailValue && emailValue.trim().length > 0) {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (resetpwdUsername.match(validRegex)) { 
        setUsernameError(''); 
      }
      else {
        setUsernameError('required'); 
      }
    }
    else {
      setUsernameError('required'); 
    }
  }

  const forgotPasswordButton = () => {
    setModalHeader('Forgot Password');
    setResetpwdUsername('');
    handleOpen();
  }

  const onSaveSubmitted = () => {
    if (resetpwdUsername && resetpwdUsername.trim().length > 0) {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (resetpwdUsername.match(validRegex)) { 
        setUsernameError(''); 
        dispatch(resetUserPasswordNoAuth(encodeURIComponent(resetpwdUsername))) 
      }
      else {
        setUsernameError('required'); 
      }
    }
    else {
      setUsernameError('required');
      return false;
    }
  };

  if (isAuthenticatedHotel()) {
    let hotelIds = getHotelUserHotelIds(); 
    let arrHotelIds = hotelIds?.split(','); 
    if(arrHotelIds && arrHotelIds.length > 1) {
      history.push('/v1/hotels');
    }
    else {
      history.push('/v1/'+LBL_URL_MAIDS);
    }
  }

  const { isProcessingRequest } = useAppSelector(selectAuthentication);
  const dispatch = useAppDispatch();

  const handleChange = (e: any) => {
    e.preventDefault();
    dispatch(authenticate(username, password));
  };

  const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    bgcolor: 'background.paper',
    border: '1px solid #d0d0d0',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <div className="align-center">
        <form autoComplete="off" onSubmit={handleChange}>
          <div className="row bg-white" style={{ paddingLeft: '10px' }}>
            <div className="box col mr-5">
              <div className="">
                <div className="justify-center mt-10px mb-100px">
                  <img className="medium" src={logo} alt={APP_NAME} />
                </div>
                <div
                  className="form-group heading div-label font-bold row strong mb-35px"
                  style={{ paddingLeft: '10px' }}
                >
                  Hotel Login
                </div>
                <div className="form-group">
                  <div className="mb-10px">
                    <label className="div-label font-bold">Username</label>
                  </div>
                  <div className="mb-10px">
                    <input
                      type="text"
                      required
                      name="username"
                      className="w-100px"
                      placeholder="Enter username here"
                      onChange={(event) => setUsername(event.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="mb-10px">
                    <label className="div-label font-bold">Password</label>
                  </div>
                  <div className="mb-35px">
                    <span className='div-right margin-right-40px cursor-pointer' onClick={e => {setPasswordShown(!passwordShown)}} ><i className={passwordShown ? "fa fa-eye-slash eye-icon" : "fa fa-eye eye-icon"} ></i></span>
                    <input
                      type={passwordShown ? "text" : "password"} 
                      className="w-100px"
                      required
                      name="password"
                      placeholder="Enter password here"
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </div>
                </div>
                <div className="row center mb-20px" style={{ padding: '0 10px' }}>
                  <button type="submit" className="primary w-100px">
                    Login
                  </button>
                </div>
                <div className="row div-right mb-35px margin-right-5px forgotpwd-text color-light-grey" onClick={(event) => forgotPasswordButton()} >Forgot Password?</div>
              </div>
            </div>
            <div className="col" id="sideImage">
              <img className="large" id="" src={sideImageSmall} alt="sideImage" />
            </div>
          </div>
        </form>
      </div>
      <footer>{COPYRIGHT_TEXT}</footer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby={modalHeader}
        aria-describedby=""
      >
        <Box sx={style}>
          <div className="forgotpwd-modal div-center">
            <div className="page-heading">
              <h4>{modalHeader}</h4>
            </div>

            <div className='row col-12 margin-bottom20px'>


              <div className='row'>
                <div className='margin-left-15px'>
                  Enter Username (Email)
                  <input
                    type="text"
                    name={'resetpwdUsername'}
                    className={usernameError && usernameError.trim().length > 0 ? "w-100px input-error" : "w-100px"}
                    placeholder="Enter Username here"
                    onChange={(e) => onSetResetpwdUsername(e.target.value)}
                    required
                    value={resetpwdUsername}
                  />
                </div>
              </div>

              <div className='row margin-top30'>
                <div className='col-12 div-right margin-left-15px'>
                  <button
                    type="button"
                    className="primary div-right margin-left-10px"
                    onClick={() => onSaveSubmitted()}
                  > &nbsp; Send password reset email &nbsp; </button>
                  <button
                    type="button"
                    className="primary div-right margin-right-15px"
                    onClick={() => handleClose()}
                  >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
