import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IMaidTransacionDetails } from '../../models/userDetailsInterfaces';
import { fetchMaidTansactions } from '../../services/maidPaymentsService';

export interface FilterState {
  transactionDetails: IMaidTransacionDetails;
}

const initialState: FilterState = {
  transactionDetails: {} as IMaidTransacionDetails,
};

export const maidPaymentsTransactionSlice = createSlice({
  name: 'maidPaymentsTransaction',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMaidTansactions.pending, (state) => {
      state.transactionDetails = {} as IMaidTransacionDetails;
    });
    builder.addCase(fetchMaidTansactions.fulfilled, (state, action) => {
      const hotels = action.payload as IMaidTransacionDetails;
      state.transactionDetails = hotels;
    });
    builder.addCase(fetchMaidTansactions.rejected, (state, { payload }: any) => {
      //   state.loading = "error";
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = maidPaymentsTransactionSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMaidPaymentsTransactionSlice = (state: RootState) =>
  state.maidPaymentsTransaction;

export const maidPaymentsTransactionReducer = maidPaymentsTransactionSlice.reducer;
