import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import Select, { Options } from 'react-select';

import Table from '../../components/Table';
import { selectHotelAssociatesListDataSlice, setDataLoading } from './hotelAssociatesListDataSlice';
import { selectHotelAssociatesAddEditSlice } from './hotelAssociatesAddEditSlice';
import { selecthotelAssociatesHotelFilterSlice } from './hotelAssociatesHotelFilterSlice';
import { IHotelAssociate, IHotelAssociateListRequestData, IHotelAssociateCreate, IHotelAssociateModify, IAdminAssociateUsername } from '../../models/hotelAssociateInterfaces';
import {
  fetchHotelAssociates, createHotelAssociate, modifyHotelAssociate, statusUpdateHotelAssociate, resetAssocUserPassword, AdminAssociateUsernameSearch, deleteHotelAssociate 
} from '../../services/hotelAssociateService';
import { setSelectedHotelId, getSelectedHotelId } from '../../services/localStorage';
import { fetchHotelsNameId } from '../../services/maidPaymentsService';
import { getHotelUserRole } from '../../services/localStorage';
import { resetUserPassword } from '../../services/hotelAssociateService';

import { changeHotelFilter } from './hotelAssociatesHotelFilterSlice';

import { hotelAssociatesListFields } from '../../config/tables';
import { checkPagePermission } from '../../config/permissions';
import { LBL_ASSOCIATES, LBL_ASSOCIATE, LBL_USER_ROLE_HOTEL_MANAGER, LBL_USER_ROLE_HOTEL_USER } from '../../config/common_labels';

import logo from './../../assets/img/csv.png';
import { notification_shown_time_success, notification_shown_time_failed, USER_ROLE_HOTEL_USER, USER_ROLE_HOTEL_MANAGER } from '../../config/env';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { MSG_CONFIRM_RESET_PASSWORD } from '../../config/messages';

export const HotelAssociatesList = (props: any) => {
  let hotelUserRole = getHotelUserRole();
  const history = useHistory();
  if (!checkPagePermission(hotelUserRole, LBL_ASSOCIATES)) {
    history.push('/v1/hotels');
  }

  const [listFetchHotelAssociatesPromise, setListFetchHotelAssociatesPromise] = useState<Array<any>>([]);
  const { associates, loading, associatesUser, assocUserloading } = useAppSelector(selectHotelAssociatesListDataSlice);
  const { addEditLoading, deleteUserLoading } = useAppSelector(selectHotelAssociatesAddEditSlice);
  const { hotelNameIds, curValue, allHotelOpts, curHotelOPt, isHotelActive } = useAppSelector(
    selecthotelAssociatesHotelFilterSlice
  );


  const dispatch = useAppDispatch();


  const [editId, setEditId] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [mobile, setMobile] = useState('');
  const [position, setPosition] = useState('');
  const [hotelListObj, setHotelListObj] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [needUserMerge, setNeedUserMerge] = useState(false);
  const [needUserMergeEdit, setNeedUserMergeEdit] = useState(false);

  const [role, setRole] = useState(USER_ROLE_HOTEL_USER);
  const [usernameError, setUsernameError] = useState('required');
  const [modalHeader, setModalHeader] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [localStorageHotelId, setLocalStorageHotelId] = useState(getSelectedHotelId());
  const [roleDisabled, setRoleDisabled] = useState(false); 

  let hotelUserId = localStorage.getItem('hotelUserId');
  let userListFields = hotelAssociatesListFields;
  // IF ADMIN USER - NO EDIT AND DELETE OPTIONS
  if (hotelUserRole === USER_ROLE_HOTEL_USER.toString()) {
    userListFields = userListFields.filter(item =>item.type != 'delete_user_button'); 
  }
  

  const onResetEmail = (username: string) => {
    if (username && username.length > 0) {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (username.match(validRegex)) {
        Store.addNotification({
          title: 'Reset Password',
          id: 'resetpwd-modal',
          content: (
            <div className="resetpwd-modal">
              <div className="resetpwd-flex-center page-heading">
                <h4>Confirm Reset Password</h4>
              </div>
              <div className="resetpwd-flex-center">
                {MSG_CONFIRM_RESET_PASSWORD+' of ' + username + '?'}
              </div>
              <div className="resetpwd-margin"></div>
              <div className="resetpwd-flex-center">
                <button
                  type="button"
                  className="primary"
                  onClick={() => Store.removeNotification('resetpwd-modal')}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="primary margin-left-15px"
                  onClick={() => {
                    dispatch(resetUserPassword(encodeURIComponent(username)))
                    Store.removeNotification('resetpwd-modal')
                  }
                  }
                >
                  Reset Password
                </button>
              </div>
            </div>
          ),
          type: 'default',
          container: 'center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 1000000000,
            click: false,
            touch: false,
          },
        });
      }
    }
  };

  const resetAssociate = () => {

    setEditId(0);
    setFirstName('');
    setLastName('');
    setUsername('');
    setMobile('');
    setPosition('');
    setRole(USER_ROLE_HOTEL_USER);
    setHotelList([]);
    setHotelListObj([]);
    setUsernameError('required');
    setEmailVerified(false);
    setIsActive(false);
  }

  const fetchall = (): void => {
    listFetchHotelAssociatesPromise.forEach((p) => {
      p.abort();
    });
    const req: IHotelAssociateListRequestData = {
      page: 0,
      search: '',
      hotel: curValue,
    };
    const promise = dispatch(fetchHotelAssociates(req));
    setListFetchHotelAssociatesPromise([promise]);
  };

  React.useEffect(() => {
    if(deleteUserLoading=='deleted') {
      fetchall();
    }
  }, [deleteUserLoading]);

  React.useEffect(() => {
    if (addEditLoading == 'succeeded') {
      handleClose();
      fetchall();
    }
    else if (addEditLoading == 'statusUpdated') {
      fetchall();
    }
    else if (addEditLoading == 'statusUpdateFailed') {
      setIsActive(!isActive);
    }
  }, [addEditLoading]);


  React.useEffect(() => {
    dispatch(fetchHotelsNameId(''));
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (props.match.params.id !== undefined) {
      dispatch(changeHotelFilter(props.match.params.id));
      setSelectedHotelId(props.match.params.id);
      setLocalStorageHotelId(props.match.params.id);
    } else if (params.get('maid-pay-hotel') != undefined) {
      dispatch(changeHotelFilter(params.get('maid-pay-hotel')));
      setSelectedHotelId(params.get('maid-pay-hotel'));
      setLocalStorageHotelId(params.get('maid-pay-hotel'));
    }
    else if (localStorageHotelId) {
      dispatch(changeHotelFilter(localStorageHotelId));
    }
  }, []);

  React.useEffect(() => {
    if (curValue !== 0) {
      setSelectedHotelId(curValue); 
      setLocalStorageHotelId(curValue.toString()); 
      fetchall();
    }
  }, [curValue]);

  React.useEffect(() => {
    if (username && username.length > 0) {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (username.match(validRegex)) {
        setUsernameError('');
      }
      else {
        setUsernameError('required');
      }
    }
    else {
      setUsernameError('required');
    }
  }, [username]);

  const {
    register,
    setValue,
    clearErrors,
    handleSubmit
  } = useForm<IHotelAssociateCreate>();

  const updateFieldMobile = (value: string) => {
    setMobile(value);
    setValue('mobile', value);
    clearErrors('mobile');
  };

  const onSetHotelList = (value: any) => {
    setHotelListObj(value)
    setHotelList(value.map((item: any) => parseInt(item.value, 10)))
  };



  const OnSetUsername = (value: any) => {
    setUsername(value)
    let pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (pattern.test(value)) {
      handleAssociateUsername(value)
    }
  };

  const handleAssociateUsername = (e: any) => {
    var newAssoc: IAdminAssociateUsername = {
      username: encodeURIComponent(e),
    };
    dispatch(AdminAssociateUsernameSearch(newAssoc));
  };


  useEffect(() => {

    if (assocUserloading == "succeeded" && associatesUser) {
      if ((associatesUser?.firstName && associatesUser?.firstName.trim().length > 0) && (associatesUser?.lastName && associatesUser?.lastName.trim().length > 0)) {
        setNeedUserMerge(true)
      }
      if (associatesUser?.firstName && associatesUser?.firstName.trim().length > 0) {
        setFirstName(associatesUser.firstName);
      }
      if (associatesUser?.lastName && associatesUser?.lastName.trim().length > 0) {
        setLastName(associatesUser.lastName);
      }
      if (associatesUser?.role && associatesUser?.role > 0) {
        setRole(associatesUser.role);
      }
      if (associatesUser?.mobile && associatesUser?.mobile.trim().length > 0) {
        setMobile(associatesUser.mobile);
      }
      if (associatesUser?.position && associatesUser?.position.trim().length > 0) {
        setPosition(associatesUser.position);
      }
    }

  }, [assocUserloading])

  const onSaveSubmitted = () => {
    if (username && username.trim().length > 0 && firstName && firstName.trim().length > 0 && lastName && lastName.trim().length > 0) {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (username.match(validRegex)) {
        if (editId == 0 && hotelListObj && hotelListObj.length > 0) {
          var newAssoc: IHotelAssociateCreate = {
            firstName: firstName,
            lastName: lastName,
            mobile: mobile && mobile.trim().length > 0 ? mobile.search('\\+') < 0 ? '+' + mobile : mobile : '',
            username: username,
            position: position,
            isActive: true,
            role: role,
            hotelList: hotelList,
          };
          dispatch(createHotelAssociate(newAssoc));
        }
        else if (editId > 0) {
          // if (needUserMerge) {
          //   setNeedUserMergeEdit(true)
          // } else {
          //   console.log("|onSaveSubmitted");

            var assocData: IHotelAssociateModify = {
              userId: editId,
              firstName: firstName,
              lastName: lastName,
              mobile: mobile && mobile.trim().length > 0 ? mobile.search('\\+') < 0 ? '+' + mobile : mobile : '',
              username: username,
              position: position,
              role: role,
              isActive: isActive,
              hotelList: hotelList,
              mergeUser: false,
            };

            dispatch(modifyHotelAssociate(assocData));

          }
        // }

      }
    }
    else {
      return false;
    }
  };

  const handleUserMerge = () => {

    var assocData: IHotelAssociateModify = {
      userId: editId,
      firstName: firstName,
      lastName: lastName,
      mobile: mobile && mobile.trim().length > 0 ? mobile.search('\\+') < 0 ? '+' + mobile : mobile : '',
      username: username,
      position: position,
      role: role,
      isActive: isActive,
      hotelList: hotelList,
      mergeUser: true,
    };
    dispatch(modifyHotelAssociate(assocData));

    setNeedUserMerge(false)

    setNeedUserMergeEdit(false)
  }
  const onAddAssociatebutton = () => {
    resetAssociate();
    setModalHeader('Add ' + LBL_ASSOCIATE);
    handleOpen();

    let arr: any = []
    let arr2: any = []
    allHotelOpts.map((d: any) => { arr.push(d) }); 
    console.log('curValue: ',curValue);
    let hId = getSelectedHotelId();  
    arr.filter((d: any) => d.value == curValue ? arr2.push(d) : null);
    console.log('arr2: ',arr2); 
    setHotelListObj(arr2);
    setHotelList(arr2.map((item: any) => parseInt(item.value, 10)));

  }


  const onClickable = (id: number, name: string): void => {
    console.log("onClickable");
    if(name=='deleteUser') {
      Store.addNotification({
        title: 'Refund',
        id: 'deleteUser-modal',
        content: (
            <div className="refund-modal">
                <div className="refund-flex-center page-heading">
                    <h4 >Delete {LBL_ASSOCIATE}</h4>
                </div>
                <div className="refund-flex-center">
                    Are you sure you want to delete this {LBL_ASSOCIATE.toLowerCase()}?
                </div>
                <div className="refund-margin"></div>
                <div className="refund-flex-center">
                    <button
                        type="button"
                        className="primary"
                        onClick={() => Store.removeNotification('deleteUser-modal')}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="rounded-btn danger margin-left-15px"
                        onClick={() => {
                            Store.removeNotification('deleteUser-modal');
                            dispatch(deleteHotelAssociate(id));
                          }
                        }
                    >
                      Delete
                    </button>
                </div>
            </div>
          ),
          type: 'default',
          container: 'center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
              duration: 1000000000,
              click: false,
              touch: false,
          },
      });
    }
    else {
      setNeedUserMerge(false)
      const assocDet = associates.find(item => item.id == id);
      let stringId = id.toString();
      if ((assocDet?.isPrimaryUser) == true && hotelUserId != stringId) {
        return;
      }
      else {
        setEditId(id);
        setFirstName(assocDet?.firstName ? assocDet.firstName : '');
        setLastName(assocDet?.lastName ? assocDet.lastName : '');
        setUsername(assocDet?.username ? assocDet.username : '');
        setMobile(assocDet?.mobile ? assocDet.mobile : '');
        setPosition(assocDet?.position ? assocDet.position : '');
        setRole(assocDet?.role ? assocDet.role : 0);
        // setHotelList(assocDet?.userHotelIds ? assocDet.userHotelIds : []);
        setEmailVerified(assocDet?.emailVerified ? assocDet.emailVerified : false);
        setIsActive(assocDet?.isActive ? assocDet.isActive : false);
        setUsernameError('');
        setModalHeader('Edit ' + LBL_ASSOCIATE);

        let arr: any = []
        let arr2: any = []
        allHotelOpts.map((d: any) => { arr.push(d) })

        assocDet?.userHotelIds.map((data: any) => {
          arr.filter((d: any) => d.value == data ? arr2.push(d) : null)
        })
      
        setHotelListObj(arr2);
        setHotelList(arr2.map((item: any) => parseInt(item.value, 10)))
        handleOpen();

      }
    }
  };

  React.useEffect(() => {
    setRoleDisabled(editId.toString()==hotelUserId ? true : false); 
  }, [editId]);

  const changeStatus = (isChecked: any) => {
    setIsActive(isChecked);
  }

  const onResendEmail = (username: string) => {
    if (username) {
      dispatch(resetAssocUserPassword(encodeURIComponent(username)));
    }
  };

  // const downloadSample = (event: React.MouseEvent<HTMLElement>): void => {
  //   event.stopPropagation();
  //   getHotelSample();
  // };

  const handleHotelFilterChange = (e: any): void => {
    dispatch(changeHotelFilter(e.value));
    setSelectedHotelId(e.value);
    setLocalStorageHotelId(e.value);
  };

  function selectBoxOnFocus() {
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-light-grey');
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.remove('color-light-grey');
  }
  function selectBoxOnBlur() {
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.remove('color-light-grey');
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-light-grey');
  }


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 850,
    bgcolor: 'background.paper',
    border: '1px solid #d0d0d0',
    boxShadow: 24,
    p: 4,
  };
  const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 850,
    bgcolor: 'background.paper',
    border: '1px solid #d0d0d0',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <div className="content_container">
      <div className="box">
        <div className="justify-space-between">

          <div className="div-left margin-left-5px width-45-perc ">
            <h4 className="page-heading div-left padding-top6">{LBL_ASSOCIATES+' of '}</h4>
            <div className="margin-left-15px div-left ">
              <Select
                onChange={handleHotelFilterChange}
                name="hotelName"
                className=""
                onFocus={selectBoxOnFocus}
                onBlur={selectBoxOnBlur}
                classNamePrefix="react-select-maids"
                options={allHotelOpts}
                value={curHotelOPt}
                isSearchable={true}
              />
            </div>
          </div>

          <div className="justify-end div-right">
            <button
              type="button"
              className="primary mb-3"
              onClick={() => onAddAssociatebutton()}
            >
              Add {LBL_ASSOCIATE}
            </button>
          </div>
        </div>
        <div className="content-box table-wrapper">
          <Table
            titles={userListFields}
            data={Array.isArray(associates) ? associates : []}
            isLoading={loading === `loading` ? true : false}
            onClickable={onClickable}
            currentPage={0}
            onResendEmail={onResendEmail}
          />

          <div className="justify-right">

          </div>

        </div>
      </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby={modalHeader}
        aria-describedby=""
      >
        <Box sx={style}>
          <div className="associate-modal div-center">
            <div className="page-heading">
              <h4 className='font-merri mt-30px'>{modalHeader}</h4>
            </div>

            <div className='col-12 margin-bottom20px'>

              <div className='row margin-top5'>
                <div className='div-label font-bold col-6'>
                  Username (Email)
                  <input
                    type="text"
                    name={'username'}
                    className={usernameError && usernameError.trim().length > 0 ? "w-100px input-error" : "w-100px"}
                    placeholder="Enter Username here"
                    onChange={(e) => OnSetUsername(e.target.value)}
                    required
                    value={username}
                  />
                  {emailVerified && <div className='div-right resetpwd-text' onClick={(e) => onResetEmail(username)}>Reset Password</div>}
                </div>
                <div className='div-label font-bold col-6'>
                  Mobile
                  <PhoneInput
                    inputProps={{
                      name: `mobile`,
                      required: true,
                      autoFocus: false,
                    }}
                    inputClass="tel-input"
                    onlyCountries={['us', 'in']}
                    country={'us'}
                    value={mobile}
                    onChange={(code) => updateFieldMobile(code)}
                  />
                  {emailVerified && <div className='div-right resetpwd-text' >&nbsp;</div>}
                </div>

              </div>

              <div className='row margin-top20'>
                <div className='div-label font-bold col-6'>
                  First Name
                  <input
                    type="text"
                    value={firstName}
                    name={'firstName'}
                    className={firstName && firstName.trim().length > 0 ? "w-100px" : "w-100px input-error"}
                    placeholder="Enter First Name here"
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className='div-label font-bold col-6'>
                  Last Name
                  <input
                    type="text"
                    name={'lastName'}
                    className={lastName && lastName.trim().length > 0 ? "w-100px" : "w-100px input-error"}
                    placeholder="Enter Last Name here"
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    value={lastName}
                  />
                </div>
              </div>
              <div className='row margin-top20'>
                <div className='div-label font-bold col-6'>
                  Position
                  <input
                    type="text"
                    name={'position'}
                    className="w-100px"
                    placeholder="Enter Position here"
                    onChange={(e) => setPosition(e.target.value)}
                    value={position}
                  />
                </div>
                <div className='div-label font-bold col-6'>
                  Role
                  <select name="role" id="role" className={'w-100px'}  value={role} defaultValue={USER_ROLE_HOTEL_USER} onChange={(e: any) => setRole(e.target.value)}  >
                    <option value={USER_ROLE_HOTEL_USER}>{LBL_USER_ROLE_HOTEL_USER} </option>
                    <option value={USER_ROLE_HOTEL_MANAGER}>{LBL_USER_ROLE_HOTEL_MANAGER} </option>
                  </select>
                </div>
              </div>
              <div className='row margin-top20'>
                <div className='div-label font-bold col-12'>
                  Hotel List {/*  input-error */}
                  <div className={hotelListObj && hotelListObj.length > 0 ? "autocomplete" : (editId==0 ? "input-error autocomplete" : "autocomplete")}>
                    <Autocomplete
                      sx={{height: 'auto'}} 
                      multiple
                      limitTags={3}
                      disableCloseOnSelect={true}
                      id="multiple-limit-tags"
                      options={allHotelOpts}
                      className="select_checkbox"
                      getOptionLabel={(option) => option.label}
                      // defaultValue={[allHotelOpts[0]]}
                      value={hotelListObj}
                      onChange={(e: any, newValue: any) => { onSetHotelList(newValue) }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => editId > 0 ? (
                        <TextField {...params} size="small" />
                      ) : ( <TextField {...params} size="small" required /> )}
                    />
                  </div>
                </div>
              </div>
              <div className='row margin-top30'>
                <div className='col-7 div-left'>
                  {/* {emailVerified === true && editId > 0 && hotelUserId != editId.toString() && <>
                    <label >
                      <div className='div-left'>
                        <input
                          type="checkbox"
                          checked={isActive}
                          onChange={e => changeStatus(e.target.checked)}
                          className="activeCheckbox"
                        /></div> <span > Active</span>
                    </label></>} */}
                </div>
                <div className='col-5 div-right'>
                  {hotelUserId != editId.toString() ? <><button
                    type="button"
                    className="primary div-right margin-left-10px"
                    onClick={() => onSaveSubmitted()}
                  > &nbsp; Save &nbsp; </button>
                  <button
                    type="button"
                    className="div-right margin-right-15px primary"
                    onClick={() => handleClose()}
                  >Cancel</button></> : <><button
                  type="button"
                  className="div-right primary"
                  onClick={() => handleClose()}
                >Cancel</button></>}
                </div>
              </div>
              <div className='row margin-top120'>&nbsp;</div>
            </div>
          </div>
        </Box>
      </Modal>


      <Modal
        open={needUserMergeEdit}
        onClose={() => setNeedUserMergeEdit(false)}
        aria-labelledby={modalHeader}
        aria-describedby=""
      >
        <Box sx={style1}>
          <div className="needusermerge-modal div-center">
           

            <div className='row col-12 margin-bottom20px'>

            This user already exists in the system. Use Add User option to add existing users.
              <div className='row margin-top30'>
                <div className='col-7 div-center'>&nbsp;</div>
                <div className='col-5 div-right'>
                  {/* <button
                    type="button"
                    className="primary div-right margin-left-10px"
                    onClick={() => handleUserMerge()}
                  >  Confirm </button> */}
                  <button
                    type="button"
                    className="div-right margin-right-15px"
                    onClick={() => setNeedUserMergeEdit(false)}
                  >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
