import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { ITableHeader } from '../../models/genericInterfaces';
import StatusField from '../StatusField';
import DateTimeTableField from '../DateTimeTableField';
import UrlTableField from '../UrlTableField';
import MoneyField from '../MoneyField';
import TransactionCountField from '../TransactionCountField';
import MoneyWithTotalMoneyField from '../MoneyWithTotalMoneyField';
import action from './../../assets/img/action.png';
import WorkedHorsTableField from '../WorkedHoursTableField';
import PhoneInput from 'react-phone-input-2';
import { PERM_MAID_VIEW, PERM_MAID_DELETE, PERM_TIPS_ACTION, checkActionPermission } from '../../config/permissions';
import { USER_ROLE_ADMIN_SUPERADMIN, USER_ROLE_ADMIN_ADMIN, USER_ROLE_HOTEL_MANAGER, USER_ROLE_HOTEL_USER, PAGINATION_NUM_ROWS, PAYOUT_TOVA, PAYOUT_HOTEL, PAYOUT_STAFF, STAFF_PAYMENT_METHOD_BANK, STAFF_PAYMENT_METHOD_CHECK } from '../../config/env'; 
import { LBL_USER_ROLE_ADMIN_SUPERADMIN, LBL_USER_ROLE_ADMIN_ADMIN, LBL_USER_ROLE_HOTEL_MANAGER, LBL_USER_ROLE_HOTEL_USER, LBL_PAYOUT_TOVA, LBL_PAYOUT_STAFF, LBL_PAYOUT_HOTEL, LBL_STAFF_PAYMENT_METHOD_BANK, LBL_STAFF_PAYMENT_METHOD_CHECK, LBL_MAIDS } from '../../config/common_labels'; 
let hotelUserId = localStorage.getItem('hotelUserId');

interface IProps {
  titles: ITableHeader[];
  data: any[];
  isLoading: boolean;
  handleSort?: (sortDetail: any) => void;
  onClickable?: (id: number, name: string) => void;
  currentPage: number;
  onDeleteVal?: (id: number, name: string, maid: string) => void;
  hotel?: string;
  noDataTableClass?: any;
  sortedFieldName?: any;
  onResendEmail?: (username: string) => void; 
  hotelUserRole?: any
}

const Table: React.FC<IProps> = (props) => {

  const getPayoutString = (payout: any) => {
    return(payout==PAYOUT_TOVA?LBL_PAYOUT_TOVA:(payout==PAYOUT_HOTEL?LBL_PAYOUT_HOTEL:payout==PAYOUT_STAFF?LBL_PAYOUT_STAFF:'')); 
  };

  const getPayMethodString = (payMethod: any) => {
    return(payMethod==STAFF_PAYMENT_METHOD_BANK?LBL_STAFF_PAYMENT_METHOD_BANK:(payMethod==STAFF_PAYMENT_METHOD_CHECK?LBL_STAFF_PAYMENT_METHOD_CHECK:'')); 
  };

  const tableHeaders = (): any => {
    const [sortField, setSortField] = useState(props?.sortedFieldName ? props.sortedFieldName : '') 
    const [sortOrder, setSortOrder] = useState('')

    const handleSortData = (name: string): void => {
      props.handleSort?.(name);
      
    };

    const getSortClass = (sortDetail: any) => {
      if (sortDetail == "") {
        return (' fa fa-srt fa-sort-up ')
      } else {
        let data = "-" + sortField
        return (' fa fa-srt fa-sort-down ');
      }
    }
  
    const handleSort = (e: any, title: any) => {
      if (sortField == title) { 
        let so = sortOrder == "-" ? "" : "-"; 
        setSortOrder(so); 
        handleSortData(so+sortField); 
      } else {
        setSortField(title); 
        setSortOrder(''); 
        handleSortData(title); 
      }
    }
  
    const dummyFunction = (e: any) => {
      return false; 
    }
    
    return (
      <thead>
        <tr>
          {props.titles.map((title, index) => (
             <th key={index} className={`text-center  ${title.sortBy ? "pointer" : null}`} onClick={(e) => title.sortBy ? handleSort(e, title.sortTitle) : dummyFunction(e)}> 
             <div className='display-center'>
               {title.display_title} &nbsp;
               {title.sortBy && (<i className={(sortField == title.sortTitle || props.sortedFieldName == title.sortTitle) ? getSortClass(sortOrder) : "fa fa-srt fa-sort"}></i>)}
             </div>
           </th>
          ))}
        </tr>
      </thead>
    );
  };

  const onClickable = (id: number, name: string): void => {
    props.onClickable?.(id, name);
  };

  const onDeleteVal = (id: number, name: string, maid: string): void => {
    props.onDeleteVal?.(id, name, maid);
  };
  const capitalizeFirstLetter = (s: string): string => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const onResendEmail = (username: any): void => {
    props.onResendEmail?.(username);
  };

  const tableBody = (): any => {
    let currentPage = props?.currentPage ? (props.currentPage - 1) : 0;
    return (
      <>
        {props.data.length === 0 && <div className={props?.noDataTableClass ? props.noDataTableClass : "no-date-table"}>No data available.</div>}
        <tbody>
          {props.data.map((data, indexOne) => (
            <tr key={indexOne}>
              {props.titles.map((title, indexTwo) => (
                <td
                  className={title.title == 'address' ? 'min-width-300' : title.title == 'mobileNumber' ? 'tel-maid-container' :  title.title == 'mobile' ? 'tel-maid-container' : ''}
                  key={indexTwo}
                  align={
                    title.type === 'url'
                      ? 'center'
                      : (title.type === 'money' || title.type === 'money_by_money')
                        ? 'right'
                        : title.type === 'transaction_count'
                          ? 'center'
                          : title.title === 'hotelCommission'
                            ? 'center'
                            : title.type === 'status'
                              ? 'center'
                              : title.type === 'date'
                                ? 'center'
                                : title.type === 'worked_hours'
                                  ? 'center'
                                  : title.title === 'refundStatus'
                                    ? 'center'
                                    : title.display_title === 'Sl. No.'
                                      ? 'center'
                                      : title.type === 'delete_button'
                                        ? 'center'
                                        : 'left'
                  }
                >
                  {title.type === 'status' && (
                    <StatusField
                      customClass={`${data[title.customClass]}`}
                      key={`status-${data.id}-${indexOne}-${indexTwo}`}
                      value={`${data[title.title]}`}
                      toolTipData={data[title.title + 'Reason']}
                      boldFont
                      status={
                        data[title.title] === true ||
                          data[title.title] === 'SUCCESS' ||
                          data[title.title] === 'YES' ||
                          data[title.title] === 'True'
                          ? true
                          : false
                      }
                    />
                  )}
                  {title.type === 'date' && (
                    <DateTimeTableField
                      key={`dt-${data.id}-${indexOne}-${indexTwo}`}
                      value={data[title.title]}
                      format={title.dateTimeFormat}
                    />
                  )}
                  {title.type === 'url' && (
                   
                    <UrlTableField                    
                      key={`url-${data.id}-${indexOne}-${indexTwo}`}
                      value={`${title.title}/${data.id}/`}
                      title={'view'}
                      storeId={title.title==LBL_MAIDS.toLowerCase() ? data.id : 0}
                    />
                  )}
                  {/* PAYMENTS PAGE - IF HOTELS PAYOUT TYPE IS 'hotel', VALUE OF id WILL BE BLANK IN THE RESPONSE, SO REMOVE CLICK OPTION */}
                  {title.type === 'money' && title.clickable && (
                    ((title.title == 'totalEarnings' && data.id=='') ? <span
                    className={`${title.customClass}`} > 
                    <MoneyField
                      key={`money-${data.id}-${indexOne}-${indexTwo}`}
                      amountValue={Number(data[title.title]).toFixed(2)}
                      clickable={false}
                      amountCurrency={data[title.title + 'Currency']}
                    />
                  </span> : <span
                      className={`${title.customClass}`}
                      onClick={() => title.title !== 'totalEarnings' ? onClickable(data.id, title.title) : (data['paymentStatus'] === 'PENDING' || data['paymentStatus'] === 'FAILED') ? onClickable(0, '') : onClickable(data.id, title.title)}
                    >
                      <MoneyField
                        key={`money-${data.id}-${indexOne}-${indexTwo}`}
                        amountValue={Number(data[title.title]).toFixed(2)}
                        clickable={title.title !== 'totalEarnings' ? true : (data['paymentStatus'] === 'PENDING' || data['paymentStatus'] === 'FAILED') ? false : true}
                        amountCurrency={data[title.title + 'Currency']}
                      />
                    </span>)
                  )}

                  {title.type === 'money' && !title.clickable && (
                    <span
                      className={`${title.customClass}`} 
                    >
                      <MoneyField
                        key={`money-${data.id}-${indexOne}-${indexTwo}`}
                        amountValue={Number(data[title.title]).toFixed(2)}
                        clickable={false}
                        amountCurrency={data[title.title + 'Currency']}
                      />
                    </span>
                  )}
                  {title.type === 'transaction_count' && (
                    <TransactionCountField
                      key={`tranc-${data.id}-${indexOne}-${indexTwo}`}
                      successTransaction={data.successTransactions}
                      failedTransactions={data.failedTransactions}
                    />
                  )}
                  {title.type === 'money_by_money' && (
                    <MoneyWithTotalMoneyField
                      key={`money-by-money-${data.id}-${indexOne}-${indexTwo}`}
                      amountValue={Number(data[title.title]).toFixed(2)}
                      amountCurrency={data[title.title + 'Currency']}
                      totalAmountValue={data['all' + capitalizeFirstLetter(title.title)]}
                    />
                  )}

                  {title.type === 'number' && title.display_title === 'Role' && (<>
                    <div className=''>
                      { data[title.title]==USER_ROLE_ADMIN_SUPERADMIN.toString() ? LBL_USER_ROLE_ADMIN_SUPERADMIN : 
                      data[title.title]==USER_ROLE_ADMIN_ADMIN.toString() ? LBL_USER_ROLE_ADMIN_ADMIN : 
                      data[title.title]==USER_ROLE_HOTEL_MANAGER.toString() ? LBL_USER_ROLE_HOTEL_MANAGER :
                      data[title.title]==USER_ROLE_HOTEL_USER.toString() ? LBL_USER_ROLE_HOTEL_USER : ''}
                    </div></>) }
                  {/* PAYMENTS PAGE - IF HOTELS PAYOUT TYPE IS 'hotel', VALUE OF id WILL BE BLANK IN THE RESPONSE, SO REMOVE CLICK OPTION */}
                  {title.type === 'text' && title.clickable && (
                    ((title.title == 'name' && data.id=='') ? <>{data[title.title]}</> : <a
                      key={`text-click-${data.id}-${indexOne}-${indexTwo}`}
                      onClick={() => onClickable(data.id, title.title)}
                      className={`${title.customClass}`}
                    >
                      {title.title === 'username' ? data[title.title].split('-del-')[0] : data[title.title]}
                    </a>)
                  )}
                  {title.type === 'text' && !title.clickable && (
                    <><span key={`text-noclick-${data.id}-${indexOne}-${indexTwo}`}>
                      {/* {title.display_title==='Sl. No.' ? ((currentPage*10)+indexOne+1) :  data[title.title]} */}
                      {/* IF USER DELETED, STRING '-del' APPENDS TO THE MOBILE NUMBER, SO REMOVE '-del' FROM MOBILE NUMBER */}
                      {title.display_title === 'Sl. No.' ? ((currentPage * PAGINATION_NUM_ROWS) + indexOne + 1) :
                        ((title.title === 'mobile' || title.title === 'mobileNumber') && data[title.title] != null && data[title.title].length > 1) ?
                          <div className=''>
                            <PhoneInput
                              disabled={true}
                              buttonClass='hide'
                              containerClass="border-none tel-maid"
                              inputClass="border-none tel-maid"
                              value={data[title.title].split('-del-')[0]}
                            />
                            <div className='tel-input-maid'>&nbsp;</div>
                          </div>
                          :
                          title.title === 'username' ? data[title.title].split('-del-')[0] : 
                          title.title === 'payout' ? getPayoutString(data[title.title]) : 
                          title.title === 'paymentMethod' ? getPayMethodString(data[title.title]) : 
                          data[title.title]}
                      {title.title === 'username' && data['isPrimaryUser'] && <sup className="ml-5px">Primary</sup>}
                    </span>
                      {title.title === 'username' && !data['emailVerified'] && <><div className='col-12'><div className='small-red div-left col-6'>Not verified</div><div className='small-text div-right col-6 text-right cursor-pointer' onClick={(e) => onResendEmail(data[title.title])}>Resend email</div></div></>} 
                    </>
                  )}
                  {title.type === 'worked_hours' && (
                    <WorkedHorsTableField value={data[title.title]} />
                  )}
                  {title.type === 'action_menu' && (
                    data.refundStatus !== "Refunded" && checkActionPermission(props?.hotelUserRole,PERM_TIPS_ACTION) && 
                    <div className="dropdown">
                      <div className="dropbtn">
                        <img src={action} alt="Action" />
                      </div>
                      <div className="dropdown-content">
                        <a
                          className="pointer-cursor"
                          onClick={() => onClickable(data.id, title.title)}
                        >
                          Refund
                        </a>
                      </div>
                    </div>
                  )}

                  {(title.type === 'delete_user_button' && !data.isPrimaryUser && data.id!=hotelUserId) && 
                    (<div className="dropdown">
                      <div className="dropbtn">
                        <img src={action} alt="Action" />
                      </div>
                      <div className="dropdown-content">
                        <a
                          className="pointer-cursor"
                          onClick={() => onClickable(data.id, 'deleteUser')}
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  )}

                  {title.type === 'delete_button' && (
                    <div> 
                      {(title.title=='deleteMaid' && !checkActionPermission(props?.hotelUserRole,PERM_MAID_DELETE)) ? '' : 
                      <a className="pointer-cursor danger delete-button"
                        onClick={() => onDeleteVal(data.id, title.title, data.maidName)}
                      >
                        Delete  
                      </a>}
                    </div>
                  )}

                  {/* {data[title.title]} */}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </>
    );
  };

  const content = (): JSX.Element => {
    if (!props.isLoading) {
      return (
        <table className="table">
          {tableHeaders()}
          {tableBody()}
        </table>
      );
    }
    return (
      <>
        <table className="table">{tableHeaders()}</table>
        <div className="table-loader">
          <ReactLoading className="loader-center" color={'#2f427c'} type={'bars'} width={'3vw'} />
        </div>
      </>
    );
  };

  return <>{content()}</>;
};

export default Table;
