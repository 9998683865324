import { getHotelAccessToken } from '../services/localStorage';

export const getHeaderInfo = async function () {
  const token = await getHotelAccessToken();
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getFormDataHeader = async function () {
  const token = await getHotelAccessToken();
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };
};


