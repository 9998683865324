import React from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import {
    APP_NAME,
    env_var,
    notification_shown_time_failed,
    notification_shown_time_failed4,
    notification_shown_time_success,
} from '../config/env';
import { getFormDataHeader, getHeaderInfo } from '../helpers/tokenCreator';
import { MSG_FILEIMPORT_INVALID_HEADER, MSG_MAID_DETAILS_UPDATE_FAILED, MSG_INVALID_EMAIL, MSG_APPNAME_ERROR, MSG_TIMESHEET_IMPORT_DATES_INVALID, MSG_INVALID_FILE_FORMAT, MSG_USERNAME_EXISTS, MSG_MOBILE_EXISTS, MSG_USER_MODIFICATION_FAILED, MSG_NEW_USER_CREATION_FAILED, MSG_USER_NOT_ELIGIBLE,MSG_MAID_APPROVAL_FAILED, MSG_LINK_EXPIRED, MSG_INVALID_USER, MSG_PWD_RESET_FAILED, MSG_MAID_IMPORT_FAILED, MSG_UNAUTHORISED, MSG_USER_NOT_REGISTERED, MSG_NO_ACTIVE_ACCOUNT, MSG_INVALID_CREDENTIALS, MSG_LOGIN_AGAIN, MSG_SOMETHING_WENT_WRONG, MSG_SERVER_ERROR, MSG_FILE_FORMAT_ERROR, MSG_USER_EDIT_DUPLICATE, MSG_HOTEL_DETAILS_UPDATE_FAILED, MSG_PRIMARY_USERNAME_EXISTS, MSG_HOTEL_CODE_EXISTS, MSG_INVALID_SESSION, MSG_HOTEL_IMAGE_SIZE_SIZE } from '../config/messages';

import { removeHotelTokens } from './localStorage';
import { } from 'react-router';
import applyCaseMiddleware from 'axios-case-converter';
import { Store } from 'react-notifications-component';
import { config } from 'process';
import { url } from 'inspector';
import { LBL_ASSOCIATE, LBL_URL_MAIDS } from '../config/common_labels';

const client = applyCaseMiddleware(axios.create());

export const setIntercept = (history: any): void => {
    client.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            console.log('::error.response::', error.response);
            if (error.response.status === 400) { // FOR STAFF LOGIN ERROR
                let reqURL = error?.response?.config?.url;

                console.log('reqURL: ', reqURL);
                console.log('error.response.data: ', error.response.data);

                if (reqURL && reqURL.search("/check/") > 0) { // ERROR FROM STAFF REGISTRATION
                    let errMsg = error?.response?.data ? error.response.data : 'User registration failed';
                    Store.addNotification({
                        width: 450,
                        title: MSG_APPNAME_ERROR,
                        message: errMsg,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-center',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed,
                            onScreen: true,
                            pauseOnHover: true,
                        },
                    });
                }
                else if (reqURL && reqURL.search("/register/") > 0) {  // ERROR FROM STAFF  REGISTRATION OTP
                    let errMsg = error?.response?.data ? error.response.data : 'OTP sending failed';
                    Store.addNotification({
                        width: 450,
                        title: MSG_APPNAME_ERROR,
                        message: errMsg,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed,
                            onScreen: true,
                        },
                    });
                }
                else if (reqURL && reqURL.search("hotel-users/v1/user/update") > 0) { // ERROR FROM HOTEL USER EDIT  
                    // {"message":"username_already_exists"}
                    let errMsgStr = error?.response?.data?.message ?
                        error.response.data.message == 'username_already_exists' ? MSG_USERNAME_EXISTS :
                            error.response.data.message == 'need_user_merge'? MSG_USER_EDIT_DUPLICATE :
                                error.response.data.message == 'phone_number_already_exists' ? MSG_MOBILE_EXISTS :
                                    MSG_USER_MODIFICATION_FAILED : MSG_USER_MODIFICATION_FAILED;
                    Store.addNotification({
                        title: APP_NAME,
                        message: errMsgStr,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-center',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed4,
                            onScreen: true,
                            pauseOnHover: true,
                        },
                    });
                }
                else if (reqURL && reqURL.search("hotel-users/v1/user/create") > 0) { // ERROR FROM HOTEL USER EDIT  
                    // {"message":"username_already_exists"}
                    let errMsgStr = error?.response?.data?.message ?
                        error.response.data.message == 'username_already_exists' ? MSG_USERNAME_EXISTS :
                            error.response.data.message == 'phone_number_already_exists' ? MSG_MOBILE_EXISTS :
                                MSG_NEW_USER_CREATION_FAILED : MSG_NEW_USER_CREATION_FAILED;
                    Store.addNotification({
                        title: APP_NAME,
                        message: errMsgStr,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-center',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed4,
                            onScreen: true,
                            pauseOnHover: true,
                        },
                    });
                }
                else if (reqURL && reqURL.search("/maid-details/") > 0) { // ERROR FROM STAFF  PROFILE  
                    // console.log('::error::',error.response); 
                    // return error; 
                    let errMsgStr = error?.response?.data?.error ? error.response.data.error : MSG_MAID_DETAILS_UPDATE_FAILED;
                    Store.addNotification({
                        title: APP_NAME,
                        message: errMsgStr,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-center',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed4,
                            onScreen: true,
                            pauseOnHover: true,
                        },
                    });
                }
                else if ((reqURL && reqURL.search("/generate-tax-form-mobile/") > 0) || (reqURL && reqURL.search("/upload-id-proof/") > 0) || (reqURL && reqURL.search("/save-tax-form/") > 0)) { // ERROR FROM STAFF PROFILE ID PROOF OR W9FORM   
                    // console.log('::error::',error.response); 
                    // return error; 
                }
                else if ((reqURL && reqURL.search("/maid-approve/") > 0)) { // ERROR FROM HOTELS, STAFF PAGES
                    console.log('::error::', error.response);
                    let errMsgStr = error?.response?.data ? error.response.data == "pending_required_fields" ? MSG_USER_NOT_ELIGIBLE : MSG_MAID_APPROVAL_FAILED : MSG_MAID_APPROVAL_FAILED;
                    Store.addNotification({
                        title: APP_NAME,
                        message: errMsgStr,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-center',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed4,
                            onScreen: true,
                            pauseOnHover: true,
                        },
                    });
                }
                else if ((reqURL && reqURL.search("/hotels/v1/password/reset/submit") > 0)) { // RESET PASSWORD 
                    /*
                    invalid_token = if token invalid 
                    invalid_userid_expirytime = if user id or expiry time invalid
                    invalid_user = if invalid user
                    invalid_date = if invalid date
                    link_time_expired = if time expired
                    */
                    let errCode = error?.response?.data?.message ? error.response.data.message : '';
                    let errMsgStr = errCode == 'invalid_token' ? MSG_LINK_EXPIRED :
                        errCode == 'invalid_userid_expirytime' ? MSG_LINK_EXPIRED :
                            errCode == 'invalid_user' ? MSG_INVALID_USER :
                                errCode == 'invalid_date' ? MSG_LINK_EXPIRED :
                                    errCode == 'link_time_expired' ? MSG_LINK_EXPIRED : MSG_PWD_RESET_FAILED;
                    Store.addNotification({
                        title: APP_NAME,
                        message: errMsgStr,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed,
                            onScreen: true,
                            pauseOnHover: true,
                        },
                    });
                }
                else if ((reqURL && (reqURL.search("hotel-users/v1/maid/upload-csv/") > 0) || reqURL.search("hotel-users/v1/timesheet/upload-csv") > 0)) { // ERROR FROM  STAFF IMPORT/EXPORT
                    let errMsgStr = error?.response?.data?.message ? error.response.data.message == "invalid_headings" ? MSG_FILEIMPORT_INVALID_HEADER : MSG_MAID_IMPORT_FAILED : MSG_MAID_IMPORT_FAILED;
                    Store.addNotification({
                        title: APP_NAME,
                        message: errMsgStr,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-center',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed4,
                            onScreen: true,
                            pauseOnHover: true,
                        },
                    });
                }
                else if ((reqURL && reqURL.search("/hotels/") > 0) || (reqURL && reqURL.search("/users/") > 0) || (reqURL && reqURL.search("/payments/") > 0)) { // ERROR FROM HOTELS, MAIDS, PAYMENTS PAGES
                    console.log('::error::', error.response);
                    return error;
                } // api//25/
                else if ((reqURL && reqURL.search("hotel-users/v1/hotel") > 0)) { // ERROR FROM HOTEL SETTINGS
                    // let errMsgStr = error?.response?.data ? error.response.data=="not_authorized" ? "Hotel details updation failed." : 'Hotel details updation failed.' : "Hotel details updation failed.";
                    let errMsgStr = ((error?.response?.data && error.response.data == "username_already_exists") || (error?.response?.data?.message && error.response.data.message == "username_already_exists")) ? MSG_PRIMARY_USERNAME_EXISTS :
                        ((error?.response?.data && error.response.data == "hotel_code_already_exists") || (error?.response?.data?.message && error.response.data.message == "hotel_code_already_exists")) ? MSG_HOTEL_CODE_EXISTS : error.response?.data[0] && error.response?.data[0].length > 2 ? error.response.data[0]=='image_size_exceeded' ? MSG_HOTEL_IMAGE_SIZE_SIZE : error.response.data[0] : MSG_HOTEL_DETAILS_UPDATE_FAILED;

                    Store.addNotification({
                        title: APP_NAME,
                        message: errMsgStr,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-center',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                          duration: notification_shown_time_success,
                          onScreen: true,
                          pauseOnHover: true,
                        },
                      });
                }
                else if (reqURL && reqURL.search("hotel-users/v1/maid-invite-link") > 0) { // ERROR FROM HOTEL USER 
                    // let errMsgStr = error?.response?.data ? error.response.data==
                    // "email__invalid" ? "Invalid email." :  "Invalid email." :  "Invalid email." ;
                    Store.addNotification({
                        title: APP_NAME,
                        message: MSG_INVALID_EMAIL,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-center',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed4,
                            onScreen: true,
                            pauseOnHover: true,
                        },
                    });
                }
                else {
                    Store.addNotification({
                        title: MSG_UNAUTHORISED,
                        message: MSG_USER_NOT_REGISTERED,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed,
                            onScreen: true,
                        },
                    });
                    history.push('/v1/'+LBL_URL_MAIDS);
                }
            }
            else if (error.response.status === 401) {
                let reqURL = error?.response?.config?.url;

                console.log('reqURL: ', reqURL);
                console.log('error.response.data: ', error.response.data);

                if (reqURL && reqURL.search("hotels/v1/login/") > 0) { // ERROR FROM HOTEL LOGIN 
                    let errMsgStr = error?.response?.data?.detail ? (error.response.data.detail == "no_active_hotel" || error.response.data.detail == MSG_NO_ACTIVE_ACCOUNT) ? MSG_INVALID_CREDENTIALS : MSG_INVALID_CREDENTIALS : MSG_INVALID_CREDENTIALS;
                    Store.addNotification({
                        title: APP_NAME,
                        message: errMsgStr,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed,
                            onScreen: true,
                            pauseOnHover: true,
                        },
                    });
                }
                else {
                    Store.addNotification({
                        title: MSG_UNAUTHORISED,
                        message: MSG_LOGIN_AGAIN,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed,
                            onScreen: true,
                            pauseOnHover: true,
                        },
                    });
                }


                removeHotelTokens();
                history.push('/login');

            }
            else if (error.response.status === 422) {
                Store.addNotification({
                    title: MSG_APPNAME_ERROR, 
                    type: 'danger',
                    id: '422-error',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 1000000000,
                        onScreen: true,
                        click: false,
                        touch: false,
                    },
                    content: () => (
                        <div>
                            <div className="box rows-error">
                                <div className="row rows-error-col">
                                    <div className="col">
                                        <label className="h5">{MSG_APPNAME_ERROR}</label>
                                    </div>
                                    <div className="col">
                                        <div>
                                            {JSON.stringify(error.response.data)}
                                            <div className="text-center">
                                                <button
                                                    type="button"
                                                    className="primary"
                                                    onClick={() => Store.removeNotification('422-error')}
                                                >
                                                    Ok
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ),
                });
            } else if (error.response.status === 500) {
                Store.addNotification({
                    title: MSG_SOMETHING_WENT_WRONG,
                    message: MSG_SERVER_ERROR,
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: notification_shown_time_failed,
                        onScreen: true,
                        pauseOnHover: true,
                    },
                    content: () => <div></div>,
                });
            }
            else { // if (error.response.status === 400)
                return error;
                // console.log(error.response);
                // alert(error.response.config.method+' :: '+error.response.config.url)
                // Store.addNotification({
                //     title: MSG_APPNAME_ERROR,
                //     message: MSG_TIMESHEET_IMPORT_DATES_INVALID,
                //     type: 'danger',
                //     insert: 'top',
                //     container: 'top-right',
                //     animationIn: ['animate__animated', 'animate__fadeIn'],
                //     animationOut: ['animate__animated', 'animate__fadeOut'],
                //     dismiss: {
                //         duration: notification_shown_time_failed,
                //         onScreen: true,
                //     },
                // });
            }
        }
    );
};

export const get = async function (url: any, params: any = {}) {
    const header = await getHeaderInfo();
    const resp = await client.get(env_var.API_URL + url, { ...header, params });
    return resp;
};

export const getWithoutAuth = async function (url: any, params: any = {}) {
    const header = await getHeaderInfo();
    const resp = await client.get(env_var.API_URL + url, { ...params });
    return resp;
};

export const getAuthImg = async function (url: any, params: any = {}) {
    const header = await getHeaderInfo();
    const resp = await client.get(env_var.API_URL + url, {
        ...header,
        responseType: 'arraybuffer',
        params,
    });
    return resp;
};

export const post = async function (url: string, body: any) {
    const header = await getHeaderInfo();
    const resp = await client.post(env_var.API_URL + url, body, header);
    return resp;
};

export const postWithoutAuth = async function (url: string, body: any) {
    const resp = await client.post(env_var.API_URL + url, body);
    return resp;
};

export const patch = async function (url: string, body: any) {
    const header = await getHeaderInfo();
    const resp = await client.patch(env_var.API_URL + url, body, header);
    return resp;
};

export const put = async function (url: any, body: any) {
    const header = await getHeaderInfo();
    const resp = await client.put(env_var.API_URL + url, body, header);
    return resp;
};

export const deleteApi = async function (url: any) {
    const header = await getHeaderInfo();
    try {
        const resp = await client.delete(env_var.API_URL + url, header);
        return resp;
    } catch (err) {
        // error handling
        // throw err.response
        return { 'data': '' };
    }
};

export const postFile = async function (url: string, body: any) {
    const header = await getFormDataHeader();
    const formData = new FormData();
    formData.append('file', body);
    try {
        const resp = await client.post(env_var.API_URL + url, formData, header);
        // if (resp.status === 200) {
        //     Store.addNotification({
        //         title: APP_NAME,
        //         message: resp.data.importedCount > 0 ? (resp.data.importedCount + "/" + resp.data.totalCount + " records imported") +
        //             (resp.data.notImportedNumbers?.length > 0 ? "The following records not imported" +
        //                 resp.data.notImportedNumbers.map((item: any) => item)
        //                 : "") : "No records imported",
        //         type: resp.data.importedCount > 0 ? 'success' : 'danger',
        //         insert: 'top',
        //         container: 'top-right',
        //         animationIn: ['animate__animated', 'animate__fadeIn'],
        //         animationOut: ['animate__animated', 'animate__fadeOut'],
        //         dismiss: {
        //             duration: notification_shown_time_success,
        //             onScreen: true,
        //         },
        //     });
        // }
        return resp;
    } catch (err) {
        Store.addNotification({
            title: MSG_FILE_FORMAT_ERROR, 
            message: MSG_INVALID_FILE_FORMAT,
            type: 'danger',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: notification_shown_time_failed,
                onScreen: true,
            },
        });
        // error handling
        // throw err.response
    }
};

export const getFile = async function (url: string, name: string, params: any = {}) {
    const header = await getHeaderInfo();
    const response = await client.get(env_var.API_URL + url, { ...header, params });
    const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = urlBlob;
    if(name){
        link.setAttribute('download', name + '.csv');
    }else{
        link.setAttribute('download', response.headers["contentDisposition"] + '.csv');
    }
    document.body.appendChild(link);
    link.click();
};

export const getPnGFile = async function (url: string, name: string) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = name;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
    }
    xhr.send();
};

export const getPdfFile = async function (url: string, name: string, params: any = {}) {
    const response = await client.get(url);
    const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = urlBlob;
    link.setAttribute('download', name + '.pdf');
    document.body.appendChild(link);
    link.click();
};



