import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../../components/PrivateRoute';
import Navbar from './NavBar';
import './pageLayout.css';
import { parseJwt } from '../../helpers/tokenDecoder';
import { HotelList } from '../HotelList';
import { HotelPayments } from '../HotelPayments';
import { GuestPayments } from '../GuestPayments';
import { MaidList } from '../MaidList';
import { HotelForm } from '../HotelForm';
import { MaidPayments } from '../MaidPayments';
import { SettingsForm } from '../Settings';
import { PaymentLog } from '../PaymentLog';
import { HotelAssociatesList } from '../HotelAssociates';
import { COPYRIGHT_TEXT } from '../../config/env'; 
import { LBL_URL_MAID_PAYMENTS, LBL_URL_MAIDS } from '../../config/common_labels';

export const PageLayout = () => {
  const { path } = useRouteMatch();

  const token = localStorage.getItem('token');

  const userData = parseJwt(token);

  return (
    <div>    
      <div className='header-top-row margin-top30'>
        <div className="header-nav-fixed">
          <Navbar />
        </div>
      </div>
      <div>
        {/* <Navbar /> */}
        <div className="content_container margin-top80">
          <Switch>
            <PrivateRoute exact path={`${path}/hotels`} component={HotelList} />
            {/* <PrivateRoute exact path={`${path}/hotel-payments`} component={HotelPayments} /> */}
            <PrivateRoute exact path={`${path}/guest-payments`} component={GuestPayments} />
            <PrivateRoute exact path={`${path}/${LBL_URL_MAIDS}`} component={MaidList} />
            <PrivateRoute exact path={`${path}/${LBL_URL_MAIDS}/:id`} component={MaidList} />
            <PrivateRoute exact path={`${path}/${LBL_URL_MAID_PAYMENTS}`} component={MaidPayments} />
            <PrivateRoute exact path={`${path}/${LBL_URL_MAID_PAYMENTS}/:id`} component={MaidPayments} />
            {/* <PrivateRoute exact path={`${path}/add-hotel`} component={HotelForm} /> */}
            <PrivateRoute exact path={`${path}/edit-hotel/:id`} component={HotelForm} />
            <PrivateRoute exact path={`${path}/guest-payments/:id`} component={GuestPayments} />
            <PrivateRoute exact path={`${path}/settings`} component={HotelForm} />
            <PrivateRoute exact path={`${path}/payment/log`} component={PaymentLog} />
            <PrivateRoute exact path={`${path}/associates`} component={HotelAssociatesList} />
          </Switch>
          <footer>{COPYRIGHT_TEXT}</footer>
        </div>
      </div>
    </div>

  );
};
