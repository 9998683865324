import React from 'react';

interface IProps {
  value: string;
}
const WorkedHorsTableField: React.FC<IProps> = ({ value }) => {
  let dispVal = value;
  if (value != undefined || value != null) { // "10:15:00" "2 02:15:00"
    const dtVal = value.split(' ');
    if(dtVal.length == 2) {
      const val = dtVal[1].split(':');
      let dtHrs = (Number(dtVal[0])*24)+Number(val[0]); 
      dispVal = dtHrs.toString() + ':' + val[1];
    }
    else {
      const val = value.split(':');
      if (val.length >= 2) {
        dispVal = val[0] + ':' + val[1];
      }
    }
  }
  return <>{dispVal}</>;
};

export default WorkedHorsTableField;
