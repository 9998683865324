import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authenticate } from '../../services/authenticationService';
import { setTokens } from '../../services/localStorage';
import { RootState } from '../../store';

export interface IAuthentication {
  isProcessingRequest: boolean;
  accessToken?: string;
}

const initialState: IAuthentication = { isProcessingRequest: false };

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    start: (state) => {
      return {
        ...state,
        isProcessingRequest: true,
      };
    },
    success: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isProcessingRequest: false,
      };
    },
    error: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isProcessingRequest: false,
      };
    },
  },
});

export const authenticateUser = (username: string, password: string) => async (dispatch: any) => {
  dispatch(start());
  try {
    const authData = await authenticate(username, password);
    setTokens(authData);
    dispatch(success(authData));
  } catch (err) {
    // dispatch(error(err));
  }
};

// Action creators are generated for each case reducer function
export const { start, success, error } = authenticationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuthentication = (state: RootState) => state.authentication;

export const authenticationReducer = authenticationSlice.reducer;
