import React, { useEffect, useState } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { performRefund } from '../../services/guestPaymentService';
import { Store } from 'react-notifications-component';
import ReactLoading from 'react-loading';
import { stat } from 'fs';
import { APP_NAME, notification_shown_time_success } from '../../config/env';
import { MSG_REFUND_SUCCESS } from '../../config/messages';

export interface RefundState {
  refundLoading: 'loading' | 'idle' | 'error' | 'success';
  refundIds: Array<string>;
}

const initialState: RefundState = {
  refundLoading: 'idle',
  refundIds: [],
};

export const guestRefundSlice = createSlice({
  name: 'guestRefund',
  initialState,
  reducers: {
    setguestPayments: (state, action: PayloadAction<number>) => {
      let id = Store.addNotification({
        title: 'Refund!',
        content: (
          <div>
            <ReactLoading
              className="loader-center"
              color={'#2f427c'}
              type={'spokes'}
              width={'3vw'}
            />
          </div>
        ),
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 500,
          onScreen: true,
        },
      });
      let arrayOfRefundIds = state.refundIds.slice();
      arrayOfRefundIds = arrayOfRefundIds.concat(id);
      state.refundIds = arrayOfRefundIds;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(performRefund.pending, (state) => {
      Store.removeNotification('refund-modal');
      state.refundLoading = 'loading';
    });
    builder.addCase(performRefund.fulfilled, (state, action) => {
      Store.removeNotification('refund-modal');
      state.refundLoading = 'success';
      let arrayOfRefundIds = state.refundIds.slice();
      if (arrayOfRefundIds.length !== 0) {
        let m = arrayOfRefundIds.splice(0, 1);
        Store.removeNotification(m[0]);
        state.refundIds = arrayOfRefundIds;
      }
      Store.addNotification({
        title: APP_NAME,
        message: MSG_REFUND_SUCCESS,
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: notification_shown_time_success,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    });
    builder.addCase(performRefund.rejected, (state, action) => {
      Store.removeNotification('refund-modal');
      state.refundLoading = 'error';
      let arrayOfRefundIds = state.refundIds.slice();
      if (arrayOfRefundIds.length !== 0) {
        let m = arrayOfRefundIds.splice(0, 1);
        Store.removeNotification(m[0]);
        state.refundIds = arrayOfRefundIds;
      }
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setguestPayments } = guestRefundSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectGuestRefundSlice = (state: RootState) => state.guestRefund;

export const selectGuestRefundReducer = guestRefundSlice.reducer;
