import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IMaid } from '../../models/maidsInterfaces';
import { fetchMaids } from '../../services/maidService';
import { IGenericResponse, IPagination } from '../../services/interfaces';

export interface DataState {
  maids: IMaid[];
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  maidsLoading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
  totalSize: number;
}

const initialState: DataState = {
  maids: [],
  loading: 'idle',
  maidsLoading: 'idle', 
  error: '',
  totalSize: 0,
};

export const maidListDataSlice = createSlice({
  name: 'maidListData',
  initialState,
  reducers: {
    setMaidList: (state, action: PayloadAction<any>) => {
      state.maids = action.payload;
    },
    setLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMaids.pending, (state) => {
      state.maids = [];
      state.loading = 'loading';
      state.maidsLoading = 'loading'; 
    });
    builder.addCase(fetchMaids.fulfilled, (state, action) => {
      const m = action.payload as IPagination<IMaid[]>;
      state.maids = m.results;
      state.loading = 'succeeded';
      state.maidsLoading = 'succeeded'; 
      state.totalSize = m.count;
    });
    builder.addCase(fetchMaids.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      state.maidsLoading = 'error'; 
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setMaidList, setLoading } = maidListDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMaidListDataSlice = (state: RootState) => state.maidListData;

export const maidListDataReducer = maidListDataSlice.reducer;
