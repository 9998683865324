import React, { useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import AddPageHeader from '../../components/AddPageHeader';
import { useForm, SubmitHandler, SubmitErrorHandler, useFieldArray } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import ReactLoading from 'react-loading';
import { notification_shown_time_success, server_url_var } from '../../config/env';
import dayjs from 'dayjs';
import { Store } from 'react-notifications-component';

export const HotelAddSuccess = (props: any) => {
  const history = useHistory();
  const [title, setTitle] = useState<string>('Hotel Added Successfully');
  const [subTitle, setSubTitle] = useState<string>('Add New Hotel');
  const dispatch = useAppDispatch();
  
  return (
    <div className="content_header">
      <div className="justify-space-between">
        <div>
          <div className="justify-center">
            <h1 className="font-bold color-blue">{title}</h1>
          </div>
        </div>
      </div>


      <div className="box pb-5">
        <div className="row top">
          <div className="col-8">
            <div className="row">
              <div className="form-group">
                <div className="mb-10px">
                  <label className="div-label font-bold">Thank you for submitting your hotel details. Administrator will contact you after reviewing the details.</label>
                </div>
              </div>
            </div>
            <div className="col-6 form-group">&nbsp;</div>

          </div>
        </div>
      </div>

    </div>


  );
};
